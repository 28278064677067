import { 
user_signup,
user_signin , 
add_user_address , 
get_all_user_address,
get_user_address,
update_user_address,
delete_user_address,  
update_email,
update_mobile,
update_user_password,
forgot_password,
add_shipping_address,
validate_postal_code,
get_default_address,
set_default_address,
get_saved_cards,
add_card,

} from '../BaseAPI/API';
import { apiPOSTRequest } from '../apiRequest';


    
    const fetch_user_signup = async (data) => {
      try {
        const responseData = await apiPOSTRequest(user_signup , data);
        return responseData;
      } catch (error) {
        throw new Error(error.message);
      }
    };
    
     const fetch_user_signin = async (data) => {
        try {
          const responseData = await apiPOSTRequest(user_signin , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };
    
    
      const fetch_add_user_address  = async (data) => {
        try {
          const responseData = await apiPOSTRequest(add_user_address  , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

    
      const fetch_get_all_user_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(get_all_user_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_get_user_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(get_user_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_update_user_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(update_user_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_delete_user_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(delete_user_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_update_email = async (data) => {
        try {
          const responseData = await apiPOSTRequest(update_email , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_update_mobile = async (data) => {
        try {
          const responseData = await apiPOSTRequest(update_mobile , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_update_user_password = async (data) => {
        try {
          const responseData = await apiPOSTRequest(update_user_password , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_forgot_password = async (data) => {
        try {
          const responseData = await apiPOSTRequest(forgot_password , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_add_shipping_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(add_shipping_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };
      
      const fetch_validate_postal_code = async (data) => {
        try {
          const responseData = await apiPOSTRequest(validate_postal_code , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const fetch_get_default_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(get_default_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_set_default_address = async (data) => {
        try {
          const responseData = await apiPOSTRequest(set_default_address , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_get_saved_cards = async (data) => {
        try {
          const responseData = await apiPOSTRequest(get_saved_cards , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };


      const fetch_add_card = async (data) => {
        try {
          const responseData = await apiPOSTRequest(add_card , data);
          return responseData;
        } catch (error) {
          throw new Error(error.message);
        }
      };



export {  fetch_user_signup,
fetch_get_all_user_address,
fetch_user_signin,
fetch_add_user_address,
fetch_delete_user_address,
fetch_update_user_address,
fetch_get_user_address,
fetch_add_card,
fetch_add_shipping_address,
fetch_forgot_password,
fetch_get_default_address,
fetch_get_saved_cards,
fetch_set_default_address,
fetch_update_email,
fetch_update_mobile,
fetch_update_user_password,
fetch_validate_postal_code,
}