import { Box, Typography, Container, Grid, Divider } from "@mui/material";
import React from "react";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import { CategoryImageAPI } from "../../Services/BaseAPI/API";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./MainPage.styles.css";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { styled, useTheme } from "@mui/material/styles";
import SwiperCore, { Autoplay } from "swiper";
import HomeCards from "../../Comps/Cards/HomeCards";
import {
  fetch_get_home_image,
  fetch_get_wholesale_trendz_banner,
} from "../../Services/Services/CommonService";
import { fetch_getallcategory } from "../../Services/Services/ProductService";
SwiperCore.use([Autoplay]);

export default function MainPage() {
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [sliderCaption, setSliderCaption] = useState("");
  const [HomeSilder, setHomeSlider] = useState([]);
  const [OfferSection, setOfferSection] = useState([]);
  const [BrandSection, setBrandSection] = useState([]);
  const [CategorySection, setCategorySection] = useState([]);
  const [ArrivingSection, setArrivingSection] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const getHomeImageData = async () => {
      const sendData = new FormData();
      sendData.append("homePageImageId", "");
      try {
        const res = await fetch_get_home_image(sendData);
        if (!res.data.error) {
          setArrivingSection(res.data.data.ArrivingSoon);
          setCategorySection(res.data.data.Trendz);
          setBrandSection(res.data.data.WholesaleBrands);
          setOfferSection(res.data.data.ShoppingOffers);
          setHomeSlider(res.data.data.HomePageSlider);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getAllCategoryData = async () => {
      try {
        const res = await fetch_getallcategory();
        if (!res.data.error) {
          setData(res.data.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getBannerText = async () => {
      const sendData = new FormData();
      sendData.append("bannerId", 3);
      try {
        const res = await fetch_get_wholesale_trendz_banner(sendData);
        if (!res.data.error) {
          setSliderCaption(res.data.data.bannerHeading);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    getHomeImageData();
    getAllCategoryData();
    getBannerText();
  }, []);

  function reveal() {
    var reveals = document.querySelectorAll(
      ".popularCards, .HomePagePreviewCards, .brandsCards"
    );

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  const handleOpenSpecific = (i) => {
    localStorage.setItem("getSpecificProductId", JSON.stringify(i.categoryId));
    localStorage.setItem(
      "getSpecificProductName",
      JSON.stringify(i.categoriesName)
    );
    const slideCategoryId = i.categoriesName.replace(/\s+/g, "").toLowerCase();
    navigate(`/shopfilter/${i.categoryId}`);

    const scrollTimeout = setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);

    // Clear the timeout if the user manually scrolls before it triggers
    window.addEventListener("scroll", () => {
      clearTimeout(scrollTimeout);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomScrollButton = styled(Box)({
    color: "#262626",
    "&:hover": {
      color: "#BDBDBD",
      backgroundColor: "transparent",
    },
    opacity: 0.1,
    "&.Mui-disabled": {
      opacity: 0,
      visibility: "hidden",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
    },
  });

  return (
    <Box>
      <Header />

      <Divider sx={{ borderColor: "silver" }} />

      {/* Main Scetion */}
      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box position="relative">
            <Swiper
              spaceBetween={25}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {HomeSilder?.cards?.map((item, index) => {
                return (
                  <SwiperSlide onClick={() => navigate("/shop")} key={index}>
                    <img
                      src={item.images}
                      alt={item.title}
                      className="SliderImagePreview"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box py={1}>
            <Typography color="text.secondary">{sliderCaption}</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* ProductsScroll Section  */}
      <Container>
        <Grid container py={5}>
          <Grid
            className="productscroll"
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              TabScrollButtonProps={{
                component: CustomScrollButton,
                theme,
              }}
            >
              {data.map((i, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => handleOpenSpecific(i)}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mr={5}
                  >
                    <Box>
                      <img
                        loading="lazy"
                        src={`${CategoryImageAPI}${i.categoryImage}`}
                        style={{ height: 100, width: 100 }}
                        alt="img"
                      />
                    </Box>
                    <Box>
                      <Typography color="#A19FA1">
                        {i.categoriesName}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Tabs>
          </Grid>
        </Grid>
      </Container>

      {/* Offers section */}
      <Container>
        <Grid container mb={2} mt={3}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box width="100%" bgcolor="#faa634" textAlign="left" py={3}>
              <Typography
                variant="h5"
                fontWeight={600}
                marginLeft={2}
                color="#ffff"
              >
                {OfferSection?.heading}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <HomeCards section={OfferSection} name={"Offers"} />
          </Grid>
        </Grid>
      </Container>

      {/* Brands Section  */}

      <Container>
        <Grid container mb={3} mt={3}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box width="100%" bgcolor="#faa634" textAlign="left" py={3}>
              <Typography
                variant="h5"
                fontWeight={600}
                marginLeft={2}
                color="#ffff"
              >
                {BrandSection?.heading}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <HomeCards section={BrandSection} name={"Brands"} />
          </Grid>
        </Grid>
      </Container>

      {/* Products Preview cards section */}

      <Container>
        <Grid container mb={2} mt={3}>
          <Grid item xs={12} md={12} lg={12} xl={12} xxl={12}>
            <Box width="100%" bgcolor="#faa634" textAlign="left" py={3}>
              <Typography
                variant="h5"
                fontWeight={600}
                marginLeft={2}
                color="#ffff"
              >
                {CategorySection?.heading}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <HomeCards section={CategorySection} name={"Trendz"} />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container mb={2} mt={3}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Box width="100%" bgcolor="#faa634" textAlign="left" py={3}>
              <Typography
                variant="h5"
                fontWeight={600}
                marginLeft={2}
                color="#ffff"
              >
                {ArrivingSection?.heading}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <HomeCards section={ArrivingSection} name={"Arriving"} />
          </Grid>
        </Grid>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
