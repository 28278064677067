import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ProfileImageAPI } from "../../Services/BaseAPI/API";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Header from "../../Comps/Header/Header";
import EditIcon from "@mui/icons-material/Edit";
import { appendData } from "../../Variables/ProcessVariable";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LogoutIcon from "@mui/icons-material/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProductsContext } from "../../Context/CountContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SnackBar from "../../Comps/Snackbar";
import CreditCardDesign from "./CreditCardDesign";
import {
  fetch_add_user_address,
  fetch_delete_user_address,
  fetch_get_all_user_address,
  fetch_get_user_address,
  fetch_set_default_address,
  fetch_update_email,
  fetch_update_mobile,
  fetch_update_user_address,
  fetch_update_user_password,
  fetch_user_signin,
} from "../../Services/Services/UserService";
import {
  fetch_get_provinces,
  fetch_getuser,
  fetch_user_update,
} from "../../Services/Services/CommonService";
import { fetch_get_user_placed_orders } from "../../Services/Services/OrderService";

export default function AccountPage() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [refreshPage, setRefreshPage] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [selectedAddresstoUpdate, setselectedAddresstoUpdate] = useState([]);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // Edit Mode
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);
  const [showNameUpdate, setShowNameUpdate] = useState(false);
  const [showAddressUpdate, setShowAddressUpdate] = useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  // Account details
  const [profilePic, setProfilePic] = useState("");
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [updatedCountry, setUpdatedCountry] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [updatedCity, setUpdatedCity] = useState("");
  const [updatedState, setUpdatedState] = useState("");
  const [updatedProvince, setUpdatedProvince] = useState("");
  const [updatedPincode, setUpdatedPincode] = useState("");

  // Orders
  const [searchedOrder, setSearchedOrder] = useState([]);
  const [openOrdersProductsBox, setOpenOrdersProductsBox] = useState(false);
  const [hideAllOrders, setHideAllOrders] = useState(false);

  // Update Email
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [emailChangePassword, setEmailChangePassword] = useState("");
  const [showEmailUpdate, setShowEmailUpdate] = useState(false);

  // Update Mobile
  const [updatedMobile, setUpdatedMobile] = useState("");
  const [mobileChangePassword, setMobileChangePassword] = useState("");
  const [showMobileUpdate, setShowMobileUpdate] = useState(false);

  // Saved Addresses
  // Add Address
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [newlySavedAddress, setNewlySavedAddress] = useState("");
  const [newlySavedCity, setNewlySavedCity] = useState("");
  const [newlySavedState, setNewlySavedState] = useState("");
  const [newlySavedProvince, setNewlySavedProvince] = useState("");
  const [newlySavedPincode, setNewlySavedPincode] = useState("");
  const [newlySavedCountry, setNewlySavedCountry] = useState("");
  //  Update Address
  const [showAllAddressBox, setShowAllAddressBox] = useState(false);
  const [updateSelectedAddress, setUpdateSelectedAddress] = useState("");
  const [updateSelectedCity, setUpdateSelectedCity] = useState("");
  const [updateSelectedState, setUpdateSelectedState] = useState("");
  const [updateSelectedProvince, setUpdateSelectedProvince] = useState("");
  const [updateSelectedPincode, setUpdateSelectedPincode] = useState("");
  const [updateSelectedCountry, setUpdateSelectedCountry] = useState("");
  const { setCartCount, setWishlistCount } = useContext(ProductsContext);
  const [DeleteAddressId, setDeleteAddressId] = useState("");

  const [errorMsg2, setErrorMsg2] = useState("");
  const [ErrorLine2, setErrorLine2] = useState("");
  const [errorMsg1, setErrorMsg1] = useState("");
  const [ErrorLine1, setErrorLine1] = useState("");
  const [errorMsg3, setErrorMsg3] = useState("");
  const [ErrorLine3, setErrorLine3] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const selectedTitleName = localStorage.getItem("accountBoxTitle");
  const [selectedTitle, setselectedTitle] = useState(selectedTitleName);

  const handleChangeTitle = async (title) => {
    localStorage.setItem("accountBoxTitle", title);
    setselectedTitle(title);
  };

  // Submit Registration

  const onSubmit = async () => {
    if (email !== "" && password !== "") {
      const serverData = new FormData();
      serverData.append("email", email);
      serverData.append("password", password);
      try {
        const res = await fetch_user_signin(serverData);
        if (!res.data.error) {
          successMsg(res.data.message);
          localStorage.setItem("Userauth", true);
          localStorage.setItem("userId", res.data.data.userId);
          localStorage.setItem("ShippingAddressId", "");
          localStorage.setItem("accountBoxTitle", "Recent Orders");
          setRefreshPage(true);
        } else {
          ErrorMsg(res.data.message);
          setRefreshPage(false);
          setErrorMsg("Incorrect Email ID or Password");
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      ErrorMsg("Please Enter Email and Password");
    }
  };

  const auth = localStorage.getItem("Userauth");
  const userId = JSON.parse(localStorage.getItem("userId"));

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  // To Get USER DATA

  const getUserDatatodisplay = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_getuser(serverData);
      if (!res.data.error) {
        setUserData(res.data.data);
        localStorage.setItem("profilePic", res.data.data.userProfile);
        setRefreshPage(true);
      } else {
        setMessage(res.data.message);

        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserDatatodisplay();
  }, [refreshPage, userId]);

  const handleChangeProfile = () => {
    setShowProfileUpdate(!showProfileUpdate);
  };

  const handleOpenPersonalName = () => {
    setShowNameUpdate(!showNameUpdate);
  };

  const handleOpenPersonalmobile = () => {
    setShowMobileUpdate(!showMobileUpdate);
    setErrorMsg("");
    setError(false);
  };

  const handleOpenAccountEmail = () => {
    setShowEmailUpdate(!showEmailUpdate);
    setErrorMsg("");
    setError(false);
  };

  const handleOpenAccountAddress = () => {
    setShowAddressUpdate(!showAddressUpdate);
  };

  const handleOpenAccountPassword = () => {
    setShowPasswordUpdate(!showPasswordUpdate);
    setErrorMsg("");
    setError(false);
  };

  // Update USER DATA

  const updateUserData = async () => {
    const obj = {
      firstName: updatedFirstName,
      lastName: updatedLastName,
      address: updatedAddress,
      city: updatedCity,
      state: updatedState,
      province: updatedProvince,
      pincode: updatedPincode,
      country: updatedCountry,
      userProfile: profilePic,
      userId: userId,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_user_update(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setShowNameUpdate(false);
        setShowMobileUpdate(false);
        setShowEmailUpdate(false);
        setShowAddressUpdate(false);
        setShowProfileUpdate(false);
        setShowPasswordUpdate(false);
        document.getElementById("profilePicUpload").value = "";
        getUserDatatodisplay();
        setErrorMsg2("");
        setErrorLine2("");
        setErrorMsg1("");
        setErrorLine1("");
        setErrorMsg3("");
        setErrorLine3("");
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  // GET All Addresses

  const getAllUserAddress = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_all_user_address(serverData);
      if (!res.data.error) {
        setAllAddresses(res.data.data);
        setRefreshPage(true);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getAllUserAddress();
  }, [refreshPage, userId]);

  // GET ALL ORDERS by USER ID
  const getAllUserOrdersById = async () => {
    setSearchOrderNumber("");
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_user_placed_orders(serverData);
      if (!res.data.error) {
        setSearchedOrder(res.data.data);
        setRefreshPage(true);
        setSearchOrderNumber("");
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getAllUserOrdersById();
  }, [refreshPage, userId]);

  // GET ALL ORDERS by ORDER ID
  const getAllUserOrdersByOrderId = async () => {
    if (searchOrderNumber !== "" && searchOrderNumber !== null) {
      const serverData = new FormData();
      serverData.append("orderNum", searchOrderNumber);
      try {
        const res = await fetch_get_user_placed_orders(serverData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setSearchedOrder(res.data.data);
          setRefreshPage(true);
          setHideAllOrders(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  //To Open Address Fomr
  const openSavedAddressForm = () => {
    setShowNewAddressForm(!showNewAddressForm);
  };

  //Submit New Address

  const submitNewlySavedAddress = async () => {
    const obj = {
      address: newlySavedAddress,
      city: newlySavedCity,
      province: newlySavedProvince,
      pincode: newlySavedPincode,
      country: newlySavedCountry,
      userId: userId,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_user_address(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setNewlySavedAddress("");
        setNewlySavedCity("");
        setNewlySavedCountry("");
        setNewlySavedPincode("");
        setNewlySavedState("");
        setNewlySavedProvince("");
        setShowNewAddressForm(false);
        getAllUserAddress();
        setErrorMsg2("");
        setErrorLine2("");
        setErrorMsg1("");
        setErrorLine1("");
        setErrorMsg3("");
        setErrorLine3("");
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const [changeAddressBoxColor, setChangeAddressBoxColor] = useState(false);

  // Change Address Box Styles onClick
  const addressBoxClick = (num) => {
    setChangeAddressBoxColor(!changeAddressBoxColor);
    if (changeAddressBoxColor) {
      document.getElementById(`addressBox${num}`).style.borderColor = "#99B37E";
      document.getElementById(`addressBox${num}`).style.borderWidth = "5px";
      document.getElementById(`addressIcons${num}`).style.display = "flex";
    } else {
      document.getElementById(`addressBox${num}`).style.borderColor = "silver";
      document.getElementById(`addressBox${num}`).style.borderWidth = "1px";
      document.getElementById(`addressIcons${num}`).style.display = "none";
    }
  };

  // Province
  useEffect(() => {
    const getProvince = async () => {
      try {
        const res = await fetch_get_provinces();
        if (!res.data.error) {
          setProvinceList(res.data.data);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getProvince();
  }, []);

  const options = provinceList.map((i) => {
    const obj = {
      label: i.ProvinceName,
      code: i.provinceCode,
      id: i.provinceId,
    };
    return obj;
  });

  // Get User Address By Address ID

  const editSavedAddress = async (id) => {
    const serverData = new FormData();
    serverData.append("addressId", id);
    try {
      const res = await fetch_get_user_address(serverData);
      if (!res.data.error) {
        setShowAllAddressBox(!showAllAddressBox);
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setselectedAddresstoUpdate(res.data.data);
        localStorage.setItem(
          "selectedAddressIdtoEdit",
          res.data.data.addressId
        );
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const addressIdfromLocal = JSON.parse(
    localStorage.getItem("selectedAddressIdtoEdit")
  );

  // Edit or Update Selcted Address

  const handleUpdateSelectedAddress = async () => {
    const obj = {
      address: updateSelectedAddress,
      city: updateSelectedCity,
      province: updateSelectedProvince,
      pincode: updateSelectedPincode,
      country: updateSelectedCountry,
      addressId: addressIdfromLocal,
      userId: userId,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_update_user_address(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setShowAllAddressBox(false);
        getAllUserAddress();
        setUpdateSelectedAddress("");
        setUpdateSelectedCity("");
        setUpdateSelectedCountry("");
        setUpdateSelectedPincode("");
        setNewlySavedProvince("");
        setUpdateSelectedState("");
        setUpdateSelectedProvince("");
        setUpdateSelectedCountry("");
        setErrorMsg2("");
        setErrorLine2("");
        setErrorMsg1("");
        setErrorLine1("");
        setErrorMsg3("");
        setErrorLine3("");
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  //  Set Default Address

  const setAsDefault = async (id) => {
    const obj = {
      isDefault: "1",
      addressId: id,
      userId: userId,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_set_default_address(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setShowAllAddressBox(false);
        getAllUserAddress();
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  //  Delete Address Dialog Box
  const [openDeleteBox, setOpenDeleteBox] = useState(false);

  const handleClickOpen = (id) => {
    setOpenDeleteBox(true);
    setDeleteAddressId(id);
  };
  const handleClose = () => {
    setOpenDeleteBox(false);
    setOpenOrdersProductsBox(false);
  };

  // Delete Saved Address
  const deleteSavedAddress = async (id) => {
    const serverData = new FormData();
    serverData.append("addressId", DeleteAddressId);
    try {
      const res = await fetch_delete_user_address(serverData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setOpenDeleteBox(false);
        getAllUserAddress();
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  //  Vaidate EMail
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    const regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    setIsValid(regex.test(emailValue));
    setUpdatedEmail(emailValue);
    if (!isValid) {
      setErrorMsg("Invalid Email ID");
    }
  };

  //  Update Email ID

  const updateUserEmailId = async () => {
    if (emailChangePassword !== "") {
      const obj = {
        userId: userId,
        email: updatedEmail,
        password: emailChangePassword,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_update_email(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setShowEmailUpdate(false);
          getUserDatatodisplay();
          setErrorMsg("");
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setErrorMsg(res.data.message);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      setErrorMsg("Enter Your Password");
    }
  };

  // Validate Password
  const validateInput = () => {
    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        setError(false);
      } else {
        setErrorMsg("Passwords do not match");
        setError(true);
      }
    } else {
      setErrorMsg("");
      setError(false);
    }
  };

  // Update Mobile Number
  const updateUserMobile = async () => {
    if (mobileChangePassword !== "") {
      const obj = {
        userId: userId,
        mobile: updatedMobile !== "" ? updatedMobile : userData.mobile,
        password: mobileChangePassword,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_update_mobile(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setErrorMsg("");
          setShowMobileUpdate(false);
          getUserDatatodisplay();
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setErrorMsg(res.data.message);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      setErrorMsg("Enter Your Password");
    }
  };

  // Update Password
  const updatePassword = async () => {
    if (password === confirmPassword) {
      const obj = {
        userId: userId,
        oldPassword: oldPassword,
        currentPassword: password,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_update_user_password(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setShowPasswordUpdate(false);
          getUserDatatodisplay();
          setErrorMsg("");
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      setErrorMsg("Passwords do not match");
    }
  };

  // Show All Orders
  const handleOpenAllorders = () => {
    setSearchedOrder([]);
    setHideAllOrders(false);
    getAllUserOrdersById();
    setExpanded(!expanded);
  };

  const handleOpenAccordion = () => {
    setExpanded(!expanded);
  };

  const logout = () => {
    navigate("/logout");
    localStorage.clear();
    setCartCount(0);
    setWishlistCount(0);
  };

  // US and Canada MObile View

  const formatMobileNumber = (mobileNumber) => {
    if (!mobileNumber) {
      return ""; // Return an empty string or some default value if mobileNumber is undefined or null
    }

    const countryCode = mobileNumber.slice(0, 1);
    const areaCode = mobileNumber.slice(1, 4);
    const firstPart = mobileNumber.slice(4, 7);
    const secondPart = mobileNumber.slice(7, 12);

    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  };

  return (
    <Box>
      <Header />
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />
      {!auth ? (
        <Box>
          <Container>
            <Box py={5}>
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={10}
                  xl={10}
                  border={1}
                  borderColor="silver"
                >
                  <Box
                    width="100%"
                    bgcolor="#faa634"
                    textAlign="left"
                    py={3}
                    color="#ffff"
                  >
                    <Typography
                      fontSize={20}
                      color="#FFFFFF"
                      fontWeight={500}
                      marginLeft={2}
                    >
                      <PersonOutlineIcon
                        padding={1}
                        fontSize="large"
                        sx={{
                          border: 1,
                          borderRadius: "50%",
                          verticalAlign: "middle",
                          marginRight: 2,
                        }}
                      />
                      Do we know you?
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Box textAlign="left" py={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack>
                            <Typography fontWeight={500} color="#707070">
                              My email address is
                            </Typography>
                            <TextField
                              sx={{
                                marginBottom: 5,
                                width: { xs: 250, sm: 300, md: 500, lg: 500 },
                              }}
                              size="small"
                              fullWidth
                              id="inputName"
                              defaultValue=""
                              type="email"
                              variant="outlined"
                              onChange={(e) => {
                                const newEmail = e.target.value;
                                const emailPattern =
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
                                const isValidEmail =
                                  emailPattern.test(newEmail);
                                if (isValidEmail) {
                                  setEmail(newEmail);
                                }
                              }}
                            />
                            <Typography fontWeight={500} color="#707070">
                              My password is
                            </Typography>
                            <Box
                              display="flex"
                              flexDirection="column"
                              mb={3}
                              gap={1}
                            >
                              <TextField
                                sx={{
                                  width: { xs: 250, sm: 300, md: 500, lg: 500 },
                                }}
                                id="inputpassword"
                                defaultValue=""
                                variant="outlined"
                                name="password"
                                type="password"
                                autoComplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span style={{ color: "red" }}>{errorMsg}</span>
                            </Box>
                            <Button
                              variant="contained"
                              onClick={onSubmit}
                              sx={{
                                height: 40,
                                boxShadow: "none",
                                bgcolor: "#99B37E",
                              }}
                            >
                              SIGN IN SECURELY
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Link to="resetPassword">
                    <Typography fontWeight={200}>
                      I've forgotten my password
                    </Typography>
                  </Link>
                  <Box
                    py={3}
                    bgcolor="#f5f5f5"
                    mt={3}
                    borderTop={1}
                    borderColor="#ccc"
                  >
                    <Typography>
                      Don't have an account with us?
                      <Link to="register">Register</Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      ) : (
        // If Logged in already

        <Box p={{ lg: 3, md: 3, xs: 1, sm: 1 }}>
          <Container>
            <Box>
              <Box textAlign="left" mt={3} mb={5}>
                <Typography color="#262626" fontSize={30} fontWeight={500}>
                  Your Account
                </Typography>
              </Box>

              <Box>
                <Grid container className="overGrid" spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={3}
                    xl={3}
                    md={3}
                    mt={2}
                    sx={{ overflowX: { xs: "auto", sm: "auto" } }}
                    className="grid1"
                  >
                    <Box
                      sx={{
                        border: {
                          lg: "1px solid silver",
                          md: "1px solid silver",
                        },
                      }}
                      textAlign="justify"
                    >
                      <Box
                        display="flex"
                        flexDirection={{
                          xs: "row",
                          sm: "row",
                          md: "column",
                          lg: "column",
                        }}
                        gap={{ lg: 3, md: 3, sm: 3, xs: 3 }}
                        p={2}
                        className="titlebox"
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleChangeTitle("Recent Orders")}
                          color={
                            selectedTitle === "Recent Orders" ? "#ffc107" : ""
                          }
                        >
                          <Typography>Orders</Typography>
                        </Box>

                        <Divider
                          className="divider"
                          sx={{ borderColor: "silver" }}
                        />

                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleChangeTitle("Account Details")}
                          color={
                            selectedTitle === "Account Details" ? "#ffc107" : ""
                          }
                        >
                          <Typography>Account Details</Typography>
                        </Box>

                        <Divider
                          className="divider"
                          sx={{ borderColor: "silver" }}
                        />

                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleChangeTitle("Addresses")}
                          color={selectedTitle === "Addresses" ? "#ffc107" : ""}
                        >
                          <Typography>Addresses</Typography>
                        </Box>

                        <Divider
                          className="divider"
                          sx={{ borderColor: "silver" }}
                        />

                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleChangeTitle("Saved Cards")}
                          color={
                            selectedTitle === "Saved Cards" ? "#ffc107" : ""
                          }
                        >
                          <Typography>Saved Cards</Typography>
                        </Box>

                        <Divider
                          className="divider"
                          sx={{ borderColor: "silver" }}
                        />

                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleChangeTitle("Vouchers")}
                          color={selectedTitle === "Vouchers" ? "#ffc107" : ""}
                        >
                          <Typography>Vouchers</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={9} md={9} xl={9}>
                    <Container>
                      <Box textAlign="left" ml={2}>
                        <Typography
                          color="#616e80"
                          variant="h6"
                          fontWeight={300}
                          fontSize={26}
                        >
                          {selectedTitle}
                        </Typography>
                      </Box>

                      {/* Recent Orders */}
                      {selectedTitle === "Recent Orders" ? (
                        <>
                          <Container>
                            <Box id="accountOrderSection">
                              <Accordion
                                sx={{
                                  boxShadow: 0,
                                  border: "1px solid silver",
                                }}
                                expanded={expanded}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <AddCircleOutlineIcon
                                      onClick={handleOpenAccordion}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  sx={{
                                    borderBottom: "1px solid silver",
                                    bgcolor: "#f5f5f5",
                                  }}
                                >
                                  <Typography>LOOKING FOR AN ORDER</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Stack spacing={5}>
                                    <Box py={2}>
                                      <TextField
                                        size="small"
                                        id="inputpassword"
                                        defaultValue=""
                                        variant="outlined"
                                        name="orderNumber"
                                        value={searchOrderNumber}
                                        onChange={(e) => {
                                          setSearchOrderNumber(e.target.value);
                                        }}
                                        required
                                        label="Your Order Number"
                                      />
                                      <Button
                                        variant="contained"
                                        className="button-62"
                                        sx={{
                                          ml: { lg: 4, md: 4, sm: 2, xs: 2 },
                                          mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                                        }}
                                        onClick={getAllUserOrdersByOrderId}
                                      >
                                        Find My Order
                                      </Button>
                                    </Box>
                                    <Box textAlign="left">
                                      <Typography>
                                        What do the numbers look like?
                                      </Typography>
                                      <Typography>
                                        You can find your order number on your
                                        receipt and it looks something like: -
                                        O1634567893
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </AccordionDetails>
                              </Accordion>

                              <Box textAlign="left" mt={3}>
                                {!hideAllOrders ? (
                                  <>
                                    <Typography
                                      fontWeight={600}
                                      color="#616e80"
                                      py={1}
                                    >
                                      All Your Orders
                                    </Typography>
                                    <Grid container spacing={2}>
                                      {searchedOrder &&
                                        searchedOrder.map((i, index) => {
                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                py={2}
                                                onClick={() =>
                                                  navigate("/vieworder", {
                                                    state: {
                                                      orderId: i.orderId,
                                                      userOrderId:
                                                        i.userOrderId,
                                                    },
                                                  })
                                                }
                                              >
                                                <Box
                                                  border={1}
                                                  borderColor="silver"
                                                  textAlign="left"
                                                >
                                                  <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    p={1}
                                                    mx={1}
                                                  >
                                                    <Box
                                                      py={1}
                                                      display="flex"
                                                      gap={1}
                                                      flexDirection="column"
                                                    >
                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Order Number :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {i.userOrderId}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Shipping Name :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {i.firstName}{" "}
                                                          {i.lastName}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Order Cost :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          ${i.totalCost}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Ordered At :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.orderTime}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Payment Mode :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.paymentOption}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Status :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.orderStatus}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box p={1}>
                                                    <Button>
                                                      View My Order
                                                    </Button>
                                                  </Box>
                                                </Box>
                                              </Grid>
                                            </>
                                          );
                                        })}
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        fontWeight={600}
                                        color="#616e80"
                                      >
                                        Order That You Searched For
                                      </Typography>
                                      <Button onClick={handleOpenAllorders}>
                                        Show All Orders
                                      </Button>
                                    </Box>
                                    <Grid container spacing={2}>
                                      {searchedOrder &&
                                        [searchedOrder].map((i, index) => {
                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                py={2}
                                                onClick={() =>
                                                  navigate("/vieworder", {
                                                    state: {
                                                      orderId: i.orderId,
                                                      userOrderId:
                                                        i.userOrderId,
                                                    },
                                                  })
                                                }
                                              >
                                                <Box
                                                  border={1}
                                                  borderColor="silver"
                                                  textAlign="left"
                                                >
                                                  <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    p={1}
                                                    mx={1}
                                                  >
                                                    <Box
                                                      py={1}
                                                      display="flex"
                                                      gap={1}
                                                      flexDirection="column"
                                                    >
                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Order Number :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {i.userOrderId}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Shipping Name :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {i.firstName}{" "}
                                                          {i.lastName}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Order Cost :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          ${i.totalCost}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Ordered At :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.orderTime}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Payment Mode :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.paymentOption}
                                                        </Typography>
                                                      </Box>

                                                      <Box
                                                        display="flex"
                                                        gap={1}
                                                      >
                                                        <Typography
                                                          fontWeight={550}
                                                          color="#616e80"
                                                        >
                                                          Status :
                                                        </Typography>
                                                        <Typography color="#262626">
                                                          {" "}
                                                          {i.orderStatus}
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box p={1}>
                                                    <Button>
                                                      View My Order
                                                    </Button>
                                                  </Box>
                                                </Box>
                                              </Grid>
                                            </>
                                          );
                                        })}
                                    </Grid>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Container>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* Account Details */}

                      {selectedTitle === "Account Details" ? (
                        <>
                          <Box border="1px solid silver">
                            <Box
                              p={3}
                              bgcolor="#f5f5f5"
                              textAlign="left"
                              borderBottom="1px solid silver"
                            >
                              <Typography>PERSONAL DETAILS</Typography>
                            </Box>

                            <Box
                              textAlign="left"
                              p={3}
                              display="flex"
                              flexDirection="column"
                              gap={4}
                              id="accountNameSection"
                            >
                              <Box>
                                <Box>
                                  <img
                                    alt="profile"
                                    src={
                                      profilePic
                                        ? URL.createObjectURL(profilePic) // Use the selected image
                                        : `${ProfileImageAPI}${userData.userProfile}` // Use the existing image
                                    }
                                    style={{
                                      width: 100,
                                      height: 100,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Box>
                                    <IconButton
                                      aria-label="upload"
                                      component="label"
                                    >
                                      <EditIcon onClick={handleChangeProfile} />
                                      <TextField
                                        hidden
                                        id="profilePicUpload"
                                        type="file"
                                        onChange={(e) =>
                                          setProfilePic(e.target.files[0])
                                        }
                                      />
                                    </IconButton>
                                  </Box>
                                  {showProfileUpdate && (
                                    <Box ml={1} display="flex" gap={1}>
                                      <Button
                                        variant="contained"
                                        onClick={updateUserData}
                                      >
                                        Update Picture
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() =>
                                          setShowProfileUpdate(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </Box>

                              {/* Name */}
                              <Box>
                                {!showNameUpdate ? (
                                  <Box>
                                    <Typography>Your Name</Typography>
                                    <Typography
                                      sx={{ marginBottom: 2 }}
                                      color="#616e80"
                                    >
                                      {userData.first_name} {userData.last_name}
                                      <EditIcon
                                        onClick={handleOpenPersonalName}
                                        sx={{ ml: 1, fontSize: 20 }}
                                      />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Stack spacing={2}>
                                    <Box>
                                      <Typography>Your First Name</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        defaultValue={userData.first_name}
                                        error={ErrorLine1}
                                        helperText={errorMsg1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          var regName = /^[A-Za-z]+$/;
                                          if (
                                            newValue.match(regName) ||
                                            newValue === ""
                                          ) {
                                            setErrorMsg1("");
                                            setErrorLine1(false);
                                            setUpdatedFirstName(newValue); // only set when successful
                                          } else {
                                            setErrorLine1(true);
                                            setErrorMsg1(
                                              "Forbidden character: %<>$'\""
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Your Last Name</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        defaultValue={userData.last_name}
                                        error={ErrorLine2}
                                        helperText={errorMsg2}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          var regName = /^[A-Za-z]+$/;
                                          if (
                                            newValue.match(regName) ||
                                            newValue === ""
                                          ) {
                                            setErrorMsg2("");
                                            setErrorLine2(false);
                                            setUpdatedLastName(newValue); // only set when successful
                                          } else {
                                            setErrorLine2(true);
                                            setErrorMsg2(
                                              "Forbidden character: %<>$'\""
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={updateUserData}
                                            size="small"
                                          >
                                            Update
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            onClick={handleOpenPersonalName}
                                            size="small"
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Stack>
                                )}
                              </Box>

                              {/* address */}

                              <Box
                                display="flex"
                                justifyContent="space-between"
                                id="accountProfileaddressSection"
                              >
                                {!showAddressUpdate ? (
                                  <Box>
                                    <Typography>Your Address</Typography>
                                    <Typography
                                      sx={{ marginBottom: 2 }}
                                      color="#616e80"
                                    >
                                      {userData.address}, {userData.city},{" "}
                                      {userData.province}, {userData.pincode},{" "}
                                      {userData.country}
                                      <EditIcon
                                        sx={{ ml: 1, fontSize: 20 }}
                                        onClick={handleOpenAccountAddress}
                                      />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Stack spacing={2}>
                                    <Box>
                                      <Typography>Your Address</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        defaultValue={userData.address}
                                        onChange={(e) =>
                                          setUpdatedAddress(e.target.value)
                                        }
                                        size="small"
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Your City</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        error={ErrorLine3}
                                        helperText={errorMsg3}
                                        defaultValue={userData.city}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          var regName = /^[A-Za-z]+$/;
                                          if (
                                            newValue.match(regName) ||
                                            newValue === ""
                                          ) {
                                            setErrorMsg3("");
                                            setErrorLine3(false);
                                            setUpdatedCity(newValue); // only set when successful
                                          } else {
                                            setErrorLine3(true);
                                            setErrorMsg3(
                                              "Forbidden character: %<>$'\""
                                            );
                                          }
                                        }}
                                        size="small"
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Your Province</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        onChange={(event, value) => {
                                          setUpdatedProvince(value.label);
                                        }}
                                        options={options}
                                        defaultValue={userData.province}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Your Pincode</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        defaultValue={userData.pincode}
                                        onChange={(e) =>
                                          setUpdatedPincode(e.target.value)
                                        }
                                        size="small"
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Your Country</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        options={[
                                          {
                                            label: "Canada",
                                            code: "CA",
                                          },
                                        ]}
                                        defaultValue={userData.country}
                                        onChange={(event, value) => {
                                          setUpdatedCountry(value.label);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>
                                    <Box>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={updateUserData}
                                            size="small"
                                          >
                                            Update
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            onClick={handleOpenAccountAddress}
                                            size="small"
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Stack>
                                )}
                              </Box>
                            </Box>

                            <Box
                              p={3}
                              bgcolor="#f5f5f5"
                              textAlign="left"
                              borderBottom="1px solid silver"
                            >
                              <Typography>ACCOUNT DETAILS</Typography>
                            </Box>

                            <Box
                              textAlign="left"
                              p={3}
                              display="flex"
                              flexDirection="column"
                              gap={4}
                            >
                              <Box>
                                {!showEmailUpdate ? (
                                  <Box>
                                    <Typography>Your Email ID</Typography>
                                    <Typography
                                      sx={{ marginBottom: 2 }}
                                      color="#616e80"
                                    >
                                      {userData.email}
                                      <EditIcon
                                        onClick={handleOpenAccountEmail}
                                        sx={{ ml: 1, fontSize: 20 }}
                                      />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Box
                                      display="grid"
                                      justifyContent="center"
                                      border="10px solid #99B37E"
                                    >
                                      <Box textAlign="left" py={5}>
                                        <Stack>
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Your Email ID
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            fullWidth
                                            onChange={handleEmailChange}
                                            size="small"
                                            defaultValue={userData.email}
                                            required
                                          />
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Your Password
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            size="small"
                                            id="inputconfirmpassword"
                                            onChange={(e) =>
                                              setEmailChangePassword(
                                                e.target.value
                                              )
                                            }
                                            variant="outlined"
                                            name="confirmPassword"
                                            type="password"
                                            autoComplete="off"
                                            helperText={errorMsg}
                                            required
                                          />
                                          <Box>
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  fullWidth
                                                  onClick={updateUserEmailId}
                                                  size="small"
                                                >
                                                  Update
                                                </Button>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="error"
                                                  fullWidth
                                                  onClick={
                                                    handleOpenAccountEmail
                                                  }
                                                  size="small"
                                                >
                                                  Cancel
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Stack>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Box>

                              <Box>
                                {!showMobileUpdate ? (
                                  <Box>
                                    <Typography>Your Phone No</Typography>
                                    <Typography
                                      sx={{ marginBottom: 2 }}
                                      color="#616e80"
                                    >
                                      {formatMobileNumber(userData?.mobile)}
                                      <EditIcon
                                        sx={{ ml: 1, fontSize: 20 }}
                                        onClick={handleOpenPersonalmobile}
                                      />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Box
                                      display="grid"
                                      justifyContent="center"
                                      border="10px solid #99B37E"
                                    >
                                      <Box textAlign="left" py={5}>
                                        <Stack>
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Your Phone No
                                          </Typography>
                                          <Box
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                          >
                                            <PhoneInput
                                              country="ca"
                                              regions={[
                                                "north-america",
                                                "carribean",
                                              ]}
                                              value={userData.mobile}
                                              onChange={(value, data) =>
                                                setUpdatedMobile(value)
                                              }
                                              autoComplete="off"
                                            />
                                          </Box>
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Your Password
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            size="small"
                                            id="inputconfirmpassword"
                                            onChange={(e) =>
                                              setMobileChangePassword(
                                                e.target.value
                                              )
                                            }
                                            variant="outlined"
                                            name="confirmPassword"
                                            type="password"
                                            required
                                            helperText={errorMsg}
                                          />
                                          <Box>
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  fullWidth
                                                  onClick={updateUserMobile}
                                                  size="small"
                                                >
                                                  Update
                                                </Button>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="error"
                                                  fullWidth
                                                  onClick={
                                                    handleOpenPersonalmobile
                                                  }
                                                  size="small"
                                                >
                                                  Cancel
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Stack>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Box>

                              {/* password */}
                              <Box>
                                {!showPasswordUpdate ? (
                                  <Box>
                                    <Typography>Your Password</Typography>
                                    <Typography
                                      sx={{ marginBottom: 2 }}
                                      color="#616e80"
                                    >
                                      ●●●●●●●●
                                      <EditIcon
                                        sx={{ ml: 1, fontSize: 20 }}
                                        onClick={handleOpenAccountPassword}
                                      />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Box
                                      display="grid"
                                      justifyContent="center"
                                      border="10px solid #99B37E"
                                    >
                                      <Box textAlign="left" py={5}>
                                        <Stack>
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Your Old Password
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            size="small"
                                            id="inputoldPasswordpassword"
                                            defaultValue=""
                                            variant="outlined"
                                            name="oldPassword"
                                            type="password"
                                            onChange={(e) =>
                                              setOldPassword(e.target.value)
                                            }
                                          />
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Enter Current Password
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            size="small"
                                            id="inputpassword"
                                            defaultValue=""
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            onChange={(e) => {
                                              setPassword(e.target.value);
                                            }}
                                            onBlur={validateInput}
                                          />
                                          <Typography
                                            fontWeight={500}
                                            color="#707070"
                                          >
                                            Confirm Current Password
                                          </Typography>
                                          <TextField
                                            sx={{
                                              width: {
                                                xs: 250,
                                                sm: 300,
                                                md: 500,
                                                lg: 500,
                                              },
                                              marginBottom: 5,
                                            }}
                                            size="small"
                                            id="inputconfirmpassword"
                                            defaultValue=""
                                            variant="outlined"
                                            name="confirmPassword"
                                            type="password"
                                            autoComplete="off"
                                            helperText={errorMsg}
                                            onBlur={validateInput}
                                            onChange={(e) => {
                                              setConfirmPassword(
                                                e.target.value
                                              );
                                            }}
                                            error={error}
                                          />
                                          <Box>
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  fullWidth
                                                  onClick={updatePassword}
                                                  size="small"
                                                >
                                                  Update
                                                </Button>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="error"
                                                  fullWidth
                                                  onClick={
                                                    handleOpenAccountPassword
                                                  }
                                                  size="small"
                                                >
                                                  Cancel
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Stack>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Box>

                              <Box py={2}>
                                <Button
                                  onClick={logout}
                                  className="button-62"
                                  fullWidth
                                  variant="contained"
                                >
                                  SIGN OUT
                                  <IconButton>
                                    <LogoutIcon sx={{ color: "white" }} />
                                  </IconButton>
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* Address */}

                      {selectedTitle === "Addresses" ? (
                        <>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box
                                border="1px solid silver"
                                textAlign="left"
                                p={3}
                                id="accountAddressSection"
                              >
                                {!showNewAddressForm ? (
                                  <Box px={2}>
                                    <Button
                                      variant="contained"
                                      className="button-62"
                                      onClick={openSavedAddressForm}
                                    >
                                      Add New Address
                                    </Button>
                                  </Box>
                                ) : (
                                  <Stack spacing={2} p={3}>
                                    <Box>
                                      <Typography>Street Address</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        value={newlySavedAddress}
                                        onChange={(e) =>
                                          setNewlySavedAddress(e.target.value)
                                        }
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>City</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        value={newlySavedCity}
                                        error={ErrorLine3}
                                        helperText={errorMsg3}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          var regName = /^[A-Za-z]+$/;
                                          if (
                                            newValue.match(regName) ||
                                            newValue === ""
                                          ) {
                                            setErrorMsg1("");
                                            setErrorLine1(false);
                                            setNewlySavedCity(newValue); // Allow setting an empty string when newValue is empty
                                          } else {
                                            setErrorLine1(true);
                                            setErrorMsg1(
                                              "Forbidden character: %<>$'\""
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography>Province</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        onChange={(event, value) => {
                                          setNewlySavedProvince(value.label);
                                        }}
                                        options={options}
                                        value={newlySavedProvince}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>Pincode</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        value={newlySavedPincode}
                                        onChange={(e) =>
                                          setNewlySavedPincode(e.target.value)
                                        }
                                        inputProps={{ maxLength: 6 }}
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>Country</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        options={[
                                          {
                                            label: "Canada",
                                            code: "CA",
                                          },
                                        ]}
                                        value={newlySavedCountry}
                                        onChange={(event, value) => {
                                          setNewlySavedCountry(value.label);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>
                                    <Button
                                      variant="contained"
                                      onClick={submitNewlySavedAddress}
                                    >
                                      Save New Address
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() =>
                                        setShowNewAddressForm(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                )}
                              </Box>

                              {!showAllAddressBox ? (
                                <Box
                                  border="1px solid silver"
                                  textAlign="left"
                                  p={3}
                                  mt={3}
                                >
                                  <Typography fontWeight={600} color="#616e80">
                                    All Your Saved Addresses
                                  </Typography>
                                  <Typography variant="caption" color="#616e80">
                                    (Click the Address to Edit)
                                  </Typography>
                                  {allAddresses &&
                                    allAddresses.map((i, index) => {
                                      return (
                                        <Box
                                          id={`addressBox${index}`}
                                          border={1}
                                          borderColor="silver"
                                          textAlign="left"
                                          p={3}
                                          mt={2}
                                          onClick={() => addressBoxClick(index)}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              xl={12}
                                              sx={{ cursor: "pointer" }}
                                            >
                                              <Box
                                                display="flex"
                                                justifyContent="space-between"
                                              >
                                                <Box display="flex" gap={2}>
                                                  <Box
                                                    border={1}
                                                    borderRadius="50%"
                                                    width={20}
                                                    height={25}
                                                    bgcolor="#616e80"
                                                    color="#ffff"
                                                    textAlign="center"
                                                  >
                                                    <Typography>
                                                      {index + 1}
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography>
                                                      {i.address}, {i.city},
                                                    </Typography>
                                                    <Typography>
                                                      {i.province}, {i.pincode},{" "}
                                                      {i.country}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                {i.isDefault === "Yes" && (
                                                  <Box
                                                    display="flex"
                                                    justifyContent="end"
                                                  >
                                                    <Typography
                                                      color="green"
                                                      fontWeight={500}
                                                    >
                                                      Default Address
                                                    </Typography>
                                                  </Box>
                                                )}
                                              </Box>
                                              <Box
                                                display="none"
                                                justifyContent="end"
                                                gap={4}
                                                id={`addressIcons${index}`}
                                              >
                                                {i.isDefault !== "Yes" && (
                                                  <Typography
                                                    color="primary"
                                                    onClick={() => {
                                                      setAsDefault(i.addressId);
                                                    }}
                                                  >
                                                    Set as Default
                                                  </Typography>
                                                )}
                                                <Typography
                                                  color="#faa634"
                                                  onClick={() => {
                                                    editSavedAddress(
                                                      i.addressId
                                                    );
                                                  }}
                                                >
                                                  <EditIcon
                                                    fontSize="small"
                                                    sx={{
                                                      verticalAlign: "middle",
                                                    }}
                                                  />
                                                  Edit
                                                </Typography>
                                                <Typography
                                                  color="error"
                                                  onClick={() =>
                                                    handleClickOpen(i.addressId)
                                                  }
                                                >
                                                  <DeleteIcon
                                                    fontSize="small"
                                                    sx={{
                                                      verticalAlign: "middle",
                                                    }}
                                                  />
                                                  Delete
                                                </Typography>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      );
                                    })}
                                  <>
                                    <Dialog
                                      open={openDeleteBox}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        {
                                          "Are You Sure You Wanna Delete This Address Permanently ?"
                                        }
                                      </DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          Let Google help apps determine
                                          location. This means sending anonymous
                                          location data to Google, even when no
                                          apps are running.
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <IconButton>
                                          <Button onClick={deleteSavedAddress}>
                                            YES
                                          </Button>
                                        </IconButton>
                                        <IconButton>
                                          <Button
                                            onClick={handleClose}
                                            autoFocus
                                          >
                                            No
                                          </Button>
                                        </IconButton>
                                      </DialogActions>
                                    </Dialog>
                                  </>
                                </Box>
                              ) : (
                                <Box
                                  border="1px solid silver"
                                  textAlign="left"
                                  p={3}
                                  mt={3}
                                >
                                  <Box px={2}>
                                    <Typography
                                      fontWeight={600}
                                      color="#616e80"
                                    >
                                      Update Your Address
                                    </Typography>
                                  </Box>
                                  <Stack spacing={2} p={3}>
                                    <Box>
                                      <Typography>Street Address</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        defaultValue={
                                          selectedAddresstoUpdate.address
                                        }
                                        onChange={(e) =>
                                          setUpdateSelectedAddress(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>City</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        defaultValue={
                                          selectedAddresstoUpdate.city
                                        }
                                        error={ErrorLine3}
                                        helperText={errorMsg3}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          var regName = /^[A-Za-z]+$/;
                                          if (
                                            newValue.match(regName) ||
                                            newValue === ""
                                          ) {
                                            setErrorMsg1("");
                                            setErrorLine1(false);
                                            setUpdateSelectedCity(newValue); // Allow setting an empty string when newValue is empty
                                          } else {
                                            setErrorLine1(true);
                                            setErrorMsg1(
                                              "Forbidden character: %<>$'\""
                                            );
                                          }
                                        }}
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>Province</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        onChange={(event, value) => {
                                          setUpdateSelectedProvince(
                                            value.label
                                          );
                                        }}
                                        options={options}
                                        defaultValue={
                                          selectedAddresstoUpdate.province
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>Pincode</Typography>
                                      <TextField
                                        id="outlined-start-adornment"
                                        fullWidth
                                        size="small"
                                        defaultValue={
                                          selectedAddresstoUpdate.pincode
                                        }
                                        onChange={(e) =>
                                          setUpdateSelectedPincode(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>

                                    <Box>
                                      <Typography>Country</Typography>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="medium"
                                        options={[
                                          {
                                            label: "Canada",
                                            code: "CA",
                                          },
                                        ]}
                                        defaultValue={
                                          selectedAddresstoUpdate.country
                                        }
                                        onChange={(event, value) => {
                                          setUpdateSelectedCountry(value.label);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            size="small"
                                          />
                                        )}
                                      />
                                    </Box>
                                    <Button
                                      variant="contained"
                                      onClick={handleUpdateSelectedAddress}
                                    >
                                      Update Address
                                    </Button>
                                  </Stack>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* Saved Cards */}

                      {selectedTitle === "Saved Cards" ? (
                        <Box
                          display="flex"
                          justifyContent={{
                            xs: "center",
                            sm: "center",
                            md: "start",
                            lg: "start",
                          }}
                          p={1}
                          mt={1}
                        >
                          <CreditCardDesign />
                        </Box>
                      ) : (
                        <></>
                      )}

                      {selectedTitle === "Vouchers" ? (
                        <>
                          <Box border="1px solid silver" py={5}>
                            <Box px={2} textAlign="left">
                              <Typography>There are no Vouchers</Typography>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Container>
                  </Grid>

                  {/* End of Overall Grid */}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )}

      <Footer />
      <BottomCredLink />
      <ToastContainer />
    </Box>
  );
}
