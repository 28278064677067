import { createContext } from "react";
import { useState, useEffect } from "react";
import { fetch_count_cart_wishlist } from "../Services/Services/ProductService";

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userAuth = JSON.parse(localStorage.getItem("Userauth"));
  const [CartCount, setCartCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    if (userAuth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_count_cart_wishlist(serverData);
        if (!res.data.error) {
          setCartCount(res.data.data.cartCount);
          setWishlistCount(res.data.data.wishlistCount);
          setRefresh(false);
        } else {
          setCartCount(0);
          setWishlistCount(0);
          setRefresh(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh, userId]);

  const value = {
    CartCount,
    wishlistCount,
    setCartCount,
    setWishlistCount,
    setRefresh,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
