import { Box, Grid, Link, Typography } from "@mui/material";
import gbusiness from "../../Assets/BusinessLogo/google-my-bussines-logo-554E98BE88-seeklogo.com.png";
import yelp from "../../Assets/BusinessLogo/yelp.png";
import trustpilot from "../../Assets/BusinessLogo/Trustpilot_logo.png";
import purapost from "../../Assets/FooterCards/purapost.png";
import tawk from "../../Assets/BusinessLogo/tawk.webp";

export default function BottomCredLink() {
  return (
    <Box>
      <Box paddingRight={2} paddingLeft={2} mt={1}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mt={2}
            mb={2}
            display="flex"
            justifyContent="center"
            gap={3}
            className="bottomlogo"
          >
            <img
              src={gbusiness}
              style={{ width: 100, height: 40, objectFit: "contain" }}
              alt="img"
            ></img>
            <img
              src={yelp}
              style={{ width: 100, height: 40, objectFit: "contain" }}
              alt="img"
            ></img>
            <img
              src={trustpilot}
              style={{ width: 100, height: 40, objectFit: "contain" }}
              alt="img"
            ></img>
            <img
              src={purapost}
              style={{ width: 100, height: 40, objectFit: "contain" }}
              alt="img"
            ></img>
            <img
              src={tawk}
              style={{ width: 100, height: 40, objectFit: "cover" }}
              alt="img"
            ></img>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
            <Box mt={1}>
              <Link
                href="/privacypolicy"
                underline="none"
                color="#49484a"
                fontFamily={"CeraStencilPRO-Bold"}
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
              >
                Privacy policy
              </Link>
              <Link
                href="/shippingPolicy"
                underline="none"
                color="#49484a"
                fontFamily={"CeraStencilPRO-Bold"}
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
              >
                Shipping policy
              </Link>
              <Link
                href="/cookiespolicy"
                underline="none"
                color="#49484a"
                fontFamily={"CeraStencilPRO-Bold"}
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
              >
                Cookies policy
              </Link>
              <Link
                href="/store"
                underline="none"
                color="#49484a"
                fontFamily={"CeraStencilPRO-Bold"}
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
              >
                Store Associates
              </Link>
              <Link
                href="/inspiration"
                underline="none"
                color="#49484a"
                fontFamily={"CeraStencilPRO-Bold"}
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
              >
                Inspiration
              </Link>
              <Link
                href="/termsandconditions"
                fontFamily={"CeraStencilPRO-Bold"}
                underline="none"
                color="#49484a"
                paddingLeft={1}
                paddingRight={1}
              >
                Terms & conditions
              </Link>
            </Box>
            <Box
              p={1}
              mt={2}
              mb={1}
              display="flex"
              gap={5}
              justifyContent="center"
              flexDirection={{
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              }}
            >
              <Typography sx={{ color: "#49484a" }}>
                {" "}
                © Shopztrend Enterprises Inc {new Date().getFullYear()}. All
                Rights Reserved.
              </Typography>
              {/* <Link href='https://www.mdqualityapps.com/' underline="none" target="_blank" rel="noopener">
            <Typography sx={{color:'#49484a'}}> Designed by  MDQuality Apps Solutions</Typography>
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
