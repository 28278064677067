import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Link, } from '@mui/material'
import { useNavigate } from "react-router-dom";

export default function TrendingCards({section, layout ,title}) {

    const navigate = useNavigate();
    const handleClickOpenProductCategory = (category) => {
      navigate(`/contactus#CHeader` , {state:{bulk:'1'}})
      };
    


  return (
    <Container>
    <Box textAlign='left' mt={5}>
    <Typography variant='h5' fontWeight={500}>{title}</Typography>
    </Box>
    {
      layout === '9' ? 
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12}>
      {
      <Box my={2}>
      <img src={section?.cards[0]?.images} alt='banner' style={{
        objectFit: 'cover',
        objectPosition :'center',
        width:'100%',
      }} />
      </Box>
      }
      </Grid>
      </Grid>
      :
      <Grid container spacing={2} justifyContent='center'>
      {
      section?.cards?.map((i, index)=>{
      return (
      <Grid sx={{cursor:'pointer'}} item xs={12} sm={12} md={4} lg={4} xl={4} key={index} mt={2} mb={2} display='flex' justifyContent={{lg:'start', md:'start', sm:'center', xs:'center'}}>
      <Card onClick={() => handleClickOpenProductCategory(i.category)} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth: 385, height: '100%', textAlign: 'left', ":hover": { boxShadow: '0px 0px 4px 3px silver' } }}>
      <CardMedia
      component='img'
      sx={{width: 385 , height:300, objectFit:'contain', objectPosition:'center center' }}
      image={i.images}
      title={i.title}
      />
      <Box bgcolor='#f5f5f5'>
      <CardContent display='flex' flexDirection='column' justifyContent='space-between'>
      <Typography variant="h4"  fontWeight={600} fontSize={18} mt={1}>
      {i.title}
      </Typography>
      <Link onClick={() => handleClickOpenProductCategory(i.category)}><Typography sx={{cursor:'pointer'}} mt={1} fontWeight={500} color='#0c509f' fontSize={15}>Inquire Now</Typography></Link>
      </CardContent>
      </Box>
      </Card>
      </Grid>
      )})
      }
      </Grid>
    }
    </Container>
  )
}
