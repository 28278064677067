import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../Comps/Header/Header";
import { appendData } from "../../Variables/ProcessVariable";
import { ProductsContext } from "../../Context/CountContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBar from "../../Comps/Snackbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  fetch_calculate_full_estimate,
  fetch_confirm_payment_intent,
  fetch_create_setup_intent,
  fetch_placeorders,
} from "../../Services/Services/OrderService";
import {
  fetch_get_user_cart,
  fetch_pay_order_payment,
} from "../../Services/Services/ProductService";

export default function FullCheckoutPage(props) {
  const userId = JSON.parse(localStorage.getItem("userId"));

  const storedShippingAddressId = localStorage.getItem("ShippingAddressId");
  const [ShippingAddressId, setShippingAddressId] = useState(
    storedShippingAddressId
  );
  const [checkoutData, setCheckoutData] = useState([]);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [taxInfo, setTaxinfo] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { setCartCount } = useContext(ProductsContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Shipping address", "Payment Details"];
  const [selectedPaymentAccord, setselectedPaymentAccord] = useState(
    "Credit / Debit Card"
  );
  const [loading, setLoading] = React.useState(false);
  const [SelectedService, setSelectedService] = useState("");
  const [ShippingPrice, setShippingPrice] = useState(0);
  const [showError, setshowError] = useState(false);
  const [TotalWeightFromShipping, setTotalWeightFromShipping] = useState("");
  const [TotalPiecesFromShipping, setTotalPiecesFromShipping] = useState("");
  const [paymentFormData, setPaymentFormData] = useState("");
  const [ShippingServiceList, setShippingServiceList] = useState([]);
  const [subtotal, setSubTotal] = useState("");
  const [calculatedData, setCalculatedData] = useState([]);
  const [clientSecret, setclientSecret] = useState("");
  const [products, setProducts] = useState([]);
  const [rememberCard, setRememberCard] = useState("0");
  const [orderNumber, setOrderNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [TransactionNumber, setTransactionNumber] = useState("");
  const [tranNoError, setTranNoError] = useState(false);
  const [selectedSaveCardPayment, setselectedSaveCardPayment] = useState(false);
  const [SavedPaymentMethod, setSavedPaymentMethod] = useState("");
  const [SavedCustomerId, setSavedCustomerId] = useState("");
  const [paymentIntentIdFromStripe, setpaymentIntentIdFromStripe] =
    useState("");
  const stripePromise = loadStripe(
    "pk_live_51NBOwyBRnDqmJB4eqdKKfrvm9yZVVYbhdgDFz6IXFryIEXg1F2IOo2AGJv8r6zXqpWFOWI7RiqhIQUm6bC7pfg6B00wqqquasA"
  );
  const [paymentIntentKey, setPaymentIntentKey] = useState("");
  const [runConfirm, setRunConfirm] = useState(false);
  const [ExpectedDelivery, setExpectedDelivery] = useState("");

  function getFormValues() {
    const storedValues = localStorage.getItem("CheckoutaddressForm");
    if (!storedValues)
      return {
        first_name: "",
        last_name: "",
        address_1: "",
        state: "",
        phone: "",
        city: "",
        postcode: "",
        country: "",
        countryCode: "",
        stateCode: "",
      };
    return JSON.parse(storedValues);
  }
  const [addressForm, setAddresForm] = useState(getFormValues);

  const createSetupIntent = async () => {
    try {
      const res = await fetch_create_setup_intent();
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setclientSecret(res.data.data.client_secret);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    createSetupIntent();
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            setCalculatedData={setCalculatedData}
            addressForm={addressForm}
            setAddresForm={setAddresForm}
            products={products}
            subtotal={subtotal}
            TotalPiecesFromShipping={TotalPiecesFromShipping}
            setShippingServiceList={setShippingServiceList}
            setTotalPiecesFromShipping={setTotalPiecesFromShipping}
            setTotalWeightFromShipping={setTotalWeightFromShipping}
            ShippingAddressId={ShippingAddressId}
            setShippingAddressId={setShippingAddressId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setTaxinfo={setTaxinfo}
          />
        );
      case 1:
        return (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm
              runConfirm={runConfirm}
              setSavedCustomerId={setSavedCustomerId}
              createSetupIntent={createSetupIntent}
              errorMsg={errorMsg}
              successMsg={successMsg}
              rememberCard={rememberCard}
              setCustomerId={setCustomerId}
              paymentIntentKey={paymentIntentKey}
              setPaymentIntentKey={setPaymentIntentKey}
              clientSecret={clientSecret}
              selectedSaveCardPayment={selectedSaveCardPayment}
              SavedPaymentMethod={SavedPaymentMethod}
              setselectedSaveCardPayment={setselectedSaveCardPayment}
              setSavedPaymentMethod={setSavedPaymentMethod}
              setRememberCard={setRememberCard}
              setPaymentFormData={setPaymentFormData}
              paymentFormData={paymentFormData}
              setselectedPaymentAccord={setselectedPaymentAccord}
              setCheckoutData={setCheckoutData}
              ShippingAddressId={ShippingAddressId}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Elements>
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleAccordion = () => {
    setExpanded(!expanded);
  };

  const getUserCartData = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_user_cart(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setSubTotal(res.data.cart[1].totalCartValue);
        setProducts(res.data.cart[0]);
        setTotalPiecesFromShipping(res.data.cart[2].totalCartQty);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
        setOpen(true);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserCartData();
  }, [userId]);

  const province = localStorage.getItem("SelectedProvince");

  const HSTProvincesList = [
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
  ];

  const HSTProvince = HSTProvincesList.some((i) => i === province);

  const handleClickOpenOrderconfirmation = () => {
    navigate("/fullcheckoutpage#orderconfirmation");
    setTimeout(() => {
      const element = document.getElementById("orderconfirmation");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const options =
    ShippingServiceList &&
    ShippingServiceList.map((i) => {
      const obj = {
        label: i.ServiceId,
        price: i.TotalPrice,
        date: i.ExpectedDeliveryDate,
      };
      return obj;
    });

  const itemlist = products.map((i) => {
    const obj = {
      productId: i.productId,
      productName: i.productName,
      productPrice: i.productPrice,
      orderedQty: i.orderedQty,
      productColor: i.orderedColor,
    };
    return obj;
  });

  // const dateObj = new Date(paymentFormData?.expiry_date);
  // const card_month = dateObj?.getMonth() + 1;
  // const card_year = dateObj?.getFullYear();

  const handlePlaceOrder = () => {
    if (subtotal < 250) {
      if (SelectedService !== null && SelectedService !== "") {
        if (selectedPaymentAccord === "Credit / Debit Card") {
          if (customerId !== "") {
            ProceedToPayCard();
          } else {
            errorMsg("Please Add Your Card Details");
          }
        } else if (selectedPaymentAccord === "Check / Cheque") {
          CheckPayment();
        } else if (selectedPaymentAccord === "Interac e-Transfer") {
          if (
            TransactionNumber !== "" &&
            TransactionNumber !== undefined &&
            TransactionNumber !== null
          ) {
            ETPayment();
          } else {
            errorMsg("Please Enter the Transaction Number");
            setTranNoError(true);
          }
        } else {
          errorMsg("Please Select a Payment Mode");
        }
      } else {
        setshowError(true);
        errorMsg("Please Choose A Shipping Service");
      }
    } else {
      if (selectedPaymentAccord === "Credit / Debit Card") {
        if (customerId !== "") {
          ProceedToPayCard();
        } else {
          errorMsg("Please Fill the Card Details");
        }
      } else if (selectedPaymentAccord === "Check / Cheque") {
        CheckPayment();
      } else if (selectedPaymentAccord === "Interac e-Transfer") {
        if (
          TransactionNumber !== "" &&
          TransactionNumber !== undefined &&
          TransactionNumber !== null
        ) {
          ETPayment();
        } else {
          errorMsg("Please Enter the Transaction Number");
          setTranNoError(true);
        }
      } else {
        errorMsg("Please Select a Payment Mode");
      }
    }
  };

  const CheckPayment = async () => {
    const TotalOrderPayment = taxInfo.total_amount + ShippingPrice;
    if (TotalOrderPayment >= 1000) {
      setLoading(true);
      const obj = {
        userId: userId,
        addressId: ShippingAddressId,
        items: JSON.stringify(itemlist),
        firstPromoOffer: "",
        orderCost: subtotal,
        paymentOption: selectedPaymentAccord,
        shippingService: SelectedService,
        shippingCost: ShippingPrice,
        totalWeight: TotalWeightFromShipping,
        totalPieces: TotalPiecesFromShipping,
        taxCost: taxInfo.total_tax_amount,
        expectedDelivery: ExpectedDelivery,
        paymentIntentId: "",
        transactionNum: "",
      };
      const serverData = appendData(obj);
      try {
        const res = await fetch_placeorders(serverData);
        if (!res.data.error) {
          getFullEstimatedShipping();
          setOrderNumber(res.data.data.userOrderId);
        } else if (res.data.error) {
          errorMsg(res.data.message);
          setLoading(false);
        } else {
          errorMsg("Something Went Wrong. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      errorMsg("Eligible For Orders Above $1000");
    }
  };

  const ETPayment = async () => {
    // const TotalOrderPayment = taxInfo.total_amount + ShippingPrice;
    if (
      TransactionNumber !== "" &&
      TransactionNumber !== undefined &&
      TransactionNumber !== null
    ) {
      setLoading(true);
      const obj = {
        userId: userId,
        addressId: ShippingAddressId,
        items: JSON.stringify(itemlist),
        firstPromoOffer: "",
        orderCost: subtotal,
        paymentOption: selectedPaymentAccord,
        shippingService: SelectedService,
        shippingCost: ShippingPrice,
        totalWeight: TotalWeightFromShipping,
        totalPieces: TotalPiecesFromShipping,
        expectedDelivery: ExpectedDelivery,
        taxCost: taxInfo.total_tax_amount,
        paymentIntentId: "",
        transactionNum: TransactionNumber,
      };
      const serverData = appendData(obj);
      try {
        const res = await fetch_placeorders(serverData);
        if (!res.data.error) {
          getFullEstimatedShipping();
          setOrderNumber(res.data.data.userOrderId);
        } else if (res.data.error) {
          errorMsg(res.data.message);
          setLoading(false);
        } else {
          errorMsg("Something Went Wrong. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      errorMsg("Please Enter the Transaction Number");
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ProceedToPayCard = async () => {
    const TotalOrderPayment = taxInfo.total_amount + ShippingPrice;
    if (selectedPaymentAccord === "Credit / Debit Card") {
      setLoading(true);
      if (!selectedSaveCardPayment) {
        const obj = {
          userId: userId,
          orderCost: TotalOrderPayment.toFixed(3),
          paymentOption: selectedPaymentAccord,
          paymentMethod: paymentIntentKey,
          customerId: customerId,
        };
        const serverData = appendData(obj);
        try {
          const res = await fetch_pay_order_payment(serverData);
          if (res.data.error) {
            errorMsg(res.data.message);
            setLoading(false);
          } else if (!res.data.error) {
            confirmPaymentIntent(res.data.paymentIntentId);
            setpaymentIntentIdFromStripe(res.data.paymentIntentId);
          } else {
            errorMsg("Something Went Wrong. Please try again.");
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      } else if (selectedSaveCardPayment) {
        const obj = {
          userId: userId,
          orderCost: TotalOrderPayment.toFixed(3),
          paymentOption: selectedPaymentAccord,
          paymentMethod: SavedPaymentMethod,
          customerId: SavedCustomerId,
        };
        const serverData = appendData(obj);
        try {
          const res = await fetch_pay_order_payment(serverData);
          if (res.data.error) {
            errorMsg(res.data.message);
            setLoading(false);
          } else if (!res.data.error) {
            confirmPaymentIntent(res.data.paymentIntentId);
            setpaymentIntentIdFromStripe(res.data.paymentIntentId);
          } else {
            errorMsg("Something Went Wrong. Please try again.");
            setLoading(false);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    }
  };

  const confirmPaymentIntent = async (intentId) => {
    const sendData = new FormData();
    sendData.append("paymentIntentId", intentId);
    sendData.append("paymentMethod", paymentIntentKey);
    try {
      const res = await fetch_confirm_payment_intent(sendData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        if (res.data.data.status === "succeeded") {
          handlePlaceCardOrder(intentId);
        } else {
          errorMsg("Something Went Wrong. Please try again.");
          setLoading(false);
        }
      } else {
        errorMsg("Something Went Wrong. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const handlePlaceCardOrder = async (intentId) => {
    const obj = {
      userId: userId,
      addressId: ShippingAddressId,
      items: JSON.stringify(itemlist),
      firstPromoOffer: "",
      orderCost: subtotal,
      paymentOption: selectedPaymentAccord,
      shippingService: SelectedService,
      shippingCost: ShippingPrice,
      totalWeight: TotalWeightFromShipping,
      totalPieces: TotalPiecesFromShipping,
      taxCost: taxInfo.total_tax_amount,
      expectedDelivery: ExpectedDelivery,
      paymentIntentId: intentId,
      transactionNum: "",
    };
    const serverData = appendData(obj);
    try {
      const res = await fetch_placeorders(serverData);
      if (!res.data.error) {
        getFullEstimatedShipping();
        setOrderNumber(res.data.data.userOrderId);
      } else if (res.data.error) {
        errorMsg(res.data.message);
        setLoading(false);
      } else {
        errorMsg("Something Went Wrong. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const getFullEstimatedShipping = async () => {
    const name = `${addressForm.first_name} ${addressForm.last_name}`;
    const obj = {
      receiverName: name,
      receiverStreetNumber: "",
      receiverStreetName: addressForm.address_1,
      receiverCity: addressForm.city,
      receiverProvince: addressForm.stateCode,
      receiverCountry: "CA",
      receiverPostalCode: addressForm.postcode,
      receiverCountryCode: "1",
      receiverAreaCode: "",
      receiverPhone: addressForm.phone,
      shipmentDate: "",
      shippingService: SelectedService,
      totalWeight: TotalWeightFromShipping,
      totalPieces: TotalPiecesFromShipping,
      productData: JSON.stringify(calculatedData),
    };
    const serverData = appendData(obj);
    try {
      const res = await fetch_calculate_full_estimate(serverData);
      if (!res.data.error) {
        setLoading(false);
        setCartCount(0);
        setActiveStep(activeStep + 1);
        localStorage.setItem("SelectedProvince", "");
        localStorage.setItem("CheckoutaddressForm", JSON.stringify({}));
        localStorage.setItem("ShippingAddressId", "");
        successMsg("Order Placed Successfully !");
        handleClickOpenOrderconfirmation();
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
      } else {
        errorMsg(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />

      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Box
        bgcolor="#fbfbfb"
        position="relative"
        id="orderconfirmation"
        p={{ xs: 1, md: 5 }}
      >
        {/* Cart Display */}
        <Grid container display="flex" justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={12} lg={7} md={7} xl={7}>
            <Box
              sx={{
                my: 2,
                p: { xs: 1, sm: 1, md: 3 },
                boxShadow: { xs: 0, sm: 0, md: 5 },
              }}
            >
              <Typography component="h1" variant="h4" align="center">
                Checkout
              </Typography>
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <Box>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #{orderNumber}. We have emailed your
                    order confirmation, and will send you an update when your
                    order has shipped.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/shop")}
                    sx={{
                      width: { xs: 200, sm: 250, md: 500, lg: 500 },
                      fontWeight: 600,
                      boxShadow: "none",
                      marginTop: 2,
                      bgcolor: "#008844",
                    }}
                  >
                    CONTINUE SHOPPING
                  </Button>
                </Box>
              ) : (
                <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
              )}
            </Box>
          </Grid>

          {activeStep === steps.length ? null : (
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Box
                sx={{
                  my: 2,
                  p: { xs: 2, sm: 2, md: 2 },
                  boxShadow: { xs: 0, sm: 0, md: 5 },
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box textAlign="left" py={2}>
                      <Typography color="#3b3b3d" variant="h4">
                        Order Summary
                      </Typography>
                    </Box>
                    <List disablePadding>
                      {products &&
                        products.map((i) => (
                          <ListItem key={i.productName} sx={{ py: 1, px: 0 }}>
                            <ListItemText
                              primary={i.productName}
                              sx={{
                                display: "flex",
                                width: "50%",
                                flexDirection: "column",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                flexWrap: "wrap",
                              }}
                              secondary={`${i.orderedQty} x ${i.productPrice}`}
                            />
                            <Typography variant="body2">{`$ ${i.totalProductPrice}`}</Typography>
                          </ListItem>
                        ))}

                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={2}
                        py={2}
                        borderTop="1px solid silver"
                      >
                        <Typography>Ordered Price</Typography>
                        <Typography textAlign="end">{`$${subtotal}`}</Typography>
                      </Box>

                      <Box my={3}>
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          mb={2}
                        >
                          <Typography>Tax</Typography>
                          {taxInfo.length !== 0 ? (
                            <Box display="flex" flexDirection="column">
                              <Typography textAlign="end">{`$${taxInfo.total_tax_amount}`}</Typography>
                              <Button onClick={handleAccordion} size="small">
                                <Typography variant="caption">
                                  {expanded ? "Read Less" : "Read More"}
                                </Typography>
                              </Button>
                            </Box>
                          ) : (
                            <Box display="flex" flexDirection="column">
                              <Typography textAlign="end">------</Typography>
                            </Box>
                          )}
                        </Box>
                        {expanded ? (
                          <Box
                            display="flex"
                            flexDirection={{
                              xs: "column",
                              sm: "column",
                              md: "row",
                              lg: "row",
                            }}
                            justifyContent="space-between"
                            mb={3}
                            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                          >
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row",
                                sm: "row",
                                md: "column",
                                lg: "column",
                              }}
                              justifyContent={{
                                xs: "space-between",
                                sm: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {HSTProvince ? "HST(%)" : "GST(%)"}
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                {HSTProvince ? taxInfo.hst : taxInfo.gst}%
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row",
                                sm: "row",
                                md: "column",
                                lg: "column",
                              }}
                              justifyContent={{
                                xs: "space-between",
                                sm: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {HSTProvince ? "HST($)" : "GST($)"}
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                $
                                {HSTProvince
                                  ? taxInfo.hst_amount
                                  : taxInfo.gst_amount}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row",
                                sm: "row",
                                md: "column",
                                lg: "column",
                              }}
                              justifyContent={{
                                xs: "space-between",
                                sm: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {province === "Quebec" ? "QST(%)" : "PST(%)"}
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                {taxInfo.pst}%
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row",
                                sm: "row",
                                md: "column",
                                lg: "column",
                              }}
                              justifyContent={{
                                xs: "space-between",
                                sm: "space-between",
                              }}
                            >
                              <Typography variant="body2">
                                {province === "Quebec" ? "QST($)" : "PST($)"}
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                ${taxInfo.pst_amount}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row",
                                sm: "row",
                                md: "column",
                                lg: "column",
                              }}
                              justifyContent={{
                                xs: "space-between",
                                sm: "space-between",
                              }}
                            >
                              <Typography variant="body2">Total</Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                ${taxInfo.total_tax_amount}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>

                      {activeStep === 1 &&
                      taxInfo.length !== 0 &&
                      subtotal < 250 ? (
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          py={2}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              textAlign: "left",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                              flexWrap: "wrap",
                            }}
                          >
                            Choose a Service
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            size="medium"
                            value={SelectedService}
                            options={options}
                            onChange={(event, value) => {
                              setSelectedService(value.label);
                              setShippingPrice(value.price);
                              setExpectedDelivery(value.date);
                              setshowError(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                variant="standard"
                                error={showError}
                                size="small"
                                label="Shipping Service"
                                sx={{
                                  width: {
                                    lg: "20ch",
                                    md: "20ch",
                                    sm: "15ch",
                                    xs: "15ch",
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}

                      {subtotal < 250 ? (
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          py={2}
                        >
                          <Typography>Shipping Cost</Typography>
                          <Typography textAlign="end">
                            {ShippingPrice === 0 ? "----" : `$${ShippingPrice}`}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          py={2}
                        >
                          <Typography>Shipping Cost</Typography>
                          <Typography textAlign="end">Free</Typography>
                        </Box>
                      )}

                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        py={3}
                        borderTop="1px solid silver"
                      >
                        <Typography>Grand Total</Typography>
                        {taxInfo.length !== 0 ? (
                          <Typography textAlign="end" fontWeight={700}>
                            ${(taxInfo.total_amount + ShippingPrice).toFixed(2)}
                          </Typography>
                        ) : (
                          <Typography textAlign="end" fontWeight={700}>
                            ${subtotal}
                          </Typography>
                        )}
                      </Box>
                    </List>
                  </Grid>
                </Grid>
              </Box>

              {activeStep === 1 && (
                <Box sx={{ boxShadow: { xs: 0, sm: 0, md: 5 } }} p={2} mb={2}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      display="flex"
                      justifyContent="start"
                      flexDirection="column"
                      textAlign="left"
                    >
                      <Typography gutterBottom sx={{ mt: 1 }}>
                        Shipping
                      </Typography>
                      <Typography variant="body2">
                        {[
                          checkoutData.firstName && checkoutData.lastName
                            ? `${checkoutData.firstName} ${checkoutData.lastName},`
                            : "",
                          checkoutData.address,
                          checkoutData.city,
                        ]
                          .filter(Boolean)
                          .join(" ")}
                      </Typography>
                      <Typography variant="body2">
                        {[
                          checkoutData.province,
                          checkoutData.pincode,
                          checkoutData.country,
                        ]
                          .filter(Boolean)
                          .join(",")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {activeStep === 1 && (
                <Box sx={{ boxShadow: { xs: 0, sm: 0, md: 5 } }} p={2} mb={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography gutterBottom>
                        Chosen Payment Method
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography gutterBottom variant="body2">
                        {selectedPaymentAccord}
                      </Typography>
                    </Grid>

                    {selectedPaymentAccord === "Interac e-Transfer" && (
                      <Grid item xs={12}>
                        <Typography gutterBottom>
                          <TextField
                            required
                            id="TransactionNumber"
                            name="Transaction_number"
                            label="Transaction Number"
                            fullWidth
                            autoComplete="cc-number"
                            variant="standard"
                            error={tranNoError}
                            onChange={(e) =>
                              setTransactionNumber(e.target.value)
                            }
                            value={TransactionNumber}
                          />
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                      <LoadingButton
                        onClick={handlePlaceOrder}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        fullWidth
                        className={!loading && "button-63"}
                      >
                        <span>Proceed to Pay</span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <ToastContainer />

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
