import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Divider,
  Badge,
} from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import axios from "axios";
import { getmainproducts } from "../../Services/BaseAPI/API";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { ProductsCard } from "../ProductsCard/ProductsCard";
import FilterProducts from "./FilterProducts";
import TuneIcon from "@mui/icons-material/Tune";
import SmallScreenFilterMenu from "./SmallScreenFilterMenu";
import Spinner from "../../Comps/Spinner/Spinner";
import { appendData } from "../../Variables/ProcessVariable";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetch_get_product_ordered_qty } from "../../Services/Services/OrderService";
import {
  fetch_get_user_cart,
  fetch_get_user_wishlist,
} from "../../Services/Services/ProductService";
import "./ShopPage.styles.css";

export default function ShopPage(props) {
  // const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [ProductDialogopen, setProductDialogopen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [PreviewData, setPreviewData] = useState([]);
  const [allCartItems, setAllCartItems] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const navigate = useNavigate();
  const [productQty, setProductQty] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");
  const auth = localStorage.getItem("Userauth");
  const [OpenFilterSection, setOpenFilterSection] = useState(false);
  const [FilteredProductsList, setFilterProductsList] = useState([]);
  const [badgeCount, setBadgeCount] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [storedWishlist, setStoredWishlist] = useState([]);
  const [HelperText, setHelperText] = useState("");
  const [availableStock, setAvailableStock] = useState(1);
  const userAuth = JSON.parse(localStorage.getItem("Userauth"));

  const fetchData = () => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: getmainproducts,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            reject(res.data.message);
          } else {
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            resolve(res.data);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
          reject(err);
        });
    });
  };

  const { data, isLoading } = useQuery("myData", fetchData, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
    staleTime: 60000,
    notifyOnChangeProps: ["data"], // Only trigger re-renders when the 'data' prop changes
    equalityFn: (oldData, newData) => {
      // Use a custom equality function to determine if the data has changed
      // Return true if data is the same, false if it's different
      return JSON.stringify(oldData) === JSON.stringify(newData);
    },
  });

  const handleColorClick = (color, productImage) => {
    setSelectedColor(color);
    setAvailableStock(productImage[color][0]?.inStock);
    setHelperText("");
  };

  useEffect(() => {
    if (PreviewData.length > 0) {
      const defaultColor = Object.keys(PreviewData[0]?.productImage)[0];
      setSelectedColor(defaultColor);
      setAvailableStock(PreviewData[0]?.productImage[defaultColor][0]?.inStock);
    }
  }, [PreviewData]);

  //Get Product Qty from DB
  const getProductQtyFromDB = async () => {
    const currentProduct = JSON.parse(localStorage.getItem("currentReview"));
    const productId = currentProduct.map((i) => i.productId);
    const obj = {
      userId: userId,
      productId: productId,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_get_product_ordered_qty(lData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setProductQty(res.data.productQty);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  // Dialog box when click QUICK View
  const QuickView = (data) => {
    setPreviewData(data);
    setProductDialogopen(true);
  };

  // Open Reviews
  const handleOpenReview = (i) => {
    navigate("/reviews");
  };

  // Close Pop_up
  const handleClose = () => {
    setProductDialogopen(false);
    navigate("/shop");
    setProductQty(1);
    setHelperText("");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (ProductDialogopen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [ProductDialogopen]);

  // Open Filter Menu
  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  // GET USER CART DATA

  const getUserCartData = async () => {
    if (auth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_get_user_cart(serverData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setAllCartItems(res.data.cart);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };
  useEffect(() => {
    getUserCartData();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  const getUserwishlistedItems = async () => {
    if (userAuth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_get_user_wishlist(serverData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setStoredWishlist(res.data.wishlist);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  useEffect(() => {
    getUserwishlistedItems();
  }, [userId]);

  return (
    <Box position="relative">
      <Header />

      {/* Snack Bar Alert */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {Notification}
        </Alert>
      </Snackbar>

      {/* add Notifications */}

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseAddedCartSnackbar}
      >
        <Alert onClose={handleCloseAddedCartSnackbar}>{Notification}</Alert>
      </Snackbar>

      <Box px={1}>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          <Container>
            <Box mt={1}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} xxl={12}>
                  <Box
                    width="100%"
                    bgcolor="#faa634"
                    textAlign="center"
                    py={{ xs: 1, md: 2, lg: 3 }}
                    className="productHead"
                  >
                    <Typography variant="h5" color="#FFFFFF" fontWeight={600}>
                      PRODUCTS
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              lg={2}
              xl={2}
              md={2}
              xxl={2}
              className="filterbox"
              mt={1}
            >
              <Box
                component={Card}
                sx={{
                  boxShadow: "3px 3px 6px -3px rgba(0,0,0,0.7)",
                  borderRadius: 0,
                }}
              >
                <FilterProducts
                  setOpenFilterSection={setOpenFilterSection}
                  setFilterProductsList={setFilterProductsList}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={9} lg={10} xl={10} xxl={10} md={10} mb={2}>
              <Container className="smallscreenfilter">
                <Box mt={2} sx={{ cursor: "pointer" }}>
                  <Badge badgeContent={badgeCount} color="warning">
                    <TuneIcon onClick={handleClickMenu} />
                  </Badge>
                  {openMenu ? (
                    <SmallScreenFilterMenu
                      setBadgeCount={setBadgeCount}
                      badgeCount={badgeCount}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      setOpenFilterSection={setOpenFilterSection}
                      setFilterProductsList={setFilterProductsList}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Container>

              {/* All Products */}
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  py={20}
                >
                  <Spinner />
                </Box>
              ) : (
                <Container id="shop-container">
                  {OpenFilterSection ? (
                    <Box>
                      <Box textAlign="left" ml={2} mt={2}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize={26}
                          id="filterResult"
                        >
                          Results
                        </Typography>
                        <Typography variant="caption">
                          Products (
                          {FilteredProductsList && FilteredProductsList.length})
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container columnSpacing={1} mt={1} rowSpacing={3}>
                          {FilteredProductsList &&
                            FilteredProductsList.map((product) => (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                xl={4}
                                ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                                position="relative"
                              >
                                <ProductsCard
                                  getUserwishlistedItems={
                                    getUserwishlistedItems
                                  }
                                  setStoredWishlist={setStoredWishlist}
                                  storedWishlist={storedWishlist}
                                  i={product}
                                  QuickView={QuickView}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {Object.entries(data.data).map(([category, products]) => {
                        return (
                          <>
                            <Box textAlign="left" ml={2} mt={2}>
                              <Typography
                                id={category.replace(/\s+/g, "").toLowerCase()}
                                variant="h6"
                                fontWeight={300}
                                fontSize={26}
                              >
                                {category}
                              </Typography>
                            </Box>
                            <Box>
                              <Grid
                                container
                                columnSpacing={1}
                                mt={1}
                                rowSpacing={3}
                              >
                                {products.map((product) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    xl={4}
                                    ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                                    position="relative"
                                  >
                                    <ProductsCard
                                      getUserwishlistedItems={
                                        getUserwishlistedItems
                                      }
                                      setStoredWishlist={setStoredWishlist}
                                      storedWishlist={storedWishlist}
                                      i={product}
                                      products={products}
                                      QuickView={QuickView}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                            <Divider
                              sx={{ marginTop: 2, borderColor: "silver" }}
                            />
                          </>
                        );
                      })}
                    </>
                  )}
                </Container>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
