import { Box, Typography, Container, Grid, Stack } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import FAQ from "./FAQ";

export default function ShopzCare() {
  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Shopz Care
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Shopz Care
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            Welcome to Shopz Care – Your Source for Support.
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            At Shopztrend Enterprises Inc., we value our customers and are
            committed to providing you with the best shopping experience
            possible. Our dedicated customer care team, known as "Shopz Care,"
            is here to assist you every step of the way. Whether you have
            questions, concerns, or need assistance with your orders, we've got
            you covered.
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box mt={2} textAlign="left">
          <Box textAlign="left">
            <Typography color="#262626" fontSize={30} fontWeight={500}>
              Shopz Care Mission
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              At Shopz Care, our mission is simple: to ensure your satisfaction.
              We are here to:
            </Typography>
            <ul>
              <Stack spacing={2} mt={3}>
                <li>
                  <strong>Assist You: </strong>Our team is available to answer
                  your questions, provide product information, and guide you
                  through the shopping process.
                </li>
                <li>
                  <strong>Resolve Issues: </strong>If you encounter any issues
                  with your orders or the website, Shopz Care is here to find
                  prompt solutions.
                </li>
                <li>
                  <strong>Listen to Your Feedback: </strong>We value your
                  feedback and use it to improve our services continually. Your
                  voice matters.
                </li>
              </Stack>
            </ul>
          </Box>

          <Box textAlign="left" mt={4}>
            <Typography color="#262626" fontSize={30} fontWeight={500}>
              How to Reach Shopz Care
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              Getting in touch with Shopz Care is easy. You can contact us
              through any of the following methods:
            </Typography>
            <ul>
              <Stack spacing={2} mt={3}>
                <li>
                  <strong>Email: </strong>Send us an email at{" "}
                  <a
                    href="mailto:clientcare@shopztrend.com"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    {" "}
                    clientcare@shopztrend.com
                  </a>{" "}
                  with your questions or concerns. We aim to respond within 24
                  hours.
                </li>
                <li>
                  <strong>Phone: </strong>Call our customer care hotline at
                  +1(306)590-0000 during our business hours.
                </li>
              </Stack>
            </ul>
          </Box>

          <Box textAlign="left" mt={4}>
            <Typography color="#262626" fontSize={30} fontWeight={500}>
              Business Hours
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              Shopz Care is available during the following hours:
            </Typography>
            <ul>
              <Stack spacing={2} mt={3}>
                <li>
                  <strong>Monday to Friday: </strong>9:30 AM to 5:30 PM
                </li>
                <li>
                  <strong>Saturday & Sunday: </strong>Closed
                </li>
              </Stack>
            </ul>
          </Box>

          <Box textAlign="left" mt={4}>
            <Typography color="#262626" fontSize={30} fontWeight={500}>
              Frequently Asked Questions
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              Before reaching out to Shopz Care, you might find the answers to
              your questions in our FAQ section. We've compiled a list of common
              inquiries to assist you quickly.
            </Typography>

            <Stack mt={3}>
              <FAQ />
            </Stack>
          </Box>

          <Box textAlign="left" my={4}>
            <Typography color="#262626" fontSize={30} fontWeight={500}>
              Your Feedback Matters
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              At Shopz Care, we believe that your feedback helps us grow and
              improve. If you have any suggestions, comments, or compliments,
              please let us know. We're here to make your shopping experience
              exceptional.
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              Thank you for choosing Shopztrend Enterprises Inc. We look forward
              to serving you with excellence at Shopz Care.
            </Typography>
          </Box>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
