import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const apiGETRequest = async (url) => {
  try {
    const response = await axios({
      method: "GET",
      url: url,
      // headers: {
      //   'x-api-key': 'fc_acaae217a85dd7c4d9c43ae66',
      // },
    });
    return response;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: "Bounce",
    });
  }
};

export const apiPOSTRequest = async (url, data) => {
  try {
    const response = await axios({
      method: "POST",
      url: url,
      data: data,
      // headers: {
      //   'x-api-key': 'fc_acaae217a85dd7c4d9c43ae66',
      // },
    });
    return response;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: "Bounce",
    });
  }
};
