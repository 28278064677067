import {
  Box,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RefundVoucherForm from "./RefundVoucherForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReturnsAndExchage() {
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [panel6, setPanel6] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenForm = () => {
    setOpenDialog(true);
  };
  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  return (
    <Box>
      <Header />
      {/* <Divider sx={{borderColor:'silver'}}/> */}

      <RefundVoucherForm
        successMsg={successMsg}
        ErrorMsg={ErrorMsg}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Returns And Exchange Policy
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Refund and Exchange Information
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            At Shopztrend, we strive to provide 100% customer satisfaction.
            Retaining your receipt will allow for a fast and easy return and
            will ensure that you receive the proper credit for your purchase.
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box py={3}>
          {/* Returns */}
          <Accordion
            expanded={panel1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                onClick={() => setPanel1(!panel1)}
                fontWeight={600}
                variant="subtitle1"
              >
                {" "}
                {panel1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Returns
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  With Return Request Form{" "}
                </Typography>

                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>Within 30 days from original date of purchase</li>

                    <li>
                      Refunds will be provided in the same tender that the
                      purchase was paid in:
                      <ul>
                        <li>
                          Debit card will be refunded to the original debit
                          card.
                        </li>
                        <li>
                          Credit card will be refunded to the original credit
                          card.
                        </li>
                        <li>Gift Cards will be returned on a gift card.</li>
                        <li>
                          Cheques if cleared after 10 days will be refunded in
                          Bank Account.
                        </li>
                      </ul>
                    </li>

                    <li>
                      Customer will be required to fill out a Return Request
                      Form to verify the return was completed.
                    </li>
                    <li>
                      The personal information provided on a Return Request Form
                      is required in order to process the refund and is used for
                      the following purposes:
                      <ul>
                        <li>For auditing purposes</li>
                        <li>To ensure customer satisfaction</li>
                      </ul>
                    </li>
                    <li>
                      A Shopztrend representative may contact you to confirm
                      your customer service expectations were met.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Without Return Request Form
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Returns not accompanied by a Return Request Form Form will
                      be processed at our discretion{" "}
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Return Request Form
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1} direction="row">
                    <Typography>To Apply for Return or Refund, </Typography>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={handleOpenForm}
                      color="primary"
                    >
                      Click Here
                    </Typography>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Exchanges */}
          <Accordion
            expanded={panel2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel2(!panel2)}
                variant="subtitle1"
              >
                {panel2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Exchanges
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      It must be within 30 days from original date of purchase
                      with a proof of purchase receipt.
                    </li>
                    <li>
                      Exchanges will be offered for the same product or a
                      product of equal value.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Return Exclusions */}
          <Accordion
            expanded={panel3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel3(!panel3)}
                variant="subtitle1"
              >
                {panel3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Return Exclusions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>Pillows</li>
                    <li>Cushions</li>
                    <li>Loveseat Cover</li>
                    <li>NOTE: Other exclusions may apply.</li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Return Exclusions with limited exceptions */}
          <Accordion
            expanded={panel4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel4(!panel4)}
                variant="subtitle1"
              >
                {panel4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Return Exclusions with limited exceptions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  Electronics, Toys, Earphones, Headphones &amp; Clothes
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Returns will ONLY be given if original product is returned
                      in the original sealed box in saleable condition with a
                      receipt and within the 30 day from original date of
                      purchase and can be exchanged with a product priced equal
                      or above the purchased receipt value.
                    </li>
                    <li>
                      Exchanges will ONLY be offered if the original is
                      defective, after opening, with receipt and within the 30
                      day return period from original date of purchase.
                    </li>
                    <li>
                      Replacement of the product will be applicable in the
                      following scenarios. If the product machinery is found to
                      be faulty, internal mechanism failure and operational
                      issues. The product timeline falls within the guarantee
                      period from the date of original purchase made.
                    </li>
                    <li>
                      Fashion apparel, linens, bedding, maternity products, and
                      baby clothes, shoes, and accessories must be unused,
                      unworn (if applicable), unlaundered, and still have the
                      original tags attached.
                    </li>
                    <li>
                      All refund will be offered only on unopened original
                      package, with a receipt and within the 30 day return
                      period from original date of purchase
                    </li>
                    <li>
                      All exchanges and returns require video or images of the
                      products to proceed further and be eligible.
                    </li>
                    <li>
                      All exchanges and returns expectation, Customers will be
                      required to pre-pay the return shipping.
                    </li>
                    <li>
                      The return or Exchange items must not have signs of use,
                      cosmetic damage, and wear and tear. If found the
                      Shopztrend Inc. has the discretion not to process the
                      claim.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Important Notes */}
          {/* <Accordion expanded={panel5} sx={{display:'flex',flexDirection:'column',  justifyContent:'start', boxShadow:'none',border: 'none'}} disableGutters >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
        > 
        <Typography color='#703737' fontWeight={600} variant='subtitle1'>{panel5 ? <RemoveCircleOutlineIcon onClick={()=>setPanel5(!panel5)} /> : <AddCircleOutlineIcon onClick={()=>setPanel5(!panel5)} /> } Important Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box textAlign='left'>
        <ul>
        <Stack spacing={1} mt={1}>
        <li>Shopztrend reserves the right to limit or refuse to accept the return of certain merchandise at any time and for any reason.</li>
        <li>Shopztrend reserves the right to request valid identification prior to accepting merchandise for a return.</li>
        <li>Shopztrend private label products such as Life Brand® and Quo® may have a full money-back guarantee. If you are not completely satisfied with the quality of any of these products purchased at a Shopztrend store, simply return it with receipt or original package, within 30 days of original date of purchase for a full refund.</li>
        <li>If you are not completely satisfied with the quality of any President’s Choice® or PC® product purchased at a Shopztrend store, simply return it to the store where it was purchased within 30 days from original date of purchase along with receipt and original package for an exchange or refund.</li>
        <li>Any Gift with Purchase or Promotional give away that was issued in original purchase, should accompany the return.</li>
        <li>Some restrictions apply. <Link>See stores for details.</Link></li>
        </Stack>
        </ul>
        </Box>
        </AccordionDetails>
        </Accordion> */}

          {/* Questions */}
          <Accordion
            expanded={panel6}
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                onClick={() => setPanel6(!panel6)}
                fontWeight={600}
                variant="subtitle1"
              >
                {panel6 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Further Questions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enterprises Inc. reserves the right to refuse,
                      limit or accept the return of certain products at any time
                      and for any reason.
                    </li>
                    <li>
                      Shopztrend Enterprises Inc. reserves the right to request
                      valid identification prior to accepting Customers for a
                      return.
                    </li>
                    <li>
                      If, at any time, you have questions about returning or
                      exchanging an item purchased at Shopztrend Enterprises
                      Inc. please reach out to our support team
                      <a
                        href="mailto:clientcare@shopztrend.com"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        {" "}
                        clientcare@shopztrend.com
                      </a>{" "}
                      .
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>

      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
