import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { Box, Typography, Container, Grid, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import TrendingCards from "../../Comps/Cards/TrendingCards";
import {
  fetch_get_wholesale_trendz,
  fetch_get_wholesale_trendz_banner,
} from "../../Services/Services/CommonService";

export default function SalePage() {
  const navigate = useNavigate();
  const [SalesPagesection1, setSalesPagesection1] = useState([]);
  const [SalesPagesection2, setSalesPagesection2] = useState([]);
  const [SalesPagesection3, setSalesPagesection3] = useState([]);
  const [SalesPagesection4, setSalesPagesection4] = useState([]);
  const [SalesPagesection5, setSalesPagesection5] = useState([]);
  const [SalesPagesection6, setSalesPagesection6] = useState([]);
  const [SalesPagesection7, setSalesPagesection7] = useState([]);
  const [SalesPagesection8, setSalesPagesection8] = useState([]);
  const [SalesPagesection9, setSalesPagesection9] = useState([]);
  const [SalesPagesection10, setSalesPagesection10] = useState([]);
  const [bannerHeading, setBannerHeading] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");

  useEffect(() => {
    const getTrends = async () => {
      const sendData = new FormData();
      sendData.append("homePageImageId", "");
      try {
        const res = await fetch_get_wholesale_trendz(sendData);
        if (!res.data.error) {
          setSalesPagesection1(res.data.data.Trending_layout_1.cards[0]);
          setSalesPagesection2(res.data.data.Trending_layout_2);
          setSalesPagesection3(res.data.data.Trending_layout_3);
          setSalesPagesection4(res.data.data.Trending_layout_4);
          setSalesPagesection5(res.data.data.Trending_layout_5);
          setSalesPagesection6(res.data.data.Trending_layout_6);
          setSalesPagesection7(res.data.data.Trending_layout_7);
          setSalesPagesection8(res.data.data.Trending_layout_8);
          setSalesPagesection9(res.data.data.Trending_layout_9);
          setSalesPagesection10(res.data.data.Trending_layout_10.cards[0]);
        } else {
          setSalesPagesection1([]);
          setSalesPagesection2([]);
          setSalesPagesection3([]);
          setSalesPagesection4([]);
          setSalesPagesection5([]);
          setSalesPagesection6([]);
          setSalesPagesection7([]);
          setSalesPagesection8([]);
          setSalesPagesection9([]);
          setSalesPagesection10([]);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getTrends();
  }, []);

  const handleClickOpenProductCategory = (category) => {
    navigate(`/contactus#CHeader`);
  };

  // Sale Page , Now Changed as Trending Page . So Names Will be Sale

  useEffect(() => {
    const getTrendsBanner = async () => {
      const sendData = new FormData();
      sendData.append("bannerId", 2);
      try {
        const res = await fetch_get_wholesale_trendz_banner(sendData);
        if (!res.data.error) {
          setBannerHeading(res.data.data.bannerHeading);
          setBannerCaption(res.data.data.bannerCaption);
        } else {
          setBannerHeading(`Trending Right Now`);
          setBannerCaption(
            `Explore savings with our trending uniform products - where deals and promotions meet smart shopping!`
          );
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getTrendsBanner();
  }, []);

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, []);

  return (
    <Box>
      <Header />

      <Container>
        <Box mb={2}>
          <Grid container mb={2} mt={1}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  {bannerHeading}
                </Typography>
                <Typography
                  variant="h6"
                  color="#FFFFFF"
                  fontWeight={500}
                  sx={{ marginTop: 2 }}
                >
                  {bannerCaption}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sm={6}
              lg={6}
              xl={6}
              md={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={SalesPagesection1?.images}
                alt="img"
                style={{ objectFit: "cover", width: "100%", height: "450px" }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              lg={6}
              xl={6}
              md={6}
              display="flex"
              alignItems="center"
            >
              <Box textAlign="left" sx={{ mt: { lg: 0, md: 1, sm: 1, xs: 1 } }}>
                <Typography variant="h6" fontWeight={600}>
                  {SalesPagesection1?.title}
                </Typography>
                <Link
                  onClick={() =>
                    handleClickOpenProductCategory(SalesPagesection1?.category)
                  }
                >
                  <Typography sx={{ marginTop: 1, cursor: "pointer" }}>
                    Inquire Now
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {SalesPagesection2?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection2}
          layout="2"
          title={SalesPagesection2?.heading}
        />
      ) : null}

      {SalesPagesection3?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection3}
          layout="3"
          title={SalesPagesection3?.heading}
        />
      ) : null}

      {SalesPagesection4?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection4}
          layout="4"
          title={SalesPagesection4?.heading}
        />
      ) : null}

      {SalesPagesection5?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection5}
          layout="5"
          title={SalesPagesection5?.heading}
        />
      ) : null}

      {SalesPagesection6?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection6}
          layout="6"
          title={SalesPagesection6?.heading}
        />
      ) : null}

      {SalesPagesection7?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection7}
          layout="7"
          title={SalesPagesection7?.heading}
        />
      ) : null}

      {SalesPagesection8?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection8}
          layout="8"
          title={SalesPagesection8?.heading}
        />
      ) : null}

      {SalesPagesection8?.isHeadingEnable === 1 ? (
        <TrendingCards
          section={SalesPagesection9}
          layout="9"
          title={SalesPagesection9?.heading}
        />
      ) : null}

      <Container>
        <Box mt={4} mb={4}>
          <Grid container>
            <Grid
              item
              xs={6}
              sm={6}
              lg={4}
              xl={4}
              md={4}
              display="flex"
              alignItems="center"
            >
              <Box textAlign="left">
                <Typography variant="h6" fontWeight={600}>
                  {SalesPagesection10?.title}
                </Typography>
                <Typography sx={{ marginTop: 1, color: "#49484a" }}>
                  {SalesPagesection10?.content}
                </Typography>
                <Link
                  onClick={() =>
                    handleClickOpenProductCategory(SalesPagesection10?.category)
                  }
                >
                  <Typography sx={{ marginTop: 1, cursor: "pointer" }}>
                    Inquire Now
                  </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              lg={8}
              xl={8}
              md={8}
              sx={{ mt: { lg: 0, md: 1, sm: 1, xs: 1 } }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={SalesPagesection10?.images}
                alt="img"
                style={{ objectFit: "cover", width: "100%", height: "450px" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
