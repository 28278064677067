import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "../../Comps/Header/Header";
import { appendData } from "../../Variables/ProcessVariable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetch_forgot_password } from "../../Services/Services/UserService";

export default function ForgotPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();

  // Validate Password
  const validateInput = () => {
    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        setError(false);
      } else {
        setErrorMsg("Passwords do not match");
        setError(true);
      }
    } else {
      setErrorMsg("");
      setError(false);
    }
  };

  //  Vaidate EMail
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    const regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    setIsValid(regex.test(emailValue));
    setEmail(emailValue);
  };

  const submit = async () => {
    if (isValid) {
      if (password === confirmPassword) {
        const obj = {
          newPassword: password,
          confirmNewPassword: confirmPassword,
          email: email,
        };
        const sendData = appendData(obj);
        try {
          const res = await fetch_forgot_password(sendData);
          if (!res.data.error) {
            successMsg(res.data.message);
            setError(false);
            navigate("/account");
          } else {
            ErrorMsg(res.data.message);
            setError(true);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    } else {
      ErrorMsg("Invalid Email Id");
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "silver" }} />
      <Box>
        <Container>
          <Box py={5}>
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={12}
                lg={10}
                xl={10}
                border={1}
                borderColor="silver"
              >
                <Box
                  width="100%"
                  bgcolor="#faa634"
                  textAlign="left"
                  py={3}
                  color="#ffff"
                >
                  <Typography fontSize={20} fontWeight={500} marginLeft={2}>
                    <PersonOutlineIcon
                      padding={1}
                      fontSize="large"
                      sx={{
                        border: 1,
                        borderRadius: "50%",
                        verticalAlign: "middle",
                        marginRight: 2,
                      }}
                    />
                    Forgot Your Password ?{" "}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box textAlign="left" py={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack>
                          <Typography fontWeight={500} color="#707070">
                            Your Email ID
                          </Typography>
                          <TextField
                            sx={{
                              width: { xs: 250, sm: 300, md: 500, lg: 500 },
                              marginBottom: 5,
                            }}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            fullWidth
                            onChange={handleEmailChange}
                            size="small"
                            defaultValue=""
                            required
                          />
                          <Typography fontWeight={500} color="#707070">
                            Enter New Password
                          </Typography>
                          <TextField
                            sx={{
                              width: { xs: 250, sm: 300, md: 500, lg: 500 },
                              marginBottom: 5,
                            }}
                            size="small"
                            id="inputpassword"
                            defaultValue=""
                            variant="outlined"
                            name="password"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            onBlur={validateInput}
                          />
                          <Typography fontWeight={500} color="#707070">
                            Confirm New Password
                          </Typography>
                          <TextField
                            sx={{
                              width: { xs: 250, sm: 300, md: 500, lg: 500 },
                              marginBottom: 5,
                            }}
                            size="small"
                            id="inputconfirmpassword"
                            defaultValue=""
                            variant="outlined"
                            name="confirmPassword"
                            type="password"
                            autoComplete="off"
                            helperText={errorMsg}
                            onBlur={validateInput}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            error={error}
                          />
                          <Button
                            variant="contained"
                            onClick={submit}
                            sx={{
                              height: 40,
                              boxShadow: "none",
                              bgcolor: "#99B37E",
                            }}
                          >
                            Create New Password
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  py={3}
                  bgcolor="#f5f5f5"
                  mt={3}
                  borderTop={1}
                  borderColor="#ccc"
                >
                  <Typography>
                    Already have an account with us?
                    <Link to="/account">Signin</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
