import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import SecondaryFooter from "../../Comps/Footer/SecondaryFooter";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../../Comps/Header/Header";
import "./WishlistPage.styles.css";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import { appendData } from "../../Variables/ProcessVariable";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HeartBrokenOutlinedIcon from "@mui/icons-material/HeartBrokenOutlined";
import { ProductsContext } from "../../Context/CountContext";
import { ToastContainer, toast } from "react-toastify";
import { fetch_getuser } from "../../Services/Services/CommonService";
import {
  fetch_add_to_cart,
  fetch_add_to_wishlist,
  fetch_get_user_wishlist,
} from "../../Services/Services/ProductService";

export default function WishlistPage() {
  const auth = JSON.parse(localStorage.getItem("Userauth"));
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [wishlistItems, setWishlistItems] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState(null);
  const { setCartCount, CartCount, wishlistCount, setWishlistCount } =
    useContext(ProductsContext);
  const [HelperText, setHelperText] = useState("");
  const [availableStock, setAvailableStock] = useState(1);

  const getUserDatatodisplay = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_getuser(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setUserData(res.data.data);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserDatatodisplay();
  }, [userId]);

  const getUserwishlistedItems = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_user_wishlist(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setWishlistItems(res.data.wishlist);
        setStatus(true);
        setColor(true);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getUserwishlistedItems();
  }, []);

  const removeFromWishlist = async (id) => {
    if (wishlistItems.length > 1) {
      const obj = {
        userId: userId,
        productId: id,
        wishlist: 0,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_wishlist(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          getUserwishlistedItems();
          setWishlistCount(wishlistCount - 1);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else if (wishlistItems.length === 1) {
      const obj = {
        userId: userId,
        productId: id,
        wishlist: 0,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_wishlist(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setWishlistItems([]);
          getUserwishlistedItems();
          setWishlistCount(0);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  const handleClickOpenAddressEdit = () => {
    navigate("/account#accountAddressSection");
    setTimeout(() => {
      const element = document.getElementById("accountAddressSection");
      localStorage.setItem("accountBoxTitle", "Addresses");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleClickOpenNameEdit = () => {
    navigate("/account#accountNameSection");
    setTimeout(() => {
      const element = document.getElementById("accountNameSection");
      localStorage.setItem("accountBoxTitle", "Account Details");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const addProductToCart = async (i) => {
    if (i.productQty !== null && i.productQty !== "" && i.productQty !== 0) {
      const colors = Object.keys(i.productImage);
      const defaultColor = colors[0];
      const obj = {
        userId: userId,
        productId: i.productId,
        qty: i.productQty,
        productColor: selectedColor !== null ? selectedColor : defaultColor,
        cart: 1,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_cart(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setWishlistItems((prevState) =>
            prevState.map((prevItem) => {
              if (prevItem.productId === i.productId) {
                return { ...prevItem, productQty: 1 };
              } else {
                return prevItem;
              }
            })
          );
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
        setCartCount(CartCount + i.productQty);
        removeFromWishlist(i.productId);
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      errorMsg("Add Minimum One Item to Proceed");
    }
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const handleColorClick = (color, productImage) => {
    setSelectedColor(color);
    setAvailableStock(productImage[color][0].inStock);
    setHelperText("");
  };

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />

      {!auth ? (
        <Box paddingBottom={15}>
          <Container>
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Box textAlign="left" mt={3} mb={5}>
                  <Typography color="#262626" fontSize={30} fontWeight={500}>
                    My Wishlist
                  </Typography>
                  <Typography sx={{ marginTop: 1 }}>
                    <LockOpenIcon sx={{ verticalAlign: "middle" }} /> Shopztrend
                    is secure and your personal details are protected
                  </Typography>
                </Box>
                <Box border={1} borderColor="silver" textAlign="center">
                  <Box py={5}>
                    <Typography color="#262626" fontSize={30} fontWeight={500}>
                      <AddShoppingCartIcon
                        fontSize="large"
                        sx={{ verticalAlign: "middle", marginRight: 2 }}
                      />
                      Wishlist - Empty
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={200}
                      lineHeight={2}
                      sx={{ marginTop: 3 }}
                    >
                      <Link to="/account">Sign in</Link> to retrieve your items
                      from your cart and wishlist.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => navigate("/shop")}
                      sx={{
                        width: { xs: 200, sm: 250, md: 500, lg: 500 },
                        fontWeight: 600,
                        boxShadow: "none",
                        marginTop: 2,
                        bgcolor: "#faa634",
                      }}
                    >
                      START SHOPPING
                    </Button>
                    <Box mt={3}>
                      <Link to="/account">
                        <Typography fontWeight={600}>
                          Don't have an account? Register now
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <Box p={3} bgcolor="#fbfbfb" position="relative" height="100%">
          {/* Cart Display */}
          <Grid container>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <Container>
                <Box>
                  <Container>
                    <Box mb={3}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                            lg: "row",
                          },
                        }}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Box>
                            <Box textAlign="left">
                              <Typography
                                color="#3b3b3d"
                                fontSize={33}
                                fontWeight={500}
                              >
                                My Wishlist
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Box
                            width={{ xs: 300, sm: 450, md: 450, lg: 450 }}
                            py={2}
                            px={2}
                            component={Card}
                            display="flex"
                            justifyContent="space-between"
                            gap={2}
                            alignItems="start"
                            margin="auto"
                          >
                            <Stack spacing={2} width="50%">
                              <Typography
                                variant="caption"
                                color="#9a9a9a"
                                textAlign="left"
                              >
                                Shipping to :
                              </Typography>
                              <Typography
                                variant="caption"
                                color="#262626"
                                fontWeight={400}
                                textAlign="left"
                                sx={{ wordBreak: "break-word" }}
                              >
                                {userData.address}, {userData.city},{" "}
                                {userData.state}, {userData.province},
                                {userData.pincode}, {userData.country}
                                <EditIcon
                                  onClick={handleClickOpenAddressEdit}
                                  fontSize="normal"
                                  sx={{
                                    verticalAlign: "middle",
                                    marginLeft: 1,
                                  }}
                                />
                              </Typography>
                            </Stack>
                            <Stack spacing={2} justifyContent="end">
                              <Typography
                                variant="caption"
                                color="#9a9a9a"
                                textAlign="left"
                              >
                                Customer Name
                              </Typography>
                              <Typography
                                variant="caption"
                                color="#262626"
                                fontWeight={400}
                                textAlign="left"
                              >
                                {" "}
                                {userData.first_name} {userData.last_name}
                                <EditIcon
                                  onClick={handleClickOpenNameEdit}
                                  fontSize="normal"
                                  sx={{
                                    verticalAlign: "middle",
                                    marginLeft: 1,
                                  }}
                                />
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>

                  <Container>
                    <Box textAlign="left" py={2}>
                      <Typography
                        color="#3b3b3d"
                        fontSize={22}
                        fontWeight={400}
                      >
                        Ready For Purchase
                      </Typography>
                    </Box>
                  </Container>

                  {wishlistItems.length !== 0 ? (
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      justifyContent="center"
                    >
                      {wishlistItems.map((i, index) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                              component={Card}
                              sx={{
                                boxShadow: "3px 3px 6px -3px rgba(0,0,0,0.7)",
                                borderRadius: 0,
                                height: "100%",
                              }}
                              mb={3}
                            >
                              <Box
                                p={2}
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                              >
                                <Box>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="cardgrid"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={5}
                                      md={5}
                                      lg={5}
                                      xl={5}
                                    >
                                      <Box className="productImgandName">
                                        <img
                                          style={{
                                            mixBlendMode: "multiply",
                                            objectFit: "contain",
                                            width: 100,
                                            height: 100,
                                          }}
                                          src={`${BaseImageAPI}${i.mainImage}`}
                                        />
                                        <Box>
                                          <Typography
                                            variant="body2"
                                            fontSize="13px"
                                            textAlign="left"
                                            ml={2}
                                            mb={1}
                                          >
                                            {i.productName}
                                          </Typography>
                                          <Select
                                            sx={{
                                              height: "25px",
                                              fontSize: "13.5px",
                                            }}
                                            fullWidth
                                            size="small"
                                            defaultValue={
                                              Object.keys(i.productImage)[0]
                                            }
                                            onChange={(e) =>
                                              handleColorClick(
                                                e.target.value,
                                                i.productImage
                                              )
                                            }
                                          >
                                            {Object.keys(i.productImage).map(
                                              (color) => (
                                                <MenuItem value={color}>
                                                  {color}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                          {i?.showHelperText && (
                                            <Typography
                                              variant="caption"
                                              mt={1}
                                              style={{ color: "red" }}
                                            >
                                              {i?.HelperText}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Box>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={5}
                                      md={5}
                                      lg={5}
                                      xl={5}
                                    >
                                      <Box px={2} className="qtybox">
                                        <Stack spacing={4}>
                                          <Box>
                                            <TextField
                                              size="small"
                                              sx={{ width: 120, mr: 1 }}
                                              key={i.productId}
                                              value={i.productQty}
                                              onChange={(event) => {
                                                const newValue = parseInt(
                                                  event.target.value
                                                );
                                                if (isNaN(newValue)) {
                                                  setWishlistItems(
                                                    (prevState) =>
                                                      prevState.map(
                                                        (prevItem) => {
                                                          if (
                                                            prevItem.productId ===
                                                            i.productId
                                                          ) {
                                                            return {
                                                              ...prevItem,
                                                              productQty: 0,
                                                              productPrices: 0,
                                                              showHelperText: true,
                                                              HelperText:
                                                                "Enter a valid number",
                                                            };
                                                          } else {
                                                            return prevItem;
                                                          }
                                                        }
                                                      )
                                                  );
                                                } else if (
                                                  !isNaN(newValue) &&
                                                  newValue >= 1
                                                ) {
                                                  const maxQty =
                                                    selectedColor !== null
                                                      ? availableStock
                                                      : i.productImage[
                                                          Object.keys(
                                                            i.productImage
                                                          )[0]
                                                        ][0].inStock;
                                                  if (newValue > maxQty) {
                                                    const productPrice =
                                                      parseFloat(
                                                        i.productPrice.replace(
                                                          /[^0-9.-]+/g,
                                                          ""
                                                        )
                                                      );
                                                    const productPrices = (
                                                      maxQty * productPrice
                                                    ).toFixed(2);
                                                    setWishlistItems(
                                                      (prevState) =>
                                                        prevState.map(
                                                          (prevItem) => {
                                                            if (
                                                              prevItem.productId ===
                                                              i.productId
                                                            ) {
                                                              return {
                                                                ...prevItem,
                                                                productQty:
                                                                  maxQty,
                                                                productPrices:
                                                                  productPrices,
                                                                showHelperText: true,
                                                                HelperText:
                                                                  "In Stock : " +
                                                                  maxQty,
                                                              };
                                                            } else {
                                                              return prevItem;
                                                            }
                                                          }
                                                        )
                                                    );
                                                  } else {
                                                    setHelperText("");
                                                    const productPrice =
                                                      parseFloat(
                                                        i.productPrice.replace(
                                                          /[^0-9.-]+/g,
                                                          ""
                                                        )
                                                      );
                                                    const productPrices = (
                                                      newValue * productPrice
                                                    ).toFixed(2);
                                                    setWishlistItems(
                                                      (prevState) =>
                                                        prevState.map(
                                                          (prevItem) => {
                                                            if (
                                                              prevItem.productId ===
                                                              i.productId
                                                            ) {
                                                              return {
                                                                ...prevItem,
                                                                productQty:
                                                                  newValue,
                                                                productPrices:
                                                                  productPrices,
                                                                showHelperText: false,
                                                              };
                                                            } else {
                                                              return prevItem;
                                                            }
                                                          }
                                                        )
                                                    );
                                                  }
                                                }
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                  <>
                                                    <IconButton
                                                      onClick={() => {
                                                        const maxQty =
                                                          selectedColor !== null
                                                            ? availableStock
                                                            : i.productImage[
                                                                Object.keys(
                                                                  i.productImage
                                                                )[0]
                                                              ][0].inStock;
                                                        if (
                                                          i.productQty < maxQty
                                                        ) {
                                                          setHelperText("");
                                                          setWishlistItems(
                                                            (prevState) =>
                                                              prevState.map(
                                                                (prevItem) => {
                                                                  if (
                                                                    prevItem.productId ===
                                                                    i.productId
                                                                  ) {
                                                                    const productPrice =
                                                                      parseFloat(
                                                                        i.productPrice.replace(
                                                                          /[^0-9.-]+/g,
                                                                          ""
                                                                        )
                                                                      );
                                                                    const productPrices =
                                                                      (
                                                                        (prevItem.productQty +
                                                                          1) *
                                                                        productPrice
                                                                      ).toFixed(
                                                                        2
                                                                      );
                                                                    return {
                                                                      ...prevItem,
                                                                      productQty:
                                                                        prevItem.productQty +
                                                                        1,
                                                                      productPrices:
                                                                        productPrices,
                                                                      showHelperText: false,
                                                                    };
                                                                  } else {
                                                                    return prevItem;
                                                                  }
                                                                }
                                                              )
                                                          );
                                                        } else {
                                                          setHelperText(
                                                            "In Stock: "
                                                          );
                                                          setWishlistItems(
                                                            (prevState) =>
                                                              prevState.map(
                                                                (prevItem) => {
                                                                  if (
                                                                    prevItem.productId ===
                                                                    i.productId
                                                                  ) {
                                                                    return {
                                                                      ...prevItem,
                                                                      showHelperText: true,
                                                                      HelperText:
                                                                        "In Stock : " +
                                                                        maxQty,
                                                                    };
                                                                  } else {
                                                                    return prevItem;
                                                                  }
                                                                }
                                                              )
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <AddIcon
                                                        sx={{
                                                          verticalAlign:
                                                            "middle",
                                                          fontSize: 16,
                                                        }}
                                                      />
                                                    </IconButton>
                                                    <IconButton
                                                      disabled={
                                                        (i.productQty === 1 ||
                                                          i.productQty === 0) &&
                                                        "true"
                                                      }
                                                      onClick={() => {
                                                        setHelperText("");
                                                        setWishlistItems(
                                                          (prevState) =>
                                                            prevState.map(
                                                              (prevItem) => {
                                                                if (
                                                                  prevItem.productId ===
                                                                  i.productId
                                                                ) {
                                                                  const productPrice =
                                                                    parseFloat(
                                                                      i.productPrice.replace(
                                                                        /[^0-9.-]+/g,
                                                                        ""
                                                                      )
                                                                    );
                                                                  const productPrices =
                                                                    (
                                                                      (prevItem.productQty -
                                                                        1) *
                                                                      productPrice
                                                                    ).toFixed(
                                                                      2
                                                                    );
                                                                  return {
                                                                    ...prevItem,
                                                                    productQty:
                                                                      prevItem.productQty -
                                                                      1,
                                                                    productPrices:
                                                                      productPrices,
                                                                    showHelperText: false,
                                                                  };
                                                                } else {
                                                                  return prevItem;
                                                                }
                                                              }
                                                            )
                                                        );
                                                      }}
                                                    >
                                                      <RemoveIcon
                                                        sx={{
                                                          verticalAlign:
                                                            "middle",
                                                          fontSize: 16,
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </>
                                                ),
                                              }}
                                            />

                                            <Typography
                                              className="itemSpan"
                                              component="span"
                                              variant="caption"
                                              color="#9a9a9a"
                                            >
                                              item
                                            </Typography>
                                            <Typography
                                              className="perItemPriceSpan"
                                              variant="caption"
                                              color="#9a9a9a"
                                            >{`${i.productPrice}/item`}</Typography>
                                          </Box>
                                          <Box
                                            display="flex"
                                            justifyContent="space-around"
                                            gap={2}
                                            className="shortcut"
                                          >
                                            <Link
                                              style={{
                                                textDecorationColor: "#9a9a9a",
                                              }}
                                              onClick={() =>
                                                addProductToCart(i)
                                              }
                                            >
                                              <Typography
                                                variant="caption"
                                                color="#9a9a9a"
                                              >
                                                Add To Bag
                                              </Typography>
                                            </Link>
                                            <Link
                                              style={{
                                                textDecorationColor: "#9a9a9a",
                                              }}
                                              onClick={() =>
                                                removeFromWishlist(i.productId)
                                              }
                                            >
                                              <Typography
                                                variant="caption"
                                                color="#9a9a9a"
                                              >
                                                Remove
                                              </Typography>
                                            </Link>
                                          </Box>
                                        </Stack>
                                        <Box>
                                          <Typography
                                            className="perItemPrice"
                                            variant="caption"
                                            color="#9a9a9a"
                                          >{`${i.productPrice}/item`}</Typography>
                                        </Box>
                                      </Box>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      className="lastgrid"
                                    >
                                      <Box
                                        display="flex"
                                        gap={2}
                                        className="shortcut2"
                                      >
                                        <Link
                                          style={{
                                            textDecorationColor: "#9a9a9a",
                                          }}
                                          onClick={() => addProductToCart(i)}
                                        >
                                          <Typography
                                            variant="caption"
                                            color="#9a9a9a"
                                          >
                                            Add To Bag
                                          </Typography>
                                        </Link>
                                        <Link
                                          style={{
                                            textDecorationColor: "#9a9a9a",
                                          }}
                                          onClick={() =>
                                            removeFromWishlist(i.productId)
                                          }
                                        >
                                          <Typography
                                            variant="caption"
                                            color="#9a9a9a"
                                          >
                                            Remove
                                          </Typography>
                                        </Link>
                                      </Box>
                                      <Box className="price">
                                        <Typography>
                                          {i.productQty === 1
                                            ? i.productPrice
                                            : `$${i.productPrices}`}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box py={2}>
                                  <Divider sx={{ borderColor: "darkgray" }} />
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                  alignContent="end"
                                >
                                  <Button
                                    onClick={() => addProductToCart(i)}
                                    variant="contained"
                                    className="button-62"
                                  >
                                    Add To Bag
                                  </Button>
                                </Box>
                                {i.productQty > 1 && (
                                  <Box
                                    display="flex"
                                    justifyContent="end"
                                    alignContent="end"
                                    py={1}
                                  >
                                    <Typography variant="caption">
                                      NOTE : (Click Add to Bag After Adjusting
                                      Qty)
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box mt={5}>
                      <Container>
                        <Grid container display="flex" justifyContent="center">
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Box
                              border={1}
                              borderColor="silver"
                              textAlign="center"
                            >
                              <Box py={5}>
                                <Typography
                                  color="#262626"
                                  fontSize={30}
                                  fontWeight={500}
                                >
                                  <HeartBrokenOutlinedIcon
                                    fontSize="large"
                                    sx={{
                                      verticalAlign: "middle",
                                      marginRight: 2,
                                    }}
                                  />
                                  Empty Wishlists!
                                </Typography>{" "}
                                <Button
                                  variant="contained"
                                  onClick={() => navigate("/shop")}
                                  sx={{
                                    width: {
                                      xs: 200,
                                      sm: 250,
                                      md: 500,
                                      lg: 500,
                                    },
                                    fontWeight: 600,
                                    boxShadow: "none",
                                    marginTop: 2,
                                    bgcolor: "#008844",
                                  }}
                                >
                                  START SHOPPING
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  )}
                </Box>
              </Container>
            </Grid>
          </Grid>
          <ToastContainer />
        </Box>
      )}
      <SecondaryFooter />
    </Box>
  );
}
