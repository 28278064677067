import React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import {
  Divider,
  Checkbox,
  Container,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductsContext } from "../../Context/CountContext";
import SnackBar from "../../Comps/Snackbar";
import { appendData } from "../../Variables/ProcessVariable";
import { fetch_tracking_shipment } from "../../Services/Services/OrderService";
import { fetch_add_to_cart } from "../../Services/Services/ProductService";

export default function TrackYourOrder() {
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const userId = JSON.parse(localStorage.getItem("userId"));
  const location = useLocation();
  const { orderId } = location.state || "";
  const [TrackingData, setTrackingData] = useState([]);
  const [TrackedOrder, setTrackedOrder] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [Products, setProducts] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const [ShowTrack, setShowTrack] = useState(false);
  const { setCartCount } = useContext(ProductsContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getTrack = async () => {
      if (orderId !== null && orderId !== "" && orderId !== undefined) {
        const serverData = new FormData();
        serverData.append("orderId", orderId);
        try {
          const res = await fetch_tracking_shipment(serverData);
          if (!res.data.error) {
            setOpen(true);
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            setShowTrack(true);
            setTrackingData(res.data?.data[0]);
            setTrackedOrder(res.data?.data[1]);
            setProducts(res.data?.data[1]?.products);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setShowTrack(false);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    };
    getTrack();
  }, [orderId]);

  const getTrackStatus = async () => {
    if (
      searchOrderNumber !== null &&
      searchOrderNumber !== "" &&
      searchOrderNumber !== undefined
    ) {
      const serverData = new FormData();
      serverData.append("orderId", searchOrderNumber);
      try {
        const res = await fetch_tracking_shipment(serverData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setShowTrack(true);
          setTrackingData(res.data?.data[0]);
          setTrackedOrder(res.data?.data[1]);
          setProducts(res.data?.data[1]?.products);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setShowTrack(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  function convertToStandardTime(scanTime) {
    // Parse the timestamp
    const hours = parseInt(scanTime.substring(0, 2));
    const minutes = parseInt(scanTime.substring(2, 4));

    // Determine if it's AM or PM
    const period = hours < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the result
    const standardTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return standardTime;
  }

  const TrackingStatus = TrackingData?.TrackingInformation?.Scans?.Scan || [];
  const TrackingStatuArray = Array.isArray(TrackingStatus)
    ? TrackingStatus
    : [TrackingStatus];
  const TrackingNo = TrackingData?.TrackingInformation?.PIN?.Value;

  const handleCheckboxChange = (index) => {
    const updatedProducts = [...Products];
    updatedProducts[index].is_checked = !updatedProducts[index].is_checked;
    setProducts(updatedProducts);
  };

  const handleSelectAllChange = (e) => {
    const updatedProducts = Products.map((product) => ({
      ...product,
      is_checked: e.target.checked,
    }));
    setProducts(updatedProducts);
    setSelectAll(e.target.checked);
  };

  const calculateTotalPrice = () => {
    const totalPrice =
      Products &&
      Products.reduce((total, product) => {
        const productPrice =
          parseFloat(product?.productPrice?.replace("$", "")) || 0;
        return total + productPrice;
      }, 0).toFixed(2);
    return setSubTotal(totalPrice);
  };

  useEffect(() => {
    calculateTotalPrice();
  });

  const Reorder = () => {
    // Filter checked products
    const checkedProducts = Products.filter((product) => product.is_checked);

    if (checkedProducts.length === 0) {
      // You can show an error message or take appropriate action
      return;
    }

    // Loop through the checked products and send one request for each
    checkedProducts.forEach(async (product) => {
      const qtyAsNumber = parseInt(product.orderedQty, 10);
      if (!isNaN(qtyAsNumber)) {
        const productToAdd = {
          userId: userId,
          productId: product.productId,
          qty: qtyAsNumber,
          cart: 1,
          productColor: product.productColor,
        };

        const lData = appendData(productToAdd);
        try {
          const res = await fetch_add_to_cart(lData);
          if (!res.data.error) {
            setCartCount((prevCount) => prevCount + 1);
            setOpen(true);
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            navigate("/trolley");
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    });
  };

  const DirectReorder = () => {
    Products.forEach(async (product) => {
      const qtyAsNumber = parseInt(product.orderedQty, 10);
      if (!isNaN(qtyAsNumber)) {
        const productToAdd = {
          userId: userId,
          productId: product.productId,
          qty: qtyAsNumber,
          cart: 1,
          productColor: product.productColor,
        };

        const lData = appendData(productToAdd);
        try {
          const res = await fetch_add_to_cart(lData);
          if (!res.data.error) {
            setCartCount((prevCount) => prevCount + 1);
            setOpen(true);
            setMessage(res.data.message);
            setStatus(true);
            setColor(true);
            navigate("/trolley");
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    });
  };

  return (
    <Box>
      <Header />
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />
      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Track Your Order
          </Typography>
          <Stack spacing={5}>
            <Box py={2}>
              <TextField
                size="small"
                id="inputpassword"
                defaultValue=""
                variant="outlined"
                name="orderNumber"
                value={searchOrderNumber}
                onChange={(e) => setSearchOrderNumber(e.target.value)}
                required
                label="Your Order Number"
              />
              <Button
                color="warning"
                onClick={getTrackStatus}
                variant="contained"
                sx={{ ml: 4 }}
              >
                Find My Order
              </Button>
            </Box>
          </Stack>
        </Box>
      </Container>

      {ShowTrack && (
        <>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} my={2}>
                <Box textAlign="left">
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Number:</Typography>
                    <Typography color="#262626">
                      {TrackedOrder.userOrderId}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Placed:</Typography>
                    <Typography color="#262626">
                      {TrackedOrder.orderPlaced}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Status:</Typography>
                    <Typography color="#262626">
                      {TrackedOrder.orderStatus}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Placed By:</Typography>
                    <Typography color="#262626">
                      {TrackedOrder.orderPlacedBy}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} justifyContent="end">
                <Box display="flex" justifyContent="end">
                  <Button onClick={() => navigate(-1)}>
                    Back To Previous Page
                  </Button>
                  <Button onClick={() => navigate("/returnexchange")}>
                    Return
                  </Button>
                  <Button onClick={DirectReorder}>Reorder</Button>
                </Box>
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} my={2}>
                <Box textAlign="left">
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Tracking #:</Typography>
                    <Typography color="#262626">{TrackingNo}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">
                      Estimated Delivery On:
                    </Typography>
                    <Typography color="#262626">
                      {TrackedOrder.expectedDelivery}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Shipped By:</Typography>
                    <Typography color="#262626">Purolator</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-evenly"
                >
                  <Box textAlign="left">
                    <Typography fontWeight={550}>Shipping Address</Typography>
                    <Stack mt={1}>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">Street:</Typography>
                        <Typography color="#262626">
                          {TrackedOrder.address}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">City:</Typography>
                        <Typography color="#262626">
                          {TrackedOrder.city}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">Street:</Typography>
                        <Typography color="#262626">
                          {TrackedOrder.province}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">Street:</Typography>
                        <Typography color="#262626">
                          {TrackedOrder.pincode}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box textAlign="left">
                    <Typography fontWeight={550}>Payment Method</Typography>
                    <Stack mt={1}>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">Order Cost:</Typography>
                        <Typography color="#262626">
                          ${TrackedOrder.totalCost}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        <Typography color="#616e80">Payment Mode:</Typography>
                        <Typography color="#262626">
                          {TrackedOrder.paymentOption}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box mt={3}>
                  <TableContainer sx={{ border: "1px solid silver" }}>
                    <Table size="small">
                      <TableHead sx={{ bgcolor: "#faa634" }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              borderRight: "1px solid silver",
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              borderRight: "1px solid silver",
                            }}
                          >
                            Time
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              borderRight: "1px solid silver",
                            }}
                          >
                            City
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              borderRight: "1px solid silver",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              borderRight: "1px solid silver",
                            }}
                          >
                            Comment
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {TrackingStatuArray &&
                          TrackingStatuArray.map((i, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  borderBottom: "1px solid silver",
                                  "& .MuiTableCell-root": {
                                    height: "20px",
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    borderBottom: "1px solid silver",
                                    borderLeft: "1px solid silver",
                                    borderRight: "1px solid silver",
                                  }}
                                >
                                  {i.ScanDate}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    borderBottom: "1px solid silver",
                                    borderLeft: "1px solid silver",
                                    borderRight: "1px solid silver",
                                  }}
                                >
                                  {convertToStandardTime(i.ScanTime)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    borderBottom: "1px solid silver",
                                    borderLeft: "1px solid silver",
                                    borderRight: "1px solid silver",
                                  }}
                                >
                                  {i?.Depot?.Name}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom: "1px solid silver",
                                    borderLeft: "1px solid silver",
                                    borderRight: "1px solid silver",
                                  }}
                                >
                                  {i?.Depot?.Name
                                    ? `${i.Description} -  ${i?.Depot?.Name}`
                                    : i?.Description}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>

            {/* 
  <Grid container spacing={2}>
         <Grid item xs={12} sm={12} md={12} lg={12} py={2}>
            <Box py={4}>
              <Stepper activeStep={1} alternativeLabel>
                {scans?.map((scan, index) => (
                  <Step key={index} completed={true}> 
                    <StepLabel
                      StepIconComponent={StepIcon} 
                      StepIconProps={{ completed: true }}
                    >
                      {scan?.Description || ''}
                    </StepLabel>
                    <Stack>
                      {
                        scan?.Depot?.Name && <Typography variant='caption'>{scan?.Depot?.Name}</Typography>
                      }
                      {
                        scan?.ScanDate && <Typography variant='caption' >{scan?.ScanDate}</Typography>
                      }
                    </Stack>

                  </Step>
                ))}
              </Stepper>
            </Box>
         </Grid>
         </Grid> */}
          </Container>

          <Container>
            <Box mt={4}>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table size="small">
                  <TableHead sx={{ bgcolor: "#faa634" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          borderRight: "1px solid silver",
                        }}
                      >
                        Product
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          borderRight: "1px solid silver",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          borderRight: "1px solid silver",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          borderRight: "1px solid silver",
                        }}
                      >
                        Price
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, textAlign: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          </Box>
                          <Box>Select</Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Products &&
                      Products.map((i, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              borderBottom: "1px solid silver",
                              "& .MuiTableCell-root": {
                                height: "20px",
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderBottom: "1px solid silver",
                                borderLeft: "1px solid silver",
                                borderRight: "1px solid silver",
                              }}
                            >
                              <img
                                alt="product"
                                src={`${BaseImageAPI}${i.mainImage}`}
                                style={{
                                  width: 50,
                                  height: 50,
                                  verticalAlign: "middle",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderBottom: "1px solid silver",
                                borderLeft: "1px solid silver",
                                borderRight: "1px solid silver",
                              }}
                            >
                              <Box>
                                <Typography> {i.productName}</Typography>
                                <Typography variant="caption">
                                  ({i.productColor})
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderBottom: "1px solid silver",
                                borderLeft: "1px solid silver",
                                borderRight: "1px solid silver",
                              }}
                            >
                              {i.orderedQty}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderBottom: "1px solid silver",
                                borderLeft: "1px solid silver",
                                borderRight: "1px solid silver",
                              }}
                            >
                              {i.productPrice}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <Checkbox
                                  checked={i.is_checked || false}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="end" p={2}>
                <Stack spacing={1} textAlign="left">
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Sub Total:</Typography>
                    <Typography color="#262626">
                      ${TrackedOrder.orderCost}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Taxes:</Typography>
                    <Typography color="#262626">
                      ${TrackedOrder.taxCost}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Shipping Cost:</Typography>
                    <Typography color="#262626">
                      ${TrackedOrder.shippingCost}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Paid:</Typography>
                    <Typography color="#262626">
                      ${TrackedOrder.totalCost}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Container>

          <Container>
            <Box display="flex" justifyContent="end" p={2}>
              <Typography variant="caption" sx={{ py: 2 }}>
                All items selected will be Added to Cart
              </Typography>
              <Button
                variant="contained"
                onClick={Reorder}
                color="warning"
                size="small"
                sx={{ marginLeft: 2 }}
              >
                Reorder
              </Button>
            </Box>
          </Container>
        </>
      )}
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
