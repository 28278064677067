import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Divider,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import Innovation from "../../Assets/About/project-management.png";
import Integrity from "../../Assets/About/trust.png";
import Impeccable from "../../Assets/About/complete.png";
import Intriguing from "../../Assets/About/loupe.png";
import Image1 from "../../Assets/About/AboutusImage1.jpg";
import Image2 from "../../Assets/About/AboutusImage2.jpg";
import "./AboutPageStyle.css";
import Customer from "../../Assets/About/customer.png";
import Compliance from "../../Assets/About/cyber-security.png";
import Uncompromised from "../../Assets/About/badge.png";
import Technology from "../../Assets/About/touch.png";

export default function About() {
  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h4" fontWeight={600} mb={2}>
                  Who We Are
                </Typography>
                <Typography textAlign="justify" sx={{ color: "#49484a" }}>
                  We are affordable and quality products selling web store,
                  based at Saskatoon, Saskatchewan Province in Canada. We are a
                  company committed for its values, offering quality products
                  thoughtfully sourced from various parts of the world and
                  coupled with several decades of supply chain management
                  expertise. We offer retailing; private labeling and contracted
                  work.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Mission and Vision */}
      <Box mt={4} mb={4} bgcolor="#d1d5db">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            alignItems="center"
            order={{ xs: 2, sm: 2, lg: 1 }}
          >
            <Box px={3} display="flex" flexDirection="column" gap={3}>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                Our Vision
              </Typography>
              <Typography
                textAlign="justify"
                sx={{ marginTop: 1, color: "#49484a" }}
              >
                We aim to be Canada’s best and affordable online retail store
                sourcing the Quality &amp; Innovation in Technology-Oriented,
                Customer-Focused and day to day useable products bridging
                various industries with a strong emphasize on serving the
                communities. We are driven by the passion to serve and exceed
                our customer’s expectation.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            order={{ xs: 1, sm: 1, lg: 2 }}
          >
            <img
              src={Image1}
              alt="img"
              style={{ objectFit: "cover", width: "100%", height: 300 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            order={{ xs: 3, sm: 3, lg: 3 }}
          >
            <img
              src={Image2}
              alt="img"
              style={{ objectFit: "cover", width: "100%", height: 300 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            alignItems="center"
            order={{ xs: 4, sm: 4, lg: 4 }}
          >
            <Box px={3} display="flex" flexDirection="column" gap={3}>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                Our Mission
              </Typography>
              <Typography
                textAlign="justify"
                sx={{ marginTop: 1, color: "#49484a" }}
              >
                To reach out to every corners of Canada, grow as an online
                retailer, passionately serve the customers and continuously
                pursue the art of selling Environment friendly products.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Container>
        <Box my={3}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={5} mb={2}>
              <Box>
                <Typography variant="h4" fontWeight={600}>
                  Our Values
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Box component={Card} boxShadow={4} py={2}>
                <Box py={4}>
                  <img
                    src={Compliance}
                    alt="Compliance"
                    style={{ height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography>Compliance Standard</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Box component={Card} boxShadow={4} py={2}>
                <Box py={4}>
                  <img
                    src={Customer}
                    alt="Customer"
                    style={{ height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography>Customer Service</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Box component={Card} boxShadow={4} py={2}>
                <Box py={4}>
                  <img
                    src={Uncompromised}
                    alt="Uncompromised "
                    style={{ height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography>Uncompromised quality</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Box component={Card} boxShadow={4} py={2}>
                <Box py={4}>
                  <img
                    src={Technology}
                    alt="Technology"
                    style={{ height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography>Technology &amp; Innovation</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Customer Value preposition */}

      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={8} mb={2}>
              <Box>
                <Typography variant="h4" fontWeight={600}>
                  Customer Value Preposition
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mb={10}>
          <Grid container mb={2} mt={3} spacing={2} rowSpacing={10}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container rowGap={10}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} my={5}>
                  <Box
                    bgcolor="#6892d7"
                    boxShadow={3}
                    width={233}
                    height={247}
                    position="relative"
                  >
                    <Box py={4}>
                      <img
                        src={Innovation}
                        alt="Innovation"
                        style={{ height: 80, width: 80 }}
                      />
                    </Box>
                    <Box
                      p={2}
                      alignItems="center"
                      gap={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent="center"
                      textAlign="left"
                      bgcolor="#ffff"
                      boxShadow={3}
                      width={220}
                      height={200}
                      sx={{
                        transform: "translate(-30px, -20px)",
                        marginLeft: { lg: "", md: "", xs: "20px", sm: "20px" },
                      }}
                    >
                      <Typography fontWeight={600} variant="h6">
                        Innovation
                      </Typography>
                      <Typography variant="subtitle1" color="#616e80">
                        To provide the customer with innovative product
                        solution.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box
                    bgcolor="#f15048"
                    boxShadow={3}
                    width={233}
                    height={247}
                    position="relative"
                  >
                    <Box py={4}>
                      <img
                        src={Integrity}
                        alt="Integrity"
                        style={{ height: 80, width: 80 }}
                      />
                    </Box>
                    <Box
                      p={2}
                      alignItems="center"
                      gap={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent="center"
                      textAlign="left"
                      bgcolor="#ffff"
                      boxShadow={3}
                      width={220}
                      height={200}
                      sx={{ transform: "translate(40px, -20px)" }}
                    >
                      <Typography fontWeight={600} variant="h6">
                        Integrity
                      </Typography>
                      <Typography variant="subtitle1" color="#616e80">
                        To exceed the customer expectation and meet their
                        requirement.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container spacing={2} rowGap={10}>
                <Grid xs={12} sm={6} md={6} lg={6} xl={6} my={5}>
                  <Box
                    bgcolor="#5cb85c"
                    boxShadow={3}
                    width={233}
                    height={247}
                    position="relative"
                    marginLeft={{ lg: "", md: "", xs: "30px", sm: "30px" }}
                  >
                    <Box py={4}>
                      <img
                        src={Impeccable}
                        alt="Impeccable"
                        style={{ height: 80, width: 80 }}
                      />
                    </Box>
                    <Box
                      p={2}
                      alignItems="center"
                      gap={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent="center"
                      textAlign="left"
                      bgcolor="#ffff"
                      boxShadow={3}
                      width={220}
                      height={200}
                      sx={{
                        transform: "translate(-40px, -20px)",
                        marginLeft: { lg: "", md: "", xs: "30px", sm: "30px" },
                      }}
                    >
                      <Typography fontWeight={600} variant="h6">
                        Impeccable
                      </Typography>
                      <Typography variant="subtitle1" color="#616e80">
                        To dedicate ourselves in delivering service of the
                        highest standard, marked by impeccable quality ,
                        precision and Impartiality.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Box
                    bgcolor="#fb9056"
                    boxShadow={3}
                    width={233}
                    height={247}
                    position="relative"
                    marginLeft={{ lg: "", md: "", xs: "30px", sm: "30px" }}
                  >
                    <Box py={4}>
                      <img
                        src={Intriguing}
                        alt="Intriguing"
                        style={{ height: 80, width: 80 }}
                      />
                    </Box>
                    <Box
                      p={2}
                      alignItems="center"
                      gap={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent="center"
                      textAlign="left"
                      bgcolor="#ffff"
                      boxShadow={3}
                      width={220}
                      height={200}
                      sx={{ transform: "translate(30px, -20px)" }}
                    >
                      <Typography fontWeight={600} variant="h6">
                        Intriguing
                      </Typography>
                      <Typography variant="subtitle1" color="#616e80">
                        To provide Customers with a convenience of shopping
                        anytime.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
