import {
  Box,
  Typography,
  Container,
  Grid,
  Rating,
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import { useRef } from "react";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { appendData } from "../../Variables/ProcessVariable";
import { ProductsContext } from "../../Context/CountContext";
import ProductDescription from "./Descritpion";
import Slider from "./Slider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import IconBreadcrumbs from "../../Comps/Bread/StyledBread";
import {
  fetch_add_to_cart,
  fetch_getParticularItem,
  fetch_get_user_cart,
} from "../../Services/Services/ProductService";
import "./ShopPage.styles.css";

export default function QuickViewScreen() {
  const [open, setOpen] = useState(false);
  const [ProductDialogopen, setProductDialogopen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [homeProducts, setHomeProducts] = useState([]);
  const [computerProducts, setComputerProducts] = useState([]);
  const [healthcareProducts, setHealthcareProducts] = useState([]);
  const [carProducts, setCarProducts] = useState([]);
  const [recreational, setRecreational] = useState([]);
  const [allCartItems, setAllCartItems] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const navigate = useNavigate();
  const [productQty, setProductQty] = useState(1);
  const [addedQtyinPopUp, setAddedQtyinPopUp] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [refreshCount, setRefreshCount] = useState(false);
  const { setCartCount, CartCount, wishlistCount, setWishlistCount } =
    useContext(ProductsContext);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");
  const auth = localStorage.getItem("Userauth");
  const [OpenFilterSection, setOpenFilterSection] = useState(false);
  const [FilteredProductsList, setFilterProductsList] = useState([]);
  const [badgeCount, setBadgeCount] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [initialIncrement, setInitialIncrement] = useState(10);
  const [storedWishlist, setStoredWishlist] = useState([]);
  const [HelperText, setHelperText] = useState("");
  const [availableStock, setAvailableStock] = useState(1);
  const { productId, productName } = useParams();
  const userAuth = JSON.parse(localStorage.getItem("Userauth"));
  const [PreviewData, setPreviewData] = useState([]);

  const textFieldRef = useRef();

  useEffect(() => {
    const getParticular = async () => {
      const sendData = new FormData();
      sendData.append("categoryId", "");
      sendData.append("productId", productId);
      try {
        const res = await fetch_getParticularItem(sendData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setPreviewData(res.data.data);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setPreviewData([]);
          setColor(false);
          setOpen(true);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getParticular();
  }, [productId]);

  const handleColorClick = (color, productImage) => {
    setSelectedColor(color);
    setAvailableStock(productImage[color][0]?.inStock);
    setHelperText("");
  };

  useEffect(() => {
    if (PreviewData.length > 0) {
      const defaultColor = Object.keys(PreviewData[0]?.productImage)[0];
      setSelectedColor(defaultColor);
      setAvailableStock(PreviewData[0]?.productImage[defaultColor][0]?.inStock);
    }
  }, [PreviewData]);

  // Open Reviews
  const handleOpenReview = (i) => {
    navigate("/reviews", { state: { id: i.productId } });
  };

  // Close Pop_up
  const handleClose = () => {
    setProductDialogopen(false);
    setProductQty(1);
    setHelperText("");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (ProductDialogopen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [ProductDialogopen]);

  // Open Filter Menu
  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  // GET USER CART DATA

  const getUserCartData = async () => {
    if (auth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_get_user_cart(serverData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setAllCartItems(res.data.cart);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };
  useEffect(() => {
    getUserCartData();
  }, [userId]);

  // Adding Product From Pop_up
  const addProductToCart = async (id) => {
    if (productQty !== null && productQty !== "") {
      if (!auth) {
        setIsSnackbarOpen(true);
        setNotification(
          "You need to log in before you can add items to your wishlist."
        );
      } else {
        const obj = {
          userId: userId,
          productId: id,
          qty: productQty,
          cart: 1,
          productColor: selectedColor,
        };
        const lData = appendData(obj);
        try {
          const res = await fetch_add_to_cart(lData);
          if (!res.data.error) {
            setCartCount(CartCount + productQty);
            setIsAddedCartSnackbarOpen(true);
            setNotification("Item Added to your Cart.");
            handleClose();
          } else {
            errorMsg(res.data.message);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    } else {
      errorMsg("Add Minimum One Item to Proceed");
    }
  };

  //Buy Now

  const BuyDirectly = async (id) => {
    if (productQty !== null && productQty !== "") {
      if (!auth) {
        setIsSnackbarOpen(true);
        setNotification(
          "You need to log in before you can add items to your wishlist."
        );
      } else {
        const obj = {
          userId: userId,
          productId: id,
          qty: productQty,
          cart: 1,
          productColor: selectedColor,
        };
        const lData = appendData(obj);
        try {
          const res = await fetch_add_to_cart(lData);
          if (!res.data.error) {
            successMsg(res.data.message);
            setCartCount(CartCount + productQty);
            setHelperText("");
            navigate(`/trolley#trolleycon`);
          } else {
            errorMsg(res.data.message);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    } else {
      errorMsg("Add Minimum One Item to Proceed");
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  return (
    <Box position="relative">
      <Header />

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {Notification}
        </Alert>
      </Snackbar>

      {/* add Notifications */}

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseAddedCartSnackbar}
      >
        <Alert onClose={handleCloseAddedCartSnackbar}>{Notification}</Alert>
      </Snackbar>

      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className="dialog">
              <Box py={2} px={1}>
                <IconBreadcrumbs
                  previous={"Home"}
                  current={"Shop"}
                  link1={`/`}
                  link2={-1}
                />
              </Box>
              <Box textAlign="left" py={2} px={2}>
                <Grid container spacing={1} sx={{ cursor: "pointer" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    justifyContent="start"
                    display="flex"
                  >
                    <Slider
                      data={PreviewData}
                      selectedColor={selectedColor}
                      setSelectedColor={setSelectedColor}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Container>
                      {PreviewData?.map((i, index) => {
                        return (
                          <Stack spacing={3} key={index}>
                            <Box>
                              <Typography variant="h5" color="#000000">
                                {i.productName}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontWeight={500}
                                sx={{ marginTop: 1 }}
                              >
                                CAD {i.productPrice}
                              </Typography>
                              <Box display="flex" flexDirection="row" gap={3}>
                                <Typography
                                  variant="subtitle2"
                                  color="#616e80"
                                  sx={{
                                    marginTop: 1,
                                    textDecoration: "line-through",
                                  }}
                                >
                                  CAD {i.productMRP}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginTop: 1 }}
                                >
                                  {i.productDiscountPercent !== ""
                                    ? `${i.productDiscountPercent}% OFF`
                                    : ""}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                width: 400,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 12 }}>
                                Customer Rating -
                              </Typography>
                              <Rating
                                name="hover-feedback"
                                value={i.overallRating}
                                precision={0.5}
                                readOnly
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {
                                <Typography
                                  color="#616e80"
                                  variant="caption"
                                  sx={{ marginTop: 1 }}
                                >
                                  {i.productReviewCount >= 1
                                    ? `(${i.productReviewCount})`
                                    : ""}
                                </Typography>
                              }
                            </Box>
                            <Box display="flex" gap={1}>
                              <Typography
                                variant="caption"
                                sx={{ textDecoration: "underline" }}
                                onClick={() => {
                                  if (
                                    i.launchingSoon === "yes" ||
                                    i.soldOut === "yes"
                                  ) {
                                    // Handle click action for launching soon or sold out products
                                    // (if needed)
                                  } else {
                                    handleOpenReview(i);
                                  }
                                }}
                              >
                                {i.productReviewCount}
                                {i.productReviewCount >= 1
                                  ? " Comments"
                                  : " Comment"}
                              </Typography>
                              <Typography variant="caption">/</Typography>
                              {i.launchingSoon === "yes" ? (
                                <Typography
                                  variant="caption"
                                  sx={{ textDecoration: "underline" }}
                                >
                                  {" "}
                                  Write a Review
                                </Typography>
                              ) : (
                                <Typography
                                  onClick={() => handleOpenReview(i)}
                                  variant="caption"
                                  sx={{ textDecoration: "underline" }}
                                >
                                  {" "}
                                  Write a Review
                                </Typography>
                              )}
                            </Box>
                            <Box>
                              <Typography variant="subtitle1" fontWeight={700}>
                                Colors
                              </Typography>
                              {i.comboColor === "yes" ? (
                                <>{i.colorCode}</>
                              ) : (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap={1}
                                  py={2}
                                  flexWrap="wrap"
                                >
                                  {Object.keys(i.productImage).map((color) => (
                                    <Box
                                      p={1}
                                      key={color}
                                      onClick={() =>
                                        handleColorClick(color, i.productImage)
                                      }
                                      sx={{
                                        width: "auto",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        border:
                                          selectedColor === color
                                            ? "3px solid #ffa700"
                                            : "1px solid silver",
                                      }}
                                    >
                                      <img
                                        src={`${BaseImageAPI}${i.productImage[color][0].productImage}`} // Assuming the first image URL is in the first item of the color array
                                        alt={color}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          mixBlendMode: "multiply",
                                        }}
                                      />
                                      <span
                                        style={{
                                          fontSize: 14,
                                          color: "#000",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {color}
                                      </span>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label="Qty"
                                size="small"
                                id="outlined-start-adornment"
                                sx={{ width: 150, marginTop: 1 }}
                                value={
                                  productQty > availableStock
                                    ? availableStock
                                    : productQty
                                }
                                max={availableStock}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const numericValue = inputValue.replace(
                                    /\D/g,
                                    ""
                                  );
                                  if (numericValue === "") {
                                    setProductQty(null);
                                    setHelperText("");
                                  } else {
                                    const parsedValue = parseInt(numericValue);
                                    if (parsedValue < availableStock) {
                                      setProductQty(parsedValue);
                                      setHelperText("");
                                    } else {
                                      setProductQty(availableStock);
                                      setHelperText(
                                        "In Stock: " + availableStock
                                      );
                                    }
                                  }
                                }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                  max: availableStock,
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        onClick={() => {
                                          if (productQty < availableStock) {
                                            setProductQty(
                                              (prevQty) => parseInt(prevQty) + 1
                                            );
                                          } else {
                                            setProductQty(availableStock);
                                            setHelperText(
                                              "In Stock: " + availableStock
                                            );
                                          }
                                        }}
                                      >
                                        <AddIcon
                                          sx={{
                                            verticalAlign: "middle",
                                            fontSize: 16,
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        disabled={productQty === 1 && "true"}
                                        onClick={() =>
                                          setProductQty(
                                            parseInt(productQty) - 1
                                          )
                                        }
                                      >
                                        <RemoveIcon
                                          sx={{
                                            verticalAlign: "middle",
                                            fontSize: 16,
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {HelperText !== "" && (
                                <Typography
                                  variant="subtitle2"
                                  mt={1}
                                  style={{ color: "red" }}
                                >
                                  {HelperText}
                                </Typography>
                              )}
                            </Box>
                            <Box className="addToBagandClose" p={1}>
                              {i.launchingSoon === "no" &&
                                i.soldOut === "no" && (
                                  <>
                                    <Box>
                                      <Button
                                        sx={{
                                          width: {
                                            lg: "20ch",
                                            md: "18ch",
                                            sm: "auto",
                                            xs: "auto",
                                          },
                                        }}
                                        className="button-62"
                                        role="button"
                                        onClick={() =>
                                          addProductToCart(
                                            i.productId,
                                            i.isBulkOrder
                                          )
                                        }
                                      >
                                        ADD TO CART
                                      </Button>
                                    </Box>
                                    <Box>
                                      <Button
                                        sx={{
                                          width: {
                                            lg: "20ch",
                                            md: "18ch",
                                            sm: "auto",
                                            xs: "auto",
                                          },
                                        }}
                                        className="button-64"
                                        role="button"
                                        onClick={() =>
                                          BuyDirectly(
                                            i.productId,
                                            i.isBulkOrder
                                          )
                                        }
                                      >
                                        BUY NOW
                                      </Button>
                                    </Box>
                                  </>
                                )}
                            </Box>
                            <Box>
                              <ProductDescription
                                productDescription={i.productDescription}
                              />
                            </Box>
                            {/* {
                              (i.launchingSoon === 'yes' || i.soldOut === 'yes') &&
                              <Box display='flex' justifyContent='end'>
                                <Button className="button-62" role="button" onClick={() => handleClose()}>Close</Button>
                              </Box>
                            } */}
                          </Stack>
                        );
                      })}
                    </Container>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
