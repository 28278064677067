import React, { useEffect, useRef, useState } from "react";
import "./Slider.styles.css";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import videoPoster from "../../Assets/VideoPoster.PNG";
import { Box, Button } from "@mui/material";
import RightArrow from "../../Assets/rightArrow.png";
import LeftArrow from "../../Assets/leftArrow.png";

export default function Slider({ data, selectedColor, setSelectedColor }) {
  const video = data[0]?.productVideo;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  const colors = Object.keys(data?.[0]?.productImage || {});
  const defaultColor = colors[0];
  const images = selectedColor ? data[0]?.productImage[selectedColor] : [];

  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]);

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images?.length
      );
      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleSelectedImageChange = (newIdx) => {
    if (images && images?.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef.current[newIdx] && newIdx !== images?.length) {
        carouselItemsRef.current[newIdx].scrollIntoView({
          inline: "top",
          behavior: "smooth",
        });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images?.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx === images?.length && video) {
        newIdx = images.length; // Set index to video thumbnail index
      } else if (newIdx > images.length || newIdx === images?.length) {
        newIdx = 0; // Cycle back to the first image
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images?.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx === images?.length && video) {
        newIdx = images?.length; // Set index to video thumbnail index
      } else if (newIdx < 0) {
        newIdx = images?.length - 1; // Set index to the last image
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <Box
      className="carousel-container"
      sx={{ marginLeft: { lg: "50px", md: "30px", sm: "15px", xs: 0 } }}
    >
      {selectedImage ? (
        <div
          className="selected-image"
          style={{
            backgroundImage: `url(${BaseImageAPI}${selectedImage?.productImage})`,
          }}
        />
      ) : (
        <div className="selected-image" />
      )}
      {selectedImageIndex === images?.length && (
        <div className="selected-video">
          <video src={`${BaseImageAPI}${video}`} controls className="video" />
        </div>
      )}
      <Button
        className="carousel__button carousel__button-left"
        onClick={handleLeftClick}
      >
        <img
          src={LeftArrow}
          style={{
            width: 35,
            height: 35,
            verticalAlign: "middle",
            objectFit: "contain",
          }}
        />
      </Button>
      <Button
        className="carousel__button carousel__button-right"
        onClick={handleRightClick}
      >
        <img
          src={RightArrow}
          style={{
            width: 35,
            height: 35,
            verticalAlign: "middle",
            objectFit: "contain",
          }}
        />
      </Button>
      <div className="carousel">
        <div className="carousel__images">
          {images &&
            images?.map((image, idx) => (
              <img
                onClick={() => handleSelectedImageChange(idx)}
                src={`${BaseImageAPI}${image.productImage}`}
                key={idx}
                className={`carousel__image ${
                  selectedImageIndex === idx && "carousel__image-selected"
                }`}
                ref={(el) => (carouselItemsRef.current[idx] = el)}
              />
            ))}
          {video && (
            <div onClick={() => handleSelectedImageChange(images?.length)}>
              <img
                src={videoPoster}
                alt=""
                className={`carousel__image ${
                  selectedImageIndex === images?.length &&
                  "carousel__image-selected"
                }`}
              />
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}
