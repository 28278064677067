import { Typography, Rating } from "@mui/material";
import { motion, useMotionValue, useTransform } from "framer-motion";
import React, { useContext } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import "./ProductsCard.styles.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { appendData } from "../../Variables/ProcessVariable";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { ProductsContext } from "../../Context/CountContext";
import { useNavigate } from "react-router-dom";
import { fetch_add_to_wishlist } from "../../Services/Services/ProductService";

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CardContainer = styled(motion.div)`
  width: 290px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  color: #fff;
  position: relative;
  filter: brightness(105%);
  background: radial-gradient(
    circle farthest-corner at center center,
    #f6f8f9 0%,
    #e5ebee 0%,
    #d7dee3 9%,
    #f5f7f9 100%
  );

  cursor: ${(props) =>
    props.isLaptop ? "grab" : "default"}; // Set cursor style conditionally
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.2;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  padding: 2em 15px;
`;

const ShoesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Shoes = styled(motion.div)`
  width: auto;
  height: 200px;
  z-index: 99;
  user-select: none;
  margin-top: 5em;
  img {
    width: 250px;
    height: 100%;
    user-select: none;
    background-blend-mode: multiply;
  }
`;

export function ProductsCard(props) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);
  const [active, setActive] = useState(-1);
  const [showaddcart, setshowaddcart] = useState(false);
  const [showHeart, setShowHeart] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [Notification, setNotification] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userAuth = JSON.parse(localStorage.getItem("Userauth"));
  const { setCartCount, CartCount, wishlistCount, setWishlistCount } =
    useContext(ProductsContext);
  const imageX = useTransform(x, (value) => value); // You can adjust this as needed
  const imageY = useTransform(y, (value) => value);
  const [isLaptop, setIsLaptop] = useState(false);
  const [include, setInclude] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Use a media query to detect screen width and set the isLaptop state
    const mediaQuery = window.matchMedia("(min-width: 768px)"); // Adjust the value to your preferred breakpoint
    setIsLaptop(mediaQuery.matches);

    const handleResize = () => {
      setIsLaptop(mediaQuery.matches);
    };

    // Attach the event listener to handle resizing
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOpen = (i) => {
    const Preview = [{ ...i }];
    // const encryptedReview = encryptData(Preview);
    // localStorage.setItem('currentReview', encryptedReview);
    const encodedProductName = encodeURIComponent(
      i.productName.replace(/\s+/g, "").toLowerCase()
    );
    navigate(`/view/${i.productId}/${encodedProductName}`);
    // props.QuickView(PreviewData);
  };

  useEffect(() => {
    const productInWishlist =
      props.storedWishlist.findIndex(
        (product) => product.productId === props.i.productId
      ) !== -1;

    setInclude(productInWishlist);
  }, [props.i.productId, props.storedWishlist]);

  const addToWishlist = async () => {
    if (!userAuth) {
      setIsSnackbarOpen(true);
      setNotification(
        "You need to log in before you can add items to your wishlist."
      );
    } else {
      setShowHeart(!showHeart);
      const obj = {
        userId: userId,
        productId: props.i.productId,
        wishlist: 1,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_wishlist(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          props.getUserwishlistedItems();
          setIsAddedCartSnackbarOpen(true);
          setNotification("Item Added to your wishlist.");
          setWishlistCount(wishlistCount + 1);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  const removeFromWishlist = async () => {
    setShowHeart(!showHeart);
    if (props.storedWishlist.length > 1) {
      const obj = {
        userId: userId,
        productId: props.i.productId,
        wishlist: 0,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_wishlist(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          props.getUserwishlistedItems();
          setWishlistCount(wishlistCount - 1);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else if (props.storedWishlist.length === 1) {
      const obj = {
        userId: userId,
        productId: props.i.productId,
        wishlist: 0,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_wishlist(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          props.setStoredWishlist([]);
          props.getUserwishlistedItems();
          setShowHeart(false);
          setWishlistCount(0);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  return (
    <CardWrapper>
      <CardContainer
        className="cardcon"
        whileTap={{ cursor: "grabbing" }}
        style={{ z: 10, ":hover": { cursor: "grab" } }}
        onMouseOver={() => {
          setActive(props.i.productId);
          if (active === props.i.productId) {
            setshowaddcart(true);
          }
        }}
        onMouseLeave={() => {
          setActive(-1);
          setshowaddcart(false);
        }}
      >
        <TopContainer onClick={() => handleClickOpen(props.i)}>
          {props.i.launchingSoon === "yes" && (
            <div className="ribbon-2">Launching Soon</div>
          )}
          {props.i.soldOut === "yes" && (
            <div className="ribbon-3">Sold Out</div>
          )}
          <ShoesWrapper>
            <Shoes
              style={{
                mixBlendMode: "multiply",
              }}
              whileTap={{ cursor: "grabbing" }}
            >
              <img
                style={{ mixBlendMode: "multiply" }}
                src={`${BaseImageAPI}${props.i.mainImage}`}
              />
            </Shoes>
          </ShoesWrapper>
        </TopContainer>

        {/* Snack Bar Alert */}
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={500}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            {Notification}
          </Alert>
        </Snackbar>

        {/* add Notifications */}

        <Snackbar
          open={isAddedCartSnackbarOpen}
          autoHideDuration={100}
          onClose={handleCloseAddedCartSnackbar}
        >
          <Alert onClose={handleCloseAddedCartSnackbar}>{Notification}</Alert>
        </Snackbar>

        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          mt={3}
          sx={{ textShadow: "none" }}
        >
          <Box
            sx={{
              textAlign: "left",
              px: 2,
              fontSmooth: "none",
              boxShadow: "none",
            }}
          >
            <Typography
              variant="caption"
              fontWeight={400}
              fontSize={15}
              mt={1}
              color="#150404"
            >
              {props.i.productName}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            px={2}
            py={1}
            onClick={() => handleClickOpen(props.i)}
          >
            <Box
              sx={{
                width: 400,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Rating
                name="hover-feedback"
                value={props.i.overallRating}
                precision={0.5}
                readOnly
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {props.i.productReviewCount >= 1 && (
                <Typography
                  color="#616e80"
                  variant="caption"
                  sx={{ marginTop: 0.5 }}
                >
                  ({props.i.productReviewCount})
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              gap={1}
              flexDirection="column"
              onClick={() => handleClickOpen(props.i)}
            >
              <Box display="flex" gap={1}>
                <Typography
                  color="#616e80"
                  sx={{ textDecoration: "line-through" }}
                >
                  CAD
                </Typography>
                <Typography
                  color="#616e80"
                  sx={{ textDecoration: "line-through" }}
                >
                  {props.i.productMRP}
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1}
                onClick={() => handleClickOpen(props.i)}
              >
                <Typography
                  variant="caption"
                  fontSize={16}
                  fontWeight={600}
                  color="#262626"
                >
                  CAD
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={16}
                  fontWeight={600}
                  color="#262626"
                >
                  {props.i.productPrice}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mx={2} my={2}>
            <Box>
              <button
                className="button-62"
                role="button"
                onClick={() => handleClickOpen(props.i)}
              >
                QUICK VIEW
              </button>
            </Box>
            {!include ? (
              <FavoriteIcon
                onClick={addToWishlist}
                sx={{
                  verticalAlign: "middle",
                  fontSize: 35,
                  color: "#616e80",
                  cursor: "pointer",
                }}
              />
            ) : (
              <FavoriteIcon
                onClick={removeFromWishlist}
                sx={{
                  verticalAlign: "middle",
                  fontSize: 35,
                  color: "red",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        </Box>
      </CardContainer>
    </CardWrapper>
  );
}
