import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography, Link, Rating, TextField, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import { fetch_add_product_reviews } from "../../Services/Services/ProductService";

export default function WriteReviewDialog({
  open,
  setOpen,
  PreviewData,
  getAllReviews,
}) {
  const [scroll, setScroll] = useState("body");
  const [guide, setshowGuide] = useState(false);
  const [value, setValue] = useState(1);
  const [hover, setHover] = useState(-1);

  const [review, setReview] = useState("");
  const [reviewTitle, setReviewTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [video, setVideo] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [refreshPage, setRefreshPage] = useState(false);

  const productId = PreviewData.map((i) => i.productId);

  const openGuide = () => {
    setshowGuide(!guide);
  };

  const handleClose = () => {
    setOpen(false);
    setReview("");
    setReviewTitle("");
    setRefreshPage(false);
    getAllReviews();
  };

  const userId = JSON.parse(localStorage.getItem("userId"));

  const handleSubmit = async () => {
    if (review !== "" && reviewTitle !== "") {
      const serverData = new FormData();
      serverData.append("review", review);
      serverData.append("userId", userId);
      serverData.append("rating", value);
      serverData.append("reviewTitle", reviewTitle);
      serverData.append("productId", productId);
      serverData.append("productImage", photo);
      serverData.append("productVideo", video);
      try {
        const res = await fetch_add_product_reviews(serverData);
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          getAllReviews();
        } else {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          handleClose();
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      alert("Please fill all the details");
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent dividers>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {PreviewData.map((data) => {
                return (
                  <>
                    <Typography fontWeight={600} variant="h6" color="#3A475B">
                      Please share your experience
                    </Typography>
                    <Typography variant="caption">
                      {data.productName}
                    </Typography>
                    <Typography variant="caption">
                      Your feedback will help other shoppers make good choices,
                      and we'll use it to improve our products.
                    </Typography>
                    <Box display="flex" justifyContent="space-between" gap={3}>
                      <Link onClick={openGuide}>
                        <Typography variant="caption">
                          Review guidelines
                        </Typography>
                      </Link>
                      <img
                        src={`${BaseImageAPI}${data.mainImage}`}
                        alt="img"
                        style={{ width: 80, height: 80 }}
                      />
                    </Box>
                  </>
                );
              })}
              <Box>
                {guide ? (
                  <Box textAlign="justify" padding="0 100px 0 0">
                    <Typography variant="caption">
                      We value your input and invite you to rate and review your
                      purchases. Be sure to explain why you like or dislike the
                      product and focus on the product's features and your own
                      experience using it. If you wish to comment about product
                      selection, pricing, ordering, delivery or other issues,
                      please contact our customer support.
                    </Typography>
                    <Typography variant="caption">
                      Please refrain from including any of the following in your
                      review:
                    </Typography>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <li style={{ fontSize: 12 }}>
                        Obscene or discriminatory language
                      </li>
                      <li style={{ fontSize: 12 }}>
                        Critical or inappropriate comments about other reviews
                        and shoppers
                      </li>
                      <li style={{ fontSize: 12 }}>
                        Advertising, spam, references to other websites or
                        retailers
                      </li>
                      <li style={{ fontSize: 12 }}>
                        Personal information such as email addresses, phone
                        numbers or physical addresses
                      </li>
                    </ul>
                    <Typography variant="caption">
                      All reviews are subject to our store's Terms of Use.
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              <Stack spacing={4}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  mt={2}
                >
                  <Typography variant="subtitle2">Overall Rating</Typography>
                  <Rating
                    size="large"
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle2">Review</Typography>
                  <TextField
                    onChange={(e) => setReview(e.target.value)}
                    required
                    id="review"
                    rows={4}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    multiline
                    fullWidth
                  />
                  <Typography variant="caption" fontSize={10}>
                    Make your review great: Describe what you liked, what you
                    didn’t like, and other key things shoppers should know
                    (minimum 5 characters)
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">Review Title</Typography>
                  <TextField
                    onChange={(e) => setReviewTitle(e.target.value)}
                    required
                    id="reviewTitle"
                    color="secondary"
                    variant="outlined"
                    size="small"
                    multiline
                    fullWidth
                  />
                </Box>
                <Box>
                  <Typography>Photos or Videos</Typography>
                  <Box
                    display={{ xs: "block", lg: "flex" }}
                    gap={2}
                    mt={1}
                    mb={1}
                  >
                    <Box mb={{ xs: 2 }}>
                      <Button
                        className="reviewbutton2"
                        variant="contained"
                        component="label"
                      >
                        Add Photo
                        <input
                          onChange={(e) => {
                            const selectedPhoto = e.target.files[0];
                            setPhoto(selectedPhoto);
                          }}
                          type="file"
                          accept="image/*"
                          hidden
                        />
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className="reviewbutton2"
                        variant="contained"
                        component="label"
                      >
                        Add Video
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(e) => {
                            const selectedVideo = e.target.files[0];
                            setVideo(selectedVideo);
                          }}
                          hidden
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-evenly"
                  >
                    {photo && (
                      <div>
                        <img
                          src={URL.createObjectURL(photo)}
                          alt=""
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}

                    {video && (
                      <div>
                        <video
                          style={{
                            width: 100,
                            height: 80,
                            objectFit: "contain",
                          }}
                        >
                          <source
                            src={URL.createObjectURL(video)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </Box>
                  <Typography variant="caption" fontSize={10}>
                    You may add up to five photos or videos
                  </Typography>
                </Box>
              </Stack>
              <Box mt={3}>
                <Button
                  variant="contained"
                  className="button-62"
                  fullWidth
                  onClick={handleSubmit}
                >
                  SUBMIT
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
