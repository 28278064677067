import { Box, Typography, Container, Grid, Card, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { ProductsCard } from "../ProductsCard/ProductsCard";
import FilterProducts from "./FilterProducts";
import TuneIcon from "@mui/icons-material/Tune";
import SmallScreenFilterMenu from "./SmallScreenFilterMenu";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetch_getParticularItem,
  fetch_get_user_cart,
  fetch_get_user_wishlist,
} from "../../Services/Services/ProductService";
import "./ShopPage.styles.css";

export default function AlternateShop(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [ProductDialogopen, setProductDialogopen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [PreviewData, setPreviewData] = useState([]);
  const [productQty, setProductQty] = useState(1);
  const [allCartItems, setAllCartItems] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const location = useLocation();
  const locationState = location.state || {};
  const products = locationState.products || [];
  const searchText = locationState.searchText || "";
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [Notification, setNotification] = useState("");
  const auth = localStorage.getItem("Userauth");
  const [OpenFilterSection, setOpenFilterSection] = useState(false);
  const [FilteredProductsList, setFilterProductsList] = useState([]);

  const [selectedColor, setSelectedColor] = useState(null);

  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [storedWishlist, setStoredWishlist] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem("Userauth"));
  const [HelperText, setHelperText] = useState("");
  const [availableStock, setAvailableStock] = useState(1);
  const { id } = useParams();

  // Dialog box when click QUICK View
  const QuickView = (data) => {
    setPreviewData(data);
    setProductDialogopen(true);
  };

  useEffect(() => {
    if (PreviewData.length > 0) {
      const defaultColor = Object.keys(PreviewData[0]?.productImage)[0];
      setSelectedColor(defaultColor);
      setAvailableStock(PreviewData[0]?.productImage[defaultColor][0]?.inStock);
    }
  }, [PreviewData]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const proId = JSON.parse(localStorage.getItem("getSpecificProductId")) || 1;
  const proName = JSON.parse(localStorage.getItem("getSpecificProductName"));

  useEffect(() => {
    const getParticular = async () => {
      const Item = id === "result" ? proId : id;
      const sendData = new FormData();
      sendData.append("categoryId", Item);
      sendData.append("productId", "");
      try {
        const res = await fetch_getParticularItem(sendData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setData(res.data.data);
          setStatus(true);
          setColor(true);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    getParticular();
  }, [id]);

  // GET USER CART DATA

  const getUserCartData = async () => {
    if (auth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_get_user_cart(serverData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setAllCartItems(res.data.cart);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };
  useEffect(() => {
    getUserCartData();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  const getUserwishlistedItems = async () => {
    if (userAuth) {
      const serverData = new FormData();
      serverData.append("userId", userId);
      try {
        const res = await fetch_get_user_wishlist(serverData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setStoredWishlist(res.data.wishlist);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  useEffect(() => {
    getUserwishlistedItems();
  }, [userId]);

  return (
    <Box>
      <Header />

      {/* Snack Bar Alert */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {Notification}
        </Alert>
      </Snackbar>

      {/* add Notifications */}

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseAddedCartSnackbar}
      >
        <Alert onClose={handleCloseAddedCartSnackbar}>{Notification}</Alert>
      </Snackbar>

      {/* <Divider sx={{borderColor:'silver'}}/> */}

      <Box px={1} mb={3}>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          <Container>
            <Box mt={1}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Box
                    width="100%"
                    bgcolor="#faa634"
                    textAlign="center"
                    py={{ xs: 1, md: 2, lg: 3 }}
                    className="productHead"
                  >
                    <Typography variant="h5" color="#FFFFFF" fontWeight={600}>
                      PRODUCTS
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid
              item
              xs={2}
              sm={2}
              lg={2}
              xl={2}
              md={2}
              xxl={2}
              className="filterbox"
              mt={1}
            >
              <Box
                component={Card}
                sx={{
                  boxShadow: "3px 3px 6px -3px rgba(0,0,0,0.7)",
                  borderRadius: 0,
                }}
              >
                <FilterProducts
                  setOpenFilterSection={setOpenFilterSection}
                  setFilterProductsList={setFilterProductsList}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={9} lg={10} xl={10} xxl={10} md={10} mb={2}>
              <Container className="smallscreenfilter">
                <Box mt={2}>
                  <TuneIcon onClick={handleClickMenu} />
                  {openMenu ? (
                    <SmallScreenFilterMenu
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Container>

              {searchText === "" ? (
                <>
                  {OpenFilterSection ? (
                    <Container>
                      <Box textAlign="left" ml={2} mt={2}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize={26}
                          id="filterResult"
                        >
                          Results
                        </Typography>
                        <Typography variant="caption">
                          Products (
                          {FilteredProductsList && FilteredProductsList.length})
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container columnSpacing={1} mt={1} rowSpacing={3}>
                          {FilteredProductsList.map((product) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              xl={4}
                              xxl={4}
                              ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                              position="relative"
                            >
                              <ProductsCard
                                getUserwishlistedItems={getUserwishlistedItems}
                                setStoredWishlist={setStoredWishlist}
                                storedWishlist={storedWishlist}
                                i={product}
                                QuickView={QuickView}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Container>
                  ) : (
                    <Container>
                      <Box textAlign="left" ml={2} mt={2}>
                        <Typography
                          id={
                            proName !== "" &&
                            proName.replace(/\s+/g, "").toLowerCase()
                          }
                          variant="h6"
                          fontWeight={300}
                          fontSize={26}
                        >
                          {proName}
                        </Typography>
                      </Box>
                      <Box>
                        <Grid container columnSpacing={1} mt={1} rowSpacing={3}>
                          {data.map((i, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                xl={4}
                                ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                                position="relative"
                                key={index}
                              >
                                <ProductsCard
                                  getUserwishlistedItems={
                                    getUserwishlistedItems
                                  }
                                  setStoredWishlist={setStoredWishlist}
                                  storedWishlist={storedWishlist}
                                  i={i}
                                  QuickView={QuickView}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Container>
                  )}
                </>
              ) : (
                <Container>
                  {searchText !== "" && (
                    <>
                      <Box
                        py={3}
                        justifyContent="start"
                        display="flex"
                        textAlign="left"
                        flexDirection="column"
                      >
                        <Typography variant="h6">Best results for</Typography>
                        <Typography
                          variant="h4"
                          color="GrayText"
                          fontWeight={700}
                        >
                          " {searchText} "
                        </Typography>
                      </Box>
                      <Divider sx={{ borderColor: "silver" }} />
                    </>
                  )}

                  {Object.keys(products).length !== 0 ? (
                    Object.entries(products).map(([category, productss]) => {
                      return (
                        <>
                          <Box textAlign="left" ml={2} mt={2}>
                            <Typography
                              variant="h6"
                              fontWeight={300}
                              fontSize={26}
                            >
                              {category}
                            </Typography>
                          </Box>
                          <Box>
                            <Grid
                              container
                              columnSpacing={1}
                              mt={1}
                              rowSpacing={3}
                            >
                              {productss.map((product) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={4}
                                  xl={4}
                                  ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                                  position="relative"
                                >
                                  <ProductsCard
                                    getUserwishlistedItems={
                                      getUserwishlistedItems
                                    }
                                    setStoredWishlist={setStoredWishlist}
                                    storedWishlist={storedWishlist}
                                    i={product}
                                    QuickView={QuickView}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                          <Divider
                            sx={{ marginTop: 2, borderColor: "silver" }}
                          />
                        </>
                      );
                    })
                  ) : (
                    <>
                      <Box
                        py={3}
                        justifyContent="center"
                        display="flex"
                        textAlign="center"
                        flexDirection="column"
                      >
                        <Typography variant="subtitle1" fontWeight={600}>
                          Sorry, we can't find any results for "{searchText}"
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          fontWeight={500}
                          color="#616e80"
                        >
                          {proId === "" || proId === null
                            ? "Please search again, or browse our trending products"
                            : "Please search again, or browse your last viewed product categories"}
                        </Typography>
                      </Box>

                      <Container>
                        <Box textAlign="left" ml={2} mt={2}>
                          <Typography
                            variant="h6"
                            fontWeight={300}
                            fontSize={26}
                          >
                            {proName}
                          </Typography>
                        </Box>
                        <Box>
                          <Grid
                            container
                            columnSpacing={1}
                            mt={1}
                            rowSpacing={3}
                          >
                            {data.map((i, index) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={4}
                                  xl={4}
                                  ml={{ lg: 0, md: 0, xs: 1.5, sm: 1 }}
                                  position="relative"
                                  key={index}
                                >
                                  <ProductsCard
                                    getUserwishlistedItems={
                                      getUserwishlistedItems
                                    }
                                    setStoredWishlist={setStoredWishlist}
                                    storedWishlist={storedWishlist}
                                    i={i}
                                    QuickView={QuickView}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Container>
                    </>
                  )}
                </Container>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
