import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function FAQ() {
  const navigate = useNavigate();

  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [panel5, setPanel5] = useState(false);
  const [panel6, setPanel6] = useState(false);
  const [panel7, setPanel7] = useState(false);
  const [panel8, setPanel8] = useState(false);

  return (
    <Box py={3}>
      {/* How do I place an order on Shopztrend.com? */}
      <Accordion
        expanded={panel1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            onClick={() => setPanel1(!panel1)}
            color="#000"
            fontWeight={600}
            variant="subtitle1"
          >
            {panel1 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            How do I place an order on Shopztrend.com?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  To place an order, simply browse our website, select the items
                  you want, add them to your cart, and proceed to checkout.
                  Follow the prompts to provide shipping and payment information
                  to complete your purchase.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* What payment methods do you accept? */}
      <Accordion
        expanded={panel2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            onClick={() => setPanel2(!panel2)}
            color="#000"
            fontWeight={600}
            variant="subtitle1"
          >
            {panel2 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            What payment methods do you accept?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  We accept major credit cards, including Visa, MasterCard,
                  American Express, and Discover. Additionally, we offer secure
                  payment through Check and Interac E-Transfer for your
                  convenience.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* How can I track my order? */}
      <Accordion
        expanded={panel3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            color="#000"
            onClick={() => setPanel3(!panel3)}
            fontWeight={600}
            variant="subtitle1"
          >
            {panel3 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}How
            can I track my order?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  To track your order, log in to your Shopztrend account and
                  navigate to the "Orders" section in Account Page. There,
                  you'll find real-time updates on your order's status,
                  including tracking information once it's available.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*  What is your return policy? */}
      <Accordion
        expanded={panel4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            color="#000"
            onClick={() => setPanel4(!panel4)}
            fontWeight={600}
            variant="subtitle1"
          >
            {panel4 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            What is your return policy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  We have a hassle-free return policy. If you're not satisfied
                  with your purchase, you can return eligible items within 30
                  days of receiving them. Please review our{" "}
                  <Typography
                    onclick={() => navigate("/")}
                    sx={{ fontWeight: "bold" }}
                  >
                    Returns & Refunds
                  </Typography>{" "}
                  page for detailed instructions.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*   What should I do if I received a damaged or incorrect item? */}
      <Accordion
        expanded={panel5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            color="#000"
            onClick={() => setPanel5(!panel5)}
            fontWeight={600}
            variant="subtitle1"
          >
            {panel5 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            What should I do if I received a damaged or incorrect item?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography sx={{ cursor: "pointer" }}>
                  We apologize for any inconvenience. Please contact Shopz Care
                  immediately through our email{" "}
                  <a
                    href="mailto:clientcare@shopztrend.com"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    clientcare@shopztrend.com
                  </a>
                  , or phone (+1(306)590-0000) with your order details, and we
                  will resolve the issue promptly.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*   How do I reset my password if I forget it? */}
      <Accordion
        expanded={panel6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            onClick={() => setPanel6(!panel6)}
            color="#000"
            fontWeight={600}
            variant="subtitle1"
          >
            {panel6 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            How do I reset my password ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  If you forget your password, navigate to the "Account Details"
                  section in Account Page. You will be prompted to enter your
                  registered email address. Enter your new password, confirm it,
                  and save the changes. If you encounter any issues, feel free
                  to contact Shopz Care for assistance.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Can I change or cancel my order after it's been placed? */}
      <Accordion
        expanded={panel7}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            color="#000"
            onClick={() => setPanel7(!panel7)}
            fontWeight={600}
            variant="subtitle1"
          >
            {panel7 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            Can I change or cancel my order after it's been placed?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography>
                  We strive to process orders quickly, but if you need to make
                  changes or cancel your order, please contact Shopz Care as
                  soon as possible. We will do our best to accommodate your
                  request, depending on the order's status.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* How can I contact Shopz Care for further assistance? */}
      <Accordion
        expanded={panel8}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          boxShadow: "none",
          border: "none",
        }}
        disableGutters
      >
        <AccordionSummary
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ borderBottom: "none" }}
        >
          <Typography
            color="#000"
            fontWeight={600}
            onClick={() => setPanel8(!panel8)}
            variant="subtitle1"
          >
            {panel8 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}{" "}
            How can I contact Shopz Care for further assistance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box textAlign="left">
            <ul>
              <Stack spacing={1} mt={1}>
                <Typography sx={{ cursor: "pointer" }}>
                  You can reach Shopz Care through our live chat, email{" "}
                  <a
                    href="mailto:clientcare@shopztrend.com"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    clientcare@shopztrend.com
                  </a>
                  , or phone (+1(306)590-0000). Our customer support team is
                  available during our business hours, as mentioned on the
                  "Business Hours" Section.
                </Typography>
              </Stack>
            </ul>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
