import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Typography, Card, CardMedia, CardContent, Link} from '@mui/material';
import SwiperCore, { Autoplay } from "swiper";
import { useNavigate } from 'react-router-dom';
SwiperCore.use([Autoplay]);

export default function HomeCards({section , name}) {

    const [my_swiper, set_my_swiper] = React.useState({});
    const settings = {
      speed: 4000,
      loop:true,
      autoplayDisableOnInteraction:false,
    };
    const navigate = useNavigate();

    // Smooth Sliding to Next Route 
    const handleClickOpenProductCategory = (category) => {
    const slideCategoryId = category.replace(/\s+/g, '').toLowerCase();
    navigate(`/shop#${slideCategoryId}`);
    };


    const handleOpenSpecificBrands = (id) => {
    navigate(`/launchingsoon/${id}/#BrandsHead`);
    }

    const handleGotoTrends = () =>{
       navigate('/trending');
    }

    const moveToPage = (item , i) => {
    switch (item) {
    case 16:
    handleClickOpenProductCategory(i.category);
    break;
    case 13:
    handleOpenSpecificBrands(i.title);
    break;
    case 15:
    handleGotoTrends();
    break;
    default:
    break;
    }
    };
    

  return (
    <Swiper
    {...settings}
    onInit={(ev) => {
      set_my_swiper(ev);
      console.log(my_swiper)
    }}
    spaceBetween={180}
    autoplay={{
      delay: 8000,
      disableOnInteraction: false,// Adjust this value to control the time interval (in milliseconds)
      pauseOnMouseEnter: true,
    }}
    breakpoints={{
      // when window width is >= 640px
      480: {
        width: 300,
        slidesPerView: 1,
        spaceBetween: 10,
      },
      600: {
        width: 550,
        slidesPerView: 2,
        spaceBetween: 10,
      },
      // when window width is >= 768px
      768: {
        width: 750,
        slidesPerView: 2,
        spaceBetween: 50
      },
      1024: {
        slidesPerView: 3,
        width: 1024,
        autoplay: {
          delay: 4000,
        },
      },
    }}
  >

    {
      section && section?.cards?.map((i , index) => {
        return (
          <SwiperSlide key={index}>
            <Card id='SwiperCard' 
           onClick={()=>moveToPage(section?.headingId , i)}
            key={index} 
            sx={{ cursor:'pointer', width: 385, marginBottom: 2,  textAlign: 'left', ":hover": { boxShadow: '0px 0px 4px 3px silver' } }}>
                <CardMedia
                  sx={{ height: 250, width: 385, objectFit: 'contain', }}
                  image={i.images}
                  title={i.title}
                />
                <CardContent>
                  <Typography variant="h4" fontWeight={600} fontSize={18} mt={1}>
                    {i.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mt={1}>
                    {i.content}
                  </Typography>
                  <Link     
                           onClick={()=>moveToPage(section?.headingId , i)} ><Typography sx={{ cursor: 'pointer' }} mt={1} fontWeight={500} color='#0c509f' fontSize={15}>{ section?.headingId === 12 ? 'Arriving Soon' :  'Shop Now'}</Typography></Link>
                </CardContent>
              </Card>
          </SwiperSlide>
        )
      })
    }
  </Swiper>
  )
}
