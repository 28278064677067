import React, { useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";

// this is pages
import MainPage from "./Pages/HomePage/MainPage";
import TrendingPage from "./Pages/Trending/TrendingPage.jsx";
import SalePage from "./Pages/Sale/SalePage.jsx";
import TrolleyPage from "./Pages/Trolley/TrolleyPage.jsx";
import WishlistPage from "./Pages/Wishlist/WishlistPage.jsx";
import AccountPage from "./Pages/Account/AccountPage.jsx";
import Pagenotfound from "./Pages/PageNotFound/Pagenotfound.jsx";
import RegisterForm from "./Comps/RegistrationFormComps/RegisterForm.jsx";
import RegistrationConfirmation from "./Comps/RegistrationFormComps/RegistrationConfirmation.jsx";
import ShopPage from "./Pages/Shop/ShopPage";
import ReviewPage from "./Pages/ReviewPage/ReviewPage.jsx";

import Checkout from "./Pages/Checkout/Checkout";
import AlternateShop from "./Pages/Shop/AlternateShop";
import ForgotPassword from "./Comps/RegistrationFormComps/ForgotPassword";
import ReturnsAndExchage from "./Pages/FooterLinksPages/ReturnsAndExchage";
import ContactUs from "./Pages/FooterLinksPages/ContactUs";
import Store from "./Pages/FooterLinksPages/Store";
import About from "./Pages/FooterLinksPages/About";
import Inspiration from "./Pages/FooterLinksPages/Inspiration";
import ShippingPolicy from "./Pages/FooterLinksPages/ShippingPoilcy";
import TrackYourOrder from "./Pages/FooterLinksPages/TrackYourOrder";
import CookiesPolicy from "./Pages/FooterLinksPages/CookiesPolicy";
import PayByCheque from "./Pages/FooterLinksPages/PayByCheque";
import PayBy_E_Transfer from "./Pages/FooterLinksPages/PayBy_E_Transfer";
import DebitCardInfo from "./Pages/FooterLinksPages/DebitCardInfo";
import CreditCardInfo from "./Pages/FooterLinksPages/CreditCardInfo";
import FullCheckoutPage from "./Pages/Checkout/FullCheckoutPage";
import NotForSale from "./Pages/Shop/NotForSale";
import ShopzCare from "./Pages/FooterLinksPages/ShopzCare";
import PrivacyPolicy from "./Pages/FooterLinksPages/PrivacyPolicy";
import TermsandConditions from "./Pages/FooterLinksPages/TermsandConditions";
import ViewOrder from "./Pages/Account/ViewOrder";
import QuickViewScreen from "./Pages/Shop/QuickViewScreen.jsx";

//this variable is for local development
export const startUrl = `/`;

function Routes() {
  const [data, setData] = useState([]);

  const sendReview = (i) => {
    setData(i);
  };

  return useRoutes([
    {
      path: `*`,
      element: <Pagenotfound />,
    },
    { path: startUrl, element: <MainPage /> },
    { path: `trending`, element: <SalePage /> },
    { path: `sale`, element: <TrendingPage /> },
    { path: `shopfilter/:id`, element: <AlternateShop /> },
    { path: `shop`, element: <ShopPage /> },
    { path: "view/:productId/:productName", element: <QuickViewScreen /> },
    { path: `account`, element: <AccountPage /> },
    { path: `trolley`, element: <TrolleyPage /> },
    { path: `wishlist`, element: <WishlistPage /> },
    { path: `account/register`, element: <RegisterForm /> },
    {
      path: `account/register/registrationconfirmation`,
      element: <RegistrationConfirmation />,
    },
    { path: `privacypolicy`, element: <PrivacyPolicy /> },
    { path: `checkoutpage`, element: <Checkout /> },
    { path: `fullcheckoutpage`, element: <FullCheckoutPage /> },
    { path: `returnexchange`, element: <ReturnsAndExchage /> },
    { path: `contactus`, element: <ContactUs /> },
    { path: `about`, element: <About /> },
    { path: `inspiration`, element: <Inspiration /> },
    { path: `privacypolicy`, element: <PrivacyPolicy /> },
    { path: `shippingPolicy`, element: <ShippingPolicy /> },
    { path: `termsandconditions`, element: <TermsandConditions /> },
    { path: `pay_cheque_info`, element: <PayByCheque /> },
    { path: `e_transfer_info`, element: <PayBy_E_Transfer /> },
    { path: `payment_info_debit`, element: <DebitCardInfo /> },
    { path: `payment_info_credit`, element: <CreditCardInfo /> },
    { path: `shopz_care`, element: <ShopzCare /> },
    { path: `ordertracking`, element: <TrackYourOrder /> },
    { path: `store`, element: <Store /> },
    { path: `cookiespolicy`, element: <CookiesPolicy /> },
    { path: `logout`, element: <Navigate to={startUrl} /> },
    { path: `reviews`, element: <ReviewPage /> },
    { path: `vieworder`, element: <ViewOrder /> },
    { path: `account/resetPassword`, element: <ForgotPassword /> },
    { path: `launchingsoon/:id`, element: <NotForSale /> },
  ]);
}

export default Routes;
