import { Box, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "../../Assets/FooterCards/icons8-facebook-96.png";
import TwitterIcon from "../../Assets/FooterCards/icons8-twitter-96.png";
import YouTubeIcon from "../../Assets/FooterCards/icons8-youtube-96.png";
import LinkedInIcon from "../../Assets/FooterCards/icons8-linkedin-96.png";
import InstagramIcon from "../../Assets/FooterCards/icons8-insta-96.png";
import "./Footer.styles.css";
import Card1 from "../../Assets/FooterCards/footer-visa.webp";
import Card2 from "../../Assets/FooterCards/footer-mastercard.webp";
import Card3 from "../../Assets/FooterCards/footer-maestro.webp";
import PlayStore from "../../Assets/FooterCards/app-google-prompt.webp";
import AppStore from "../../Assets/FooterCards/app-apple-prompt.webp";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box bgcolor="#f5f5f5">
      {/* Footer Layer 1 */}
      <Box padding={2} borderBottom="1px solid silver">
        <Grid container mb={2} mt={1} columnSpacing={5} rowSpacing={3}>
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="initial"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Delivery & Returns
              </Typography>
              <li>
                <Link href="/account" underline="none" color="#262626">
                  <Typography fontSize={16}>Account</Typography>
                </Link>
              </li>
              <li>
                <Link href="/returnexchange" underline="none" color="#262626">
                  <Typography fontSize={16}>Returns & Refunds</Typography>
                </Link>
              </li>
              <li>
                <Link href="/shippingpolicy" underline="none" color="#262626">
                  <Typography fontSize={16}>Shipping Policy</Typography>
                </Link>
              </li>
              <li>
                <Link href="/privacypolicy" underline="none" color="#262626">
                  <Typography fontSize={16}>Privacy Policy</Typography>
                </Link>
              </li>
              <li>
                <Link href="/ordertracking" underline="none" color="#262626">
                  <Typography fontSize={16}>Track Your Order</Typography>
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="initial"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Information
              </Typography>
              <li>
                <Link href="/about" underline="none" color="#262626">
                  <Typography fontSize={16}>About us</Typography>
                </Link>
              </li>
              <li>
                <Link href="/inspiration" underline="none" color="#262626">
                  <Typography fontSize={16}>Inspiration</Typography>
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    navigate(`/contactus#CHeader`, { state: { bulk: "0" } })
                  }
                  underline="none"
                  color="#262626"
                >
                  <Typography fontSize={16}>Contact us</Typography>
                </Link>
              </li>
              <li>
                <Link href="/store" underline="none" color="#262626">
                  <Typography fontSize={16}>Store Associates</Typography>
                </Link>
              </li>
              <li>
                <Link
                  href="/termsandconditions"
                  underline="none"
                  color="#262626"
                >
                  <Typography fontSize={16}>Terms & Conditions</Typography>
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="initial"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Payment & services
              </Typography>
              <li>
                <Link
                  href="/payment_info_credit"
                  underline="none"
                  color="#262626"
                >
                  <Typography fontSize={16}>Credit Card</Typography>
                </Link>
              </li>
              <li>
                <Link
                  href="/payment_info_debit"
                  underline="none"
                  color="#262626"
                >
                  <Typography fontSize={16}>Debit Card</Typography>
                </Link>
              </li>
              <li>
                <Link href="/pay_cheque_info" underline="none" color="#262626">
                  <Typography fontSize={16}>Pay by Checks </Typography>
                </Link>
              </li>
              <li>
                <Link href="/e_transfer_info" underline="none" color="#262626">
                  <Typography fontSize={16}>Pay by E-Transfer </Typography>
                </Link>
              </li>
              <li>
                <Link href="/shopz_care" underline="none" color="#262626">
                  <Typography fontSize={16}> Shopztrend Care</Typography>
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      {/* Footer Layer 2 */}
      <Box mt={2} alignContent="center" py={3} borderBottom="1px solid silver">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography mb={3} fontWeight="bold">
              Be first to know the latest deals
            </Typography>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.youtube.com/channel/UCAsp_pwgCB0yjlu8joqMpZA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={YouTubeIcon}
                alt="YT"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.facebook.com/Shopztrend"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookIcon}
                alt="FB"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://twitter.com/shopztrend"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={TwitterIcon}
                alt="tw"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.linkedin.com/company/shopztrend-enterprises-inc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={LinkedInIcon}
                alt="ln"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.instagram.com/shopztrend/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="in"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} padding={1}>
            <Typography fontWeight="bold">Payment methods</Typography>
            <Typography variant="subitlte1">
              We accept the following payment methods online
            </Typography>
            <Box mt={2}>
              <img src={Card1} style={{ marginRight: 10 }} alt="img"></img>
              <img src={Card2} style={{ marginRight: 10 }} alt="img"></img>
              <img src={Card3} style={{ marginRight: 10 }} alt="img"></img>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography mb={3} fontWeight="bold">
              Download our app
            </Typography>
            <img src={PlayStore} style={{ marginRight: 10 }} alt="img"></img>
            <img src={AppStore} style={{ marginRight: 10 }} alt="img"></img>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
