import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appendData } from "../../Variables/ProcessVariable";
import { useLocation } from "react-router-dom";
import { fetch_add_contact_us } from "../../Services/Services/CommonService";

export default function ContactUs() {
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    country: "",
    city: "",
    comments: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setContactForm({ ...contactForm, [name]: value });
  }

  const location = useLocation();
  const bulk = location.state && location.state.bulk;

  const isBulk = bulk === "0" ? 0 : 1;

  console.log(isBulk);

  async function sendForm(e) {
    e.preventDefault();
    const obj = {
      isBulkOrder: isBulk,
      customerName: contactForm.name,
      customerMail: contactForm.email,
      customerMobile: contactForm.phone,
      customerCompany: contactForm.company,
      customerCountry: contactForm.country,
      customerCity: contactForm.city,
      comments: contactForm.comments,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_contact_us(lData);
      if (!res.data.error) {
        successMsg("Your request has been sent successfully !");
        setContactForm({
          name: "",
          email: "",
          phone: "",
          company: "",
          country: "",
          city: "",
          comments: "",
        });
      } else {
        ErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  }

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  return (
    <Box>
      <Header />
      {/* <Divider sx={{borderColor:'silver'}}/> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box
                id="CHeader"
                width="100%"
                bgcolor="#faa634"
                textAlign="center"
                py={3}
              >
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Contact Us
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box mt={10}>
        <Grid
          container
          spacing={1}
          display="flex"
          justifyContent="center"
          alignContent="start"
          alignItems="start"
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box px={1}>
              <Stack
                component="form"
                sx={{
                  marginBottom: 5,
                }}
                spacing={2}
                noValidate
                autoComplete="off"
                textAlign="left"
                marginLeft={5}
                onSubmit={sendForm}
                id="contactForm"
              >
                <Typography color="#616e80" fontWeight={600}>
                  Name
                </Typography>

                <TextField
                  htmlFor="Name"
                  size="small"
                  id="name"
                  name="name"
                  fullWidth
                  value={contactForm.name}
                  onChange={handleChange}
                  sx={{ ":focus": { borderColor: "#7EC948", border: "none" } }}
                />
                <Typography color="#616e80" fontWeight={600}>
                  Email
                </Typography>

                <TextField
                  size="small"
                  id="email"
                  name="email"
                  type="email"
                  fullWidth
                  value={contactForm.email}
                  onChange={handleChange}
                />
                <Typography color="#616e80" fontWeight={600}>
                  Phone <Typography variant="caption">(optional)</Typography>
                </Typography>

                <TextField
                  size="small"
                  id="phone"
                  name="phone"
                  fullWidth
                  value={contactForm.phone}
                  onChange={handleChange}
                />
                <Typography color="#616e80" fontWeight={600}>
                  Company
                </Typography>

                <TextField
                  size="small"
                  id="company"
                  name="company"
                  fullWidth
                  value={contactForm.company}
                  onChange={handleChange}
                />
                <Typography color="#616e80" fontWeight={600}>
                  Country
                </Typography>

                <TextField
                  size="small"
                  id="country"
                  name="country"
                  fullWidth
                  value={contactForm.country}
                  onChange={handleChange}
                />
                <Typography color="#616e80" fontWeight={600}>
                  City
                </Typography>

                <TextField
                  size="small"
                  id="city"
                  name="city"
                  fullWidth
                  value={contactForm.city}
                  onChange={handleChange}
                />

                <Typography color="#616e80" fontWeight={600}>
                  Comments
                </Typography>
                <TextField
                  multiline
                  size="small"
                  id="comments"
                  name="comments"
                  fullWidth
                  rows={3}
                  value={contactForm.comments}
                  onChange={handleChange}
                />

                <Box mt={1}>
                  <Button
                    className="button-62"
                    fullWidth
                    type="submit"
                    form="contactForm"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            display="flex"
            justifyContent="center"
          >
            {/* <iframe width="520" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=138,%20Delayen%20Crescent,%20%20Saskatoon,%20Saskatchewan+(Shopztrend%20Enterprises%20Inc.%20)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
            <Box bgcolor="#f0f0f0" textAlign="left" p={8} mt={2}>
              <Stack spacing={1}>
                <Typography color="#faa635" variant="h5" fontWeight={500}>
                  OUR OFFICE
                </Typography>
                <Typography color="#faa634" variant="h6" fontWeight={500}>
                  Registered Office
                </Typography>
                <Typography>138, Delayen Crescent</Typography>
                <Typography>Saskatoon, Saskatchewan , S7N 2V6.</Typography>
                <Typography>Tel : +1(306)590-0000</Typography>
                <Typography sx={{ cursor: "pointer" }}>
                  Mail :
                  <a
                    href="mailto:sales@shopztrend.com"
                    style={{
                      fontWeight: 400,
                      textDecoration: "none",
                      color: "#faa635",
                    }}
                  >
                    {" "}
                    sales@shopztrend.com
                  </a>
                </Typography>
                <a
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://maps.google.com/maps?q=138,%20Delayen%20Crescent,%20%20Saskatoon,%20Saskatchewan"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Google Maps
                </a>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
