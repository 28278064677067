import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Stack,
  Card,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../../Comps/Header/Header";
import "./TrolleyPage.styles.css";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { appendData } from "../../Variables/ProcessVariable";
import { ProductsContext } from "../../Context/CountContext";
import {
  fetch_add_to_cart,
  fetch_add_to_wishlist,
  fetch_get_user_cart,
  fetch_get_user_wishlist,
} from "../../Services/Services/ProductService";
import { fetch_getuser } from "../../Services/Services/CommonService";

export default function TrolleyPage() {
  const auth = JSON.parse(localStorage.getItem("Userauth"));
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [userData, setUserData] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [productQty, setProductQty] = useState(null);
  const [storedWishlist, setStoredWishlist] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const [emptyCart, setEmptyCart] = useState(true);
  const navigate = useNavigate();
  const [CartItemCount, setCartItemCount] = useState(0);
  const {
    setCartCount,
    CartCount,
    wishlistCount,
    setWishlistCount,
    setRefresh,
  } = useContext(ProductsContext);
  const [totalCartItems, setTotalCartItems] = useState([]);
  const [TotalSaving, setTotalSaving] = useState(null);

  const goToCheckout = () => {
    navigate("/fullcheckoutpage");
  };

  // GET USER CART DATA
  const getUserCartData = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_user_cart(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setCartItems(res.data.cart[0]);
        setSubTotal(res.data.cart[1].totalCartValue);
        setTotalCartItems(res.data.cart[0]);
        setCartItemCount(res.data.cart[2].totalCartQty);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
        setEmptyCart(true);
        setCartItemCount(0);
        setTotalCartItems([]);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserCartData();
  }, [userId]);

  // USER DATA
  const getUserDatatodisplay = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_getuser(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setUserData(res.data.data);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserDatatodisplay();
  }, [userId]);

  const handleClickOpenAddressEdit = () => {
    navigate("/account#accountNameSection");
    setTimeout(() => {
      const element = document.getElementById("accountNameSection");
      localStorage.setItem("accountBoxTitle", "Account Details");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleClickOpenNameEdit = () => {
    navigate("/account#accountNameSection");
    setTimeout(() => {
      const element = document.getElementById("accountNameSection");
      localStorage.setItem("accountBoxTitle", "Account Details");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  // Adding Product From Pop_up
  const addProductToCart = async (id, color) => {
    setProductQty(productQty + 1);
    const obj = {
      userId: userId,
      productId: id,
      qty: 1,
      cart: 1,
      productColor: color,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_to_cart(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        getUserCartData();
        setCartCount(CartCount + 1);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  // REMOVE PRODUCT
  const removeProductFromCart = async (id, color) => {
    setProductQty(productQty - 1);
    if (CartItemCount > 1) {
      const obj = {
        userId: userId,
        productId: id,
        qty: 1,
        cart: 0,
        productColor: color,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_cart(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          getUserCartData();
          setCartCount(CartCount - 1);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      const obj = {
        userId: userId,
        productId: id,
        qty: "",
        cart: 0,
        productColor: color,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_cart(lData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          getUserCartData();
          setCartItems([]);
          setTotalCartItems([]);
          setCartCount(0);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  // ClEAR PRODUCT
  const ClearProductFromCart = async (id, color) => {
    const obj = {
      userId: userId,
      productId: id,
      qty: "",
      cart: 0,
      productColor: color,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_to_cart(lData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        getUserCartData();
        setRefresh(true);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const getUserwishlistedItems = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_user_wishlist(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStoredWishlist(res.data.wishlist);
        setStatus(true);
        setColor(true);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getUserwishlistedItems();
  }, [userId]);

  // ADD TO WISH
  const addToWishlist = async (id, color) => {
    const obj = {
      userId: userId,
      productId: id,
      wishlist: 1,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_to_wishlist(lData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      } else {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        getUserwishlistedItems();
        setWishlistCount(wishlistCount + 1);
        ClearProductFromCart(id, color);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  const getTotalSavings = () => {
    // Calculate the total product price for each product and sum them up
    const totalProductPrice = cartItems?.reduce((total, product) => {
      const productMRP = parseFloat(product.productMRP);
      const orderedQty = parseFloat(product.orderedQty);
      return total + productMRP * orderedQty;
    }, 0);

    // Compare the total product price with the subTotal and update totalSaving accordingly
    if (totalProductPrice > subTotal) {
      setTotalSaving((totalProductPrice - subTotal).toFixed(2));
    } else {
      setTotalSaving(null);
    }
  };

  useEffect(() => {
    getTotalSavings();
  }, [cartItems, subTotal]);

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "1px solid rgba(0,0,0,.3" }} />

      {!auth ? (
        <Box paddingBottom={15}>
          <Container>
            <Grid container display="flex" justifyContent="center">
              <Grid item xs={12} sm={12} lg={12} xl={10}>
                <Box textAlign="left" mt={3} mb={5} id="trolleycon">
                  <Typography color="#262626" fontSize={30} fontWeight={500}>
                    My Cart
                  </Typography>
                  <Typography sx={{ marginTop: 1 }}>
                    <LockOpenIcon sx={{ verticalAlign: "middle" }} /> Shopztrend
                    is secure and your personal details are protected
                  </Typography>
                </Box>
                <Box border={1} borderColor="silver" textAlign="center">
                  <Box py={5}>
                    <Typography color="#262626" fontSize={30} fontWeight={500}>
                      <AddShoppingCartIcon
                        fontSize="large"
                        sx={{ verticalAlign: "middle", marginRight: 2 }}
                      />
                      Empty Cart!
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={200}
                      lineHeight={2}
                      sx={{ marginTop: 2 }}
                    >
                      <Link to="/account">Sign in</Link> to retrieve your items
                      from your cart and wishlist.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => navigate("/shop")}
                      sx={{
                        width: { xs: 200, sm: 250, md: 500, lg: 500 },
                        fontWeight: 600,
                        boxShadow: "none",
                        marginTop: 2,
                        bgcolor: "#faa634",
                      }}
                    >
                      START SHOPPING
                    </Button>
                    <Box mt={3}>
                      <Link to="/account">
                        <Typography fontWeight={600}>
                          Don't have an account? Register now
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <Box p={3} bgcolor="#fbfbfb" position="relative" height="100%">
          {/* Cart Display */}
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} sm={12} lg={8} md={8} xl={8}>
              <Container>
                <Box>
                  <Container>
                    <Box mb={3}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                            lg: "row",
                          },
                        }}
                      >
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Box>
                            <Box textAlign="left" id="trolleycon">
                              <Typography
                                color="#3b3b3d"
                                fontSize={33}
                                fontWeight={500}
                              >
                                My Cart
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Box
                            width={{ xs: 300, sm: 450, md: 450, lg: 450 }}
                            py={2}
                            px={2}
                            component={Card}
                            display="flex"
                            justifyContent="space-between"
                            gap={2}
                            alignItems="start"
                            margin="auto"
                          >
                            <Stack spacing={2} width="50%">
                              <Typography
                                variant="caption"
                                color="#9a9a9a"
                                textAlign="left"
                              >
                                Shipping to :
                              </Typography>
                              <Typography
                                variant="caption"
                                color="#262626"
                                fontWeight={400}
                                textAlign="left"
                                sx={{ wordBreak: "break-word" }}
                              >
                                {userData.address}, {userData.city},{" "}
                                {userData.state}, {userData.province},
                                {userData.pincode}, {userData.country}
                                <EditIcon
                                  onClick={handleClickOpenAddressEdit}
                                  fontSize="normal"
                                  sx={{
                                    verticalAlign: "middle",
                                    marginLeft: 1,
                                  }}
                                />
                              </Typography>
                            </Stack>
                            <Stack spacing={2} justifyContent="end">
                              <Typography
                                variant="caption"
                                color="#9a9a9a"
                                textAlign="left"
                              >
                                Customer Name
                              </Typography>
                              <Typography
                                variant="caption"
                                color="#262626"
                                fontWeight={400}
                                textAlign="left"
                              >
                                {" "}
                                {userData.first_name} {userData.last_name}
                                <EditIcon
                                  onClick={handleClickOpenNameEdit}
                                  fontSize="normal"
                                  sx={{
                                    verticalAlign: "middle",
                                    marginLeft: 1,
                                  }}
                                />
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>

                  <Container>
                    <Box textAlign="left" py={2}>
                      <Typography
                        color="#3b3b3d"
                        fontSize={22}
                        fontWeight={400}
                      >
                        Ready For Checkout
                      </Typography>
                    </Box>
                  </Container>

                  {CartItemCount !== 0 && totalCartItems.length !== 0 ? (
                    <Box
                      component={Card}
                      sx={{
                        boxShadow: "3px 3px 6px -3px rgba(0,0,0,0.7)",
                        borderRadius: 0,
                      }}
                      mb={3}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {/* Products Details */}
                          <Box p={4}>
                            {cartItems.map((i, index) => {
                              return (
                                i.orderedQty > 0 && (
                                  <Box p={{ lg: 2 }}>
                                    <Grid
                                      container
                                      spacing={3}
                                      className="cardgrid"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        lg={5}
                                        xl={5}
                                      >
                                        <Box className="productImgandName">
                                          {i.productImage[
                                            i.orderedColor
                                          ]?.[0] && (
                                            <img
                                              style={{
                                                mixBlendMode: "multiply",
                                                objectFit: "contain",
                                                width: 100,
                                                height: 100,
                                              }}
                                              src={`${BaseImageAPI}${
                                                i.productImage[
                                                  i.orderedColor
                                                ][0].productImage
                                              }`}
                                              alt={`Product ${i.productName} - ${i.orderedColor}`}
                                            />
                                          )}
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                          >
                                            <Typography
                                              variant="caption"
                                              textAlign="left"
                                            >
                                              {i.productName}
                                            </Typography>
                                            <Typography
                                              variant="caption"
                                              textAlign="left"
                                              mt={1}
                                              sx={{ color: "#616e80" }}
                                            >
                                              Color: {i.orderedColor}
                                            </Typography>
                                            <Typography
                                              variant="caption"
                                              textAlign="left"
                                              mt={1}
                                              sx={{ color: "red" }}
                                            >
                                              {i.productDiscountPercent}% OFF
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        lg={5}
                                        xl={5}
                                      >
                                        <Box px={2} className="qtybox">
                                          <Stack spacing={4}>
                                            <Box>
                                              <TextField
                                                size="small"
                                                sx={{ width: 120 }}
                                                key={i.productId}
                                                value={i.orderedQty}
                                                InputProps={{
                                                  endAdornment: (
                                                    <>
                                                      <InputAdornment position="end">
                                                        <IconButton
                                                          onClick={() =>
                                                            addProductToCart(
                                                              i.productId,
                                                              i.orderedColor
                                                            )
                                                          }
                                                        >
                                                          <AddIcon
                                                            sx={{
                                                              verticalAlign:
                                                                "middle",
                                                              fontSize: 15,
                                                            }}
                                                          />
                                                        </IconButton>
                                                        <IconButton
                                                          onClick={() =>
                                                            removeProductFromCart(
                                                              i.productId,
                                                              i.orderedColor
                                                            )
                                                          }
                                                        >
                                                          <RemoveIcon
                                                            sx={{
                                                              verticalAlign:
                                                                "middle",
                                                              fontSize: 15,
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </InputAdornment>
                                                    </>
                                                  ),
                                                }}
                                              />
                                              <Typography
                                                className="itemSpan"
                                                component="span"
                                                variant="caption"
                                                color="#9a9a9a"
                                                ml={1}
                                              >
                                                item
                                              </Typography>
                                              <Typography
                                                className="perItemPriceSpan"
                                                variant="caption"
                                                color="#9a9a9a"
                                              >{`${i.productPrice}/item`}</Typography>
                                            </Box>
                                            <Box
                                              display="flex"
                                              justifyContent="space-around"
                                              gap={2}
                                              className="shortcut"
                                            >
                                              {storedWishlist.findIndex(
                                                (product) =>
                                                  product.productId ===
                                                  i.productId
                                              ) >= 0 ? (
                                                <Link
                                                  style={{
                                                    textDecorationColor:
                                                      "green",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="caption"
                                                    color="green"
                                                  >
                                                    Wishlisted
                                                  </Typography>
                                                </Link>
                                              ) : (
                                                <Link
                                                  style={{
                                                    textDecorationColor:
                                                      "#9a9a9a",
                                                  }}
                                                  onClick={() =>
                                                    addToWishlist(
                                                      i.productId,
                                                      i.orderedColor
                                                    )
                                                  }
                                                >
                                                  <Typography
                                                    variant="caption"
                                                    color="#9a9a9a"
                                                  >
                                                    Move To Wishlist
                                                  </Typography>
                                                </Link>
                                              )}
                                              <Link
                                                style={{
                                                  textDecorationColor:
                                                    "#9a9a9a",
                                                }}
                                                onClick={() =>
                                                  ClearProductFromCart(
                                                    i.productId,
                                                    i.orderedColor
                                                  )
                                                }
                                              >
                                                <Typography
                                                  variant="caption"
                                                  color="#9a9a9a"
                                                >
                                                  Remove
                                                </Typography>
                                              </Link>
                                            </Box>
                                          </Stack>
                                          <Box>
                                            <Typography
                                              className="perItemPrice"
                                              variant="caption"
                                              color="#9a9a9a"
                                            >{`${i.productPrice}/item`}</Typography>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        className="lastgrid"
                                      >
                                        <Box
                                          display="flex"
                                          gap={2}
                                          className="shortcut2"
                                        >
                                          <Link
                                            style={{
                                              textDecorationColor: "#9a9a9a",
                                            }}
                                            onClick={() =>
                                              addToWishlist(i.productId)
                                            }
                                          >
                                            <Typography
                                              variant="caption"
                                              color="#9a9a9a"
                                            >
                                              Wishlist
                                            </Typography>
                                          </Link>
                                          <Link
                                            style={{
                                              textDecorationColor: "#9a9a9a",
                                            }}
                                            onClick={() =>
                                              ClearProductFromCart(i.productId)
                                            }
                                          >
                                            <Typography
                                              variant="caption"
                                              color="#9a9a9a"
                                            >
                                              Remove
                                            </Typography>
                                          </Link>
                                        </Box>
                                        <Box className="price">
                                          <Typography>
                                            ${i.totalProductPrice}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            $
                                            {(
                                              i.orderedQty * i.productMRP
                                            ).toFixed(2)}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Box py={2}>
                                      <Divider
                                        sx={{ borderColor: "darkgray" }}
                                      />
                                    </Box>
                                  </Box>
                                )
                              );
                            })}
                          </Box>

                          {/* Promotion Scetion */}
                          {/* <Box p={4}>
                      <Grid container spacing={3}>
                      <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Box textAlign='left'>
                        <Typography mb={1} color="#3b3b3d" >Promotion</Typography>
                        <Typography color='#30ccb7' variant="subtitle2" fontStyle='italic' ><DoneIcon fontSize="normal" sx={{verticalAlign:'middle', mr:1}} />Promotions applied! <Typography component='span' variant="caption" color="#9a9a9a" ml={3}>All products: Get 5% off when you spend $2,000.00 USD</Typography></Typography>
                        <Typography color='#30ccb7' variant="subtitle2" fontStyle='italic' ><DoneIcon fontSize="normal" sx={{verticalAlign:'middle', mr:1}} />Promotions applied! <Typography component='span' variant="caption" color="#9a9a9a" ml={3}>All products: Get 5% off when you spend $2,000.00 USD</Typography></Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Box display='flex' justifyContent='end'  py={4}>
                          <Typography color='#30ccb7' variant="subtitle2" fontStyle='italic'>0.00</Typography>
                        </Box>
                      </Grid>
                      </Grid>
                      </Box> */}

                          {/* <Box px={2}>
                  <Divider sx={{borderColor:'darkgray'}}/>
                  </Box> */}

                          <Box p={4}>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  p={2}
                                >
                                  <Typography
                                    color="#3b3b3d"
                                    fontWeight={500}
                                    variant="subtitle1"
                                  >
                                    SubTotal
                                  </Typography>
                                  <Typography color="#3b3b3d" fontWeight={600}>
                                    ${subTotal.toFixed(2)}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box mt={5}>
                      <Container>
                        <Grid container display="flex" justifyContent="center">
                          <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Box
                              border={1}
                              borderColor="silver"
                              textAlign="center"
                            >
                              <Box py={5}>
                                <Typography
                                  color="#262626"
                                  fontSize={30}
                                  fontWeight={500}
                                >
                                  <AddShoppingCartIcon
                                    fontSize="large"
                                    sx={{
                                      verticalAlign: "middle",
                                      marginRight: 2,
                                    }}
                                  />
                                  Empty Cart!
                                </Typography>{" "}
                                <Button
                                  variant="contained"
                                  onClick={() => navigate("/shop")}
                                  sx={{
                                    width: {
                                      xs: 200,
                                      sm: 250,
                                      md: 500,
                                      lg: 500,
                                    },
                                    fontWeight: 600,
                                    boxShadow: "none",
                                    marginTop: 2,
                                    bgcolor: "#008844",
                                  }}
                                >
                                  START SHOPPING
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Container>
                    </Box>
                  )}

                  {/* End of Cart Display */}
                </Box>
              </Container>
            </Grid>

            {/* CheckOut Preview - Box to Open Dialog */}

            {totalCartItems && totalCartItems.length !== 0 ? (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Container>
                  <Box
                    component={Card}
                    sx={{
                      boxShadow: "3px 3px 6px -3px rgba(0,0,0,0.7)",
                      borderRadius: 0,
                    }}
                    p={3}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box textAlign="left" py={2}>
                          <Typography color="#3b3b3d" variant="h4">
                            Summary
                          </Typography>
                        </Box>
                        <Box>
                          <Divider sx={{ borderColor: "darkgray" }} />
                        </Box>

                        <Box textAlign="left" py={2}>
                          <Typography color="#3b3b3d" mb={2}>
                            Promo/Voucher Code
                          </Typography>
                          <Box display="flex" justifyContent="space-between">
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              sx={{
                                width: 200,
                                "& .MuiInputBase-root": {
                                  height: 40,
                                },
                              }}
                            />
                            <Button
                              variant="contained"
                              sx={{
                                marginLeft: 2,
                                bgcolor: "#3b3b3d",
                                boxShadow: 0,
                                borderRadius: 0,
                                height: 40,
                                width: 80,
                              }}
                            >
                              Apply
                            </Button>
                          </Box>
                        </Box>

                        <Box>
                          <Divider sx={{ borderColor: "darkgray" }} />
                        </Box>

                        <Stack spacing={2} py={2} mt={2}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" color="#9a9a9a">
                              Sub Total
                            </Typography>
                            <Typography>${subTotal}</Typography>
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" color="red">
                              Total Savings
                            </Typography>
                            <Typography color="red">${TotalSaving}</Typography>
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" color="#9a9a9a">
                              Promo
                            </Typography>
                            <Typography>Not Applied</Typography>
                          </Box>
                        </Stack>

                        <Box>
                          <Divider sx={{ borderColor: "darkgray" }} />
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          py={2}
                          className="total"
                        >
                          <Typography variant="h6" fontWeight={550}>
                            Total Product Price
                          </Typography>
                          <Typography variant="h6" fontWeight={550}>
                            ${subTotal.toFixed(2)}
                          </Typography>
                        </Box>

                        <Box py={2}>
                          <Button
                            onClick={goToCheckout}
                            variant="contained"
                            sx={{
                              marginLeft: 2,
                              bgcolor: "#20bda8",
                              boxShadow: 0,
                              borderRadius: 2,
                              height: { lg: 40, md: 50 },
                            }}
                          >
                            Proceed to Checkout
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            )}
          </Grid>
        </Box>
      )}

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
