import {
  Box,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function ShippingPolicy() {
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [panel5, setPanel5] = useState(false);
  const [panel6, setPanel6] = useState(false);
  const [panel7, setPanel7] = useState(false);
  const [panel8, setPanel8] = useState(false);
  const [panel9, setPanel9] = useState(false);
  const [panel10, setPanel10] = useState(false);

  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Shipping Policy
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Shipping Policy Information
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            Shopztrend Enterprises Inc. (“we” and “us”) is the operator of
            (https://shopztrend.com/) (“Website”). By placing an order through
            this Website you will be agreeing to the terms below. These are
            provided to ensure both parties are aware of and agree upon this
            arrangement to mutually protect and set expectations on our service.
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box py={3}>
          {/* General */}
          <Accordion
            expanded={panel1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel1(!panel1)}
                variant="subtitle1"
              >
                {panel1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                General
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <Typography>
                      Subject to stock availability. We try to maintain accurate
                      stock counts on our website but from time-to-time there
                      may be a stock discrepancy and we will not be able to
                      fulfill all your items at time of purchase. In this
                      instance, we will fulfill the available products to you,
                      and contact you in prior about whether you would prefer to
                      wait restocking of the backordered item or if you would
                      prefer for us to process a refund.
                    </Typography>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Shipping Costs */}
          <Accordion
            expanded={panel2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel2(!panel2)}
                variant="subtitle1"
              >
                {panel2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Shipping Costs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shipping costs are calculated during checkout based on
                      weight, dimensions and destination of the items in the
                      order. Payment for shipping will be collected with the
                      purchase.
                    </li>
                    <li>
                      This price will be the final price for shipping cost to
                      the customer.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Returns */}
          <Accordion
            expanded={panel3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel3(!panel3)}
                variant="subtitle1"
              >
                {" "}
                {panel3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Returns
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  Return Due To Change Of Mind{" "}
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enterprises Inc. will happily accept returns
                      due to change of mind as long as a request to return is
                      received by us within 30 days of purchased date of item
                      and are returned to us in original packaging, unused and
                      in resalable condition.
                    </li>

                    <li>
                      Return shipping will be paid at the customer’s expense and
                      will be required to arrange their own shipping.
                    </li>
                    <li>
                      Once returns are received and accepted, refunds will be
                      processed to store credit for a future purchase or Refund
                      will be processed back to the clients’ mode of payment
                      based on the customer’s request. We will notify you once
                      this has been completed through email.
                    </li>
                    <li>
                      Shopztrend Enterprises Inc. will refund the value of the
                      goods returned but will NOT refund the value of any
                      shipping paid.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Warranty Returns
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enterprises Inc. Will happily honor any valid
                      warranty claims, provided a claim is submitted within the
                      warranty time line of receipt of items.
                    </li>
                    <li>
                      Customers will be required to pre-pay the return shipping,
                      however we will reimburse you upon successful warranty
                      claim.
                    </li>
                    <li>
                      Upon return receipt of items for warranty claim, you can
                      expect Shopztrend Enterprises Inc. to process your
                      warranty claim within 7 -14 days.
                    </li>

                    <li>
                      Once warranty claim is confirmed, you will receive the
                      choice of:
                      <ul type="i">
                        <li>Refund to your payment method (or)</li>
                        <li>A refund in store credit (or)</li>
                        <li>
                          A replacement item sent to you (if stock is available)
                        </li>
                      </ul>
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Delivery Terms */}
          <Accordion
            expanded={panel4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel4(!panel4)}
                variant="subtitle1"
              >
                {" "}
                {panel4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Delivery Terms
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  Transit Time Domestically{" "}
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      In general, domestic shipments are in transit for 7 – 14
                      days
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Transit time Internationally
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enterprises Inc. is delivering within Canada
                      only; we will be able to offer these services
                      internationally shortly.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Dispatch Time
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Orders are usually dispatched within 2 business days of
                      payment of order
                    </li>
                    <li>
                      Our warehouse operates on Monday – Friday during standard
                      business hours, except on national holidays at which time
                      the warehouse will be closed. In these instances, we take
                      steps to ensure shipment delays will be kept to a minimum.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Change Of Delivery Address
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      For change of delivery address requests, we are able to
                      allow the customers to change the address within the first
                      24 hours of the order placed, before the order has been
                      dispatched.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  P.O. Box Shipping
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enerprises Inc. is unable to ship to P.O. Box
                      addresses using the Courier services.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Military Address Shipping
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Shopztrend Enterprises Inc. is unable to ship to military
                      addresses using the courier services.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Items Out Of Stock
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      If an item is out of stock, we will wait for the item to
                      be available before dispatching your order. Existing items
                      in the order will be reserved while we await this item.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Delivery Time Exceeded
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      If delivery time has exceeded the forecasted time, please
                      contact us so that we can conduct an investigation.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Tracking Notifications */}
          <Accordion
            expanded={panel5}
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel5(!panel5)}
                variant="subtitle1"
              >
                {panel5 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Tracking Notifications
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <Typography>
                      Upon dispatch, customers will receive a tracking link from
                      which they will be able to follow the progress of their
                      shipment based on the latest updates made available by the
                      shipping provider.
                    </Typography>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Parcels Damaged In Transit */}
          <Accordion
            expanded={panel6}
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel6(!panel6)}
                variant="subtitle1"
              >
                {panel6 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Parcels Damaged In Transit
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      If you find a parcel is damaged in-transit, if possible,
                      please reject the parcel from the courier and get in touch
                      with our customer service. If the parcel has been
                      delivered without you being present, please contact
                      customer service at
                      <a
                        href="mailto:clientcare@shopztrend.com"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        {" "}
                        clientcare@shopztrend.com
                      </a>{" "}
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Duties &amp; Taxes */}
          <Accordion
            expanded={panel7}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel7(!panel7)}
                variant="subtitle1"
              >
                {" "}
                {panel7 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Duties &amp; Taxes
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 4 }}
                >
                  Sales Tax
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Appropriate GST/HST and PST charges will be applicable as
                      per the Provincial and Federal Jurisdiction.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Import Duties &amp; Taxes
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      Import duties and taxes for international shipments will
                      be pre-paid, without any additional fees to be paid by
                      customer upon arrival in destination country.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Cancellations */}
          <Accordion
            expanded={panel8}
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel8(!panel8)}
                variant="subtitle1"
              >
                {panel8 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Cancellations
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <Typography>
                      If you change your mind before you have received your
                      order, we are able to accept cancellations at 24 hours
                      from the order time, before the order has been dispatched.
                      If an order has already been dispatched, unfortunately we
                      will not be able to cancel the order. But Customers can
                      return the item and are required to pre-pay for the return
                      shipment.
                    </Typography>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Insurance */}
          <Accordion
            expanded={panel9}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel9(!panel9)}
                variant="subtitle1"
              >
                {" "}
                {panel9 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Insurance
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  Parcels are insured for loss and damage up to the value as
                  stated by the courier.
                </Typography>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Process for parcel damaged in-transit
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      We will process a refund or replacement as soon as the
                      courier has completed their investigation into the claim.
                    </li>
                  </Stack>
                </ul>

                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 5 }}
                >
                  Process for parcel lost in-transit
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      We will process a refund or replacement as soon as the
                      courier has conducted an investigation and deemed the
                      parcel lost.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Customer service */}
          <Accordion
            expanded={panel10}
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel10(!panel10)}
                variant="subtitle1"
              >
                {panel10 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Customer service
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      For all customer service enquiries, please email us at
                      <a
                        href="mailto:clientcare@shopztrend.com"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        {" "}
                        clientcare@shopztrend.com
                      </a>{" "}
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
