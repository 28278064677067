import {
  update_email,
  gettax,
  get_provinces,
  user_update,
  add_feedback,
  getuser,
  get_brand_image,
  get_home_image,
  get_wholesale_trendz,
  add_supplier,
  add_contractor,
  add_consultant,
  add_contact_us,
  generate_code,
  verify_code,
  get_wholesale_trendz_banner,
  get_all_brand_names,
} from "../BaseAPI/API";
import { apiGETRequest, apiPOSTRequest } from "../apiRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fetch_update_email = async (data) => {
  try {
    const responseData = await apiPOSTRequest(update_email, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_gettax = async (data) => {
  try {
    const responseData = await apiPOSTRequest(gettax, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_provinces = async () => {
  try {
    const responseData = await apiGETRequest(get_provinces);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_add_feedback = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_feedback, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_getuser = async (data) => {
  try {
    const responseData = await apiPOSTRequest(getuser, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_user_update = async (data) => {
  try {
    const responseData = await apiPOSTRequest(user_update, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_brand_image = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_brand_image, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_home_image = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_home_image, data);
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_add_consultant = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_consultant, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_wholesale_trendz = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_wholesale_trendz, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_add_supplier = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_supplier, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_add_contractor = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_contractor, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_add_contact_us = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_contact_us, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_generate_code = async (data) => {
  try {
    const responseData = await apiPOSTRequest(generate_code, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_wholesale_trendz_banner = async (data) => {
  try {
    const responseData = await apiPOSTRequest(
      get_wholesale_trendz_banner,
      data
    );
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_get_all_brand_names = async () => {
  try {
    const responseData = await apiGETRequest(get_all_brand_names);
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

const fetch_verify_code = async (data) => {
  try {
    const responseData = await apiPOSTRequest(verify_code, data);
    toast.success(responseData.message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return responseData;
  } catch (error) {
    toast.error(`GET  request failed: ${error.message}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export {
  fetch_getuser,
  fetch_add_feedback,
  fetch_update_email,
  fetch_user_update,
  fetch_get_provinces,
  fetch_gettax,
  fetch_add_consultant,
  fetch_add_contact_us,
  fetch_add_contractor,
  fetch_add_supplier,
  fetch_generate_code,
  fetch_get_brand_image,
  fetch_get_home_image,
  fetch_get_wholesale_trendz,
  fetch_get_wholesale_trendz_banner,
  fetch_verify_code,
  fetch_get_all_brand_names,
};
