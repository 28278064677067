import { BaseUrl } from "./BaseUrl";

const commonApiPath = BaseUrl.Live;

const methodGet = "GET";
const methodPost = "POST";

// Category Image
const CategoryImageAPI = `${commonApiPath}category/`;

// Products Image
const BaseImageAPI = `${commonApiPath}products/`;

// Reviews Image
const ReviewImageAPI = `${commonApiPath}review/`;

// userProfile
const ProfileImageAPI = `${commonApiPath}userprofile/`;

// FETCH DATA
const getallcategory = `${commonApiPath}get-allcategory`;

const user_signup = `${commonApiPath}user-signup`;

const getmainproducts = `${commonApiPath}get_all_category_products`;

const user_signin = `${commonApiPath}user-signin`;

const get_product_reviews = `${commonApiPath}get_product_reviews`;

const add_product_reviews = `${commonApiPath}add_product_reviews`;

const add_main_category = `${commonApiPath}add_main_category`;

const add_products = `${commonApiPath}add_products`;

const get_all_orders = `${commonApiPath}get_all_orders`;

const get_allproducts = `${commonApiPath}get-allproducts`;

const getParticularItem = `${commonApiPath}get-mainproducts`;

const add_feedback = `${commonApiPath}add_feedback`;

const getuser = `${commonApiPath}getuser`;

const user_update = `${commonApiPath}user-update`;

const add_user_address = `${commonApiPath}add_user_address`;

const get_all_user_address = `${commonApiPath}get_all_user_address`;

const get_user_address = `${commonApiPath}get_user_address`;

const update_user_address = `${commonApiPath}update_user_address`;

const delete_user_address = `${commonApiPath}delete_user_address`;

const update_email = `${commonApiPath}update_email`;

const update_mobile = `${commonApiPath}update_mobile`;

const update_user_password = `${commonApiPath}update_user_password`;

const forgot_password = `${commonApiPath}forgot_password`;

const add_to_cart = `${commonApiPath}add_to_cart`;

const add_to_wishlist = `${commonApiPath}add_to_wishlist`;

const get_user_wishlist = `${commonApiPath}get_user_wishlist`;

const get_user_cart = `${commonApiPath}get_user_cart`;

const count_cart_wishlist = `${commonApiPath}count_cart_wishlist`;

const get_product_ordered_qty = `${commonApiPath}get_product_ordered_qty`;

const get_provinces = `${commonApiPath}get_provinces`;

const add_shipping_address = `${commonApiPath}add_shipping_address`;

const carddetails = `${commonApiPath}carddetails`;

const get_checkout_details = `${commonApiPath}get_checkout_details`;

const gettax = `${commonApiPath}gettax`;

const get_placed_orders = `${commonApiPath}get_placed_orders`;

const placeorders = `${commonApiPath}placeorders`;

const get_product_review_count = `${commonApiPath}get_product_review_count`;

const get_product_rating_count = `${commonApiPath}get_product_rating_count`;

const filter_products = `${commonApiPath}filter_products`;

const pay_order_payment = `${commonApiPath}pay_order_payment`;

const validate_postal_code = `${commonApiPath}validate_postal_code`;

const calculate_full_estimate = `${commonApiPath}calculate_full_estimate`;

const get_default_address = `${commonApiPath}get_default_address`;

const get_product_color = `${commonApiPath}get_product_color`;

const get_brand_image = `${commonApiPath}get_brand_image`;

const get_home_image = `${commonApiPath}get_home_image`;

const get_wholesale_trendz = `${commonApiPath}get_wholesale_trendz`;

const set_default_address = `${commonApiPath}set_default_address`;

const tracking_shipment = `${commonApiPath}tracking_shipment`;

const get_saved_cards = `${commonApiPath}get_saved_cards`;

const add_returns_refunds = `${commonApiPath}add_returns_refunds`;

const add_supplier = `${commonApiPath}add_supplier`;

const add_contractor = `${commonApiPath}add_contractor`;

const add_consultant = `${commonApiPath}add_consultant`;

const get_user_placed_orders = `${commonApiPath}get_user_placed_orders`;

const add_contact_us = `${commonApiPath}add_contact_us`;

const generate_code = `${commonApiPath}generate_code`;

const verify_code = `${commonApiPath}verify_code`;

const quick_estimate = `${commonApiPath}quick_estimate`;

const create_setup_intent = `${commonApiPath}create_setup_intent`;

const add_card = `${commonApiPath}add_card`;

const confirm_payment_intent = `${commonApiPath}confirm_payment_intent`;

const get_products_in_admin = `${commonApiPath}get_products_in_admin`;

const get_wholesale_trendz_banner = `${commonApiPath}get_wholesale_trendz_banner`;

const get_all_brand_names = `${commonApiPath}get_all_brand_names`;

export {
  getallcategory,
  get_products_in_admin,
  get_all_brand_names,
  get_wholesale_trendz_banner,
  quick_estimate,
  confirm_payment_intent,
  create_setup_intent,
  add_card,
  generate_code,
  verify_code,
  BaseImageAPI,
  get_user_placed_orders,
  add_consultant,
  add_contractor,
  add_supplier,
  add_contact_us,
  add_returns_refunds,
  get_saved_cards,
  tracking_shipment,
  set_default_address,
  get_home_image,
  get_brand_image,
  get_product_color,
  calculate_full_estimate,
  get_default_address,
  validate_postal_code,
  get_placed_orders,
  pay_order_payment,
  filter_products,
  placeorders,
  get_product_rating_count,
  get_product_review_count,
  gettax,
  get_provinces,
  carddetails,
  get_checkout_details,
  add_shipping_address,
  get_user_cart,
  get_product_ordered_qty,
  get_user_wishlist,
  add_to_cart,
  add_to_wishlist,
  update_mobile,
  delete_user_address,
  update_email,
  update_user_address,
  get_user_address,
  get_all_user_address,
  add_user_address,
  user_update,
  getuser,
  add_feedback,
  getParticularItem,
  get_allproducts,
  add_products,
  get_all_orders,
  add_main_category,
  add_product_reviews,
  get_product_reviews,
  user_signup,
  getmainproducts,
  user_signin,
  ProfileImageAPI,
  ReviewImageAPI,
  count_cart_wishlist,
  CategoryImageAPI,
  update_user_password,
  forgot_password,
  get_wholesale_trendz,
  methodGet,
  methodPost,
  commonApiPath,
};
