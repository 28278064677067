import React from "react";
import "./CreditCard.css";
import { useEffect } from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { fetch_get_saved_cards } from "../../Services/Services/UserService";

export default function CreditCardDesign() {
  const [savedCards, setSavedCards] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    const getData = async () => {
      const sendData = new FormData();
      sendData.append("userId", userId);
      try {
        const res = await fetch_get_saved_cards(sendData);
        if (!res.data.error) {
          setSavedCards(res.data.data);
        } else {
          setSavedCards([]);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getData();
  }, [userId]);

  return (
    <Box width="100%">
      {savedCards.length === 0 ? (
        <Box border="1px solid silver" py={5}>
          <Box px={2} textAlign="left">
            <Typography>There are no Saved Cards</Typography>
          </Box>
        </Box>
      ) : (
        savedCards &&
        savedCards.map((i) => {
          return (
            <div class="card">
              <div class="card__front card__part">
                <Typography
                  color="#FFF"
                  variant="h5"
                  textTransform="uppercase"
                  fontWeight={550}
                >
                  {i.cardType}
                </Typography>
                <p class="card_numer">**** **** **** {i.cardNumber}</p>
                <div class="card__space-75">
                  <span class="card__label">Card holder</span>
                  <p class="card__info">{i.cardHolderName}</p>
                </div>
                <div class="card__space-25">
                  <span class="card__label">Expires</span>
                  <p class="card__info">
                    {i.expMonth}/{i.expYear}
                  </p>
                </div>
              </div>

              <div class="card__back card__part">
                <div class="card__black-line"></div>
                <div class="card__back-content">
                  <div class="card__secret">
                    <p class="card__secret--last">XXX</p>
                  </div>
                  {/* <Button variant='contained' sx={{bgcolor:'#FFF' , color:'#000'}}>Remove Card</Button> */}
                </div>
              </div>
            </div>
          );
        })
      )}
    </Box>
  );
}
