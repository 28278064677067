import React from 'react'
import './Spinner.styles.css';

export default function Spinner() {
  return (
<div className="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
  )
}
