/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { Divider, Container, Stack, Grid } from "@mui/material";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";
import SnackBar from "../../Comps/Snackbar";
import { fetch_get_placed_orders } from "../../Services/Services/OrderService";

export default function ViewOrder() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [searchedOrder, setSearchedOrder] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, userOrderId } = location.state || "";
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [BasicData, setBasicData] = useState([]);

  const getAllUserOrdersByOrderId = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    serverData.append("orderId", orderId);
    try {
      const res = await fetch_get_placed_orders(serverData);
      if (!res.data.error) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setSearchedOrder(res.data.data);
        setBasicData(res.data.data[0]);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getAllUserOrdersByOrderId();
  }, [userId, orderId]);

  return (
    <Box>
      <Header />
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />
      <Divider sx={{ borderColor: "silver", borderWidth: "1px" }} />

      <Box p={2}>
        <Container>
          <Box display="flex" justifyContent="end">
            <Button onClick={() => navigate(-1)}>Back To Previous Page</Button>
            <Button
              onClick={() =>
                navigate("/ordertracking", { state: { orderId: userOrderId } })
              }
            >
              Track My Order
            </Button>
          </Box>

          <Grid container mt={3}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box textAlign="left">
                <Typography fontWeight={550}>Order Info</Typography>
                <Stack mt={1}>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Number:</Typography>
                    <Typography color="#262626">
                      {BasicData.userOrderId}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Placed:</Typography>
                    <Typography color="#262626">
                      {BasicData.orderTime}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Status:</Typography>
                    <Typography color="#262626">
                      {BasicData.orderStatus}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Placed By:</Typography>
                    <Typography color="#262626">
                      {BasicData.firstName}
                      {BasicData.lastName}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box textAlign="left">
                <Typography fontWeight={550}>Shipping Address</Typography>
                <Stack mt={1}>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Street:</Typography>
                    <Typography color="#262626">{BasicData.address}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">City:</Typography>
                    <Typography color="#262626">{BasicData.city}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Street:</Typography>
                    <Typography color="#262626">
                      {BasicData.province}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Street:</Typography>
                    <Typography color="#262626">{BasicData.pincode}</Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box textAlign="left">
                <Typography fontWeight={550}>Payment Method</Typography>
                <Stack mt={1}>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Order Cost:</Typography>
                    <Typography color="#262626">
                      ${BasicData.totalCost}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography color="#616e80">Payment Mode:</Typography>
                    <Typography color="#262626">
                      {BasicData.paymentOption}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Box mt={4}>
            <TableContainer sx={{ border: "1px solid silver" }}>
              <Table size="small">
                <TableHead sx={{ bgcolor: "#faa634" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        borderRight: "1px solid silver",
                      }}
                    >
                      Product
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        borderRight: "1px solid silver",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        borderRight: "1px solid silver",
                      }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        borderRight: "1px solid silver",
                      }}
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedOrder &&
                    searchedOrder.map((i, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            borderBottom: "1px solid silver",
                            "& .MuiTableCell-root": {
                              height: "20px",
                            },
                          }}
                        >
                          <TableCell
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid silver",
                              borderLeft: "1px solid silver",
                              borderRight: "1px solid silver",
                            }}
                          >
                            <img
                              alt="order"
                              src={`${BaseImageAPI}${i.mainImage}`}
                              style={{
                                width: 50,
                                height: 50,
                                verticalAlign: "middle",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid silver",
                              borderLeft: "1px solid silver",
                              borderRight: "1px solid silver",
                            }}
                          >
                            <Box>
                              <Typography> {i.productName}</Typography>
                              <Typography variant="caption">
                                ({i.productColor})
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid silver",
                              borderLeft: "1px solid silver",
                              borderRight: "1px solid silver",
                            }}
                          >
                            {i.orderedQty}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              borderBottom: "1px solid silver",
                              borderLeft: "1px solid silver",
                              borderRight: "1px solid silver",
                            }}
                          >
                            {i.productPrice}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="end" p={2}>
              <Stack spacing={1} textAlign="left">
                <Box display="flex" gap={1}>
                  <Typography color="#616e80">Sub Total:</Typography>
                  <Typography color="#262626">
                    ${BasicData.orderCost}
                  </Typography>
                </Box>
                <Box display="flex" gap={1}>
                  <Typography color="#616e80">Taxes:</Typography>
                  <Typography color="#262626">${BasicData.taxCost}</Typography>
                </Box>
                <Box display="flex" gap={1}>
                  <Typography color="#616e80">Shipping Cost:</Typography>
                  <Typography color="#262626">
                    ${BasicData.shippingCost}
                  </Typography>
                </Box>
                <Box display="flex" gap={1}>
                  <Typography color="#616e80">Paid:</Typography>
                  <Typography color="#262626">
                    ${BasicData.totalCost}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
