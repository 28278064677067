import { Box, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "../../Assets/FooterCards/icons8-facebook-96.png";
import TwitterIcon from "../../Assets/FooterCards/icons8-twitter-96.png";
import YouTubeIcon from "../../Assets/FooterCards/icons8-youtube-96.png";
import LinkedInIcon from "../../Assets/FooterCards/icons8-linkedin-96.png";
import InstagramIcon from "../../Assets/FooterCards/icons8-insta-96.png";
import gbusiness from "../../Assets/BusinessLogo/google-my-bussines-logo-554E98BE88-seeklogo.com.png";
import yelp from "../../Assets/BusinessLogo/yelp.png";
import trustpilot from "../../Assets/BusinessLogo/Trustpilot_logo.png";
import purapost from "../../Assets/FooterCards/purapost.png";
import tawk from "../../Assets/BusinessLogo/tawk.webp";
import Card1 from "../../Assets/FooterCards/footer-visa.webp";
import Card2 from "../../Assets/FooterCards/footer-mastercard.webp";
import Card3 from "../../Assets/FooterCards/footer-maestro.webp";
import PlayStore from "../../Assets/FooterCards/app-google-prompt.webp";
import AppStore from "../../Assets/FooterCards/app-apple-prompt.webp";
import { useNavigate } from "react-router-dom";

export default function SecondaryFooter() {
  const navigate = useNavigate();

  return (
    <Box bgcolor="#f5f5f5">
      {/* Footer Layer 1 */}
      <Box padding={2} bgcolor="#333" mb={2}>
        <Grid container mb={2} mt={1} columnSpacing={5} rowSpacing={3}>
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="#ffff"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Delivery & returns
              </Typography>
              <li>
                <Link href="/account" underline="none" color="#ffff">
                  <Typography fontSize={16}>Account</Typography>
                </Link>
              </li>
              <li>
                <Link href="/privacypolicy" underline="none" color="#ffff">
                  <Typography fontSize={16}>Privacy Policy</Typography>
                </Link>
              </li>
              <li>
                <Link href="/returnexchange" underline="none" color="#ffff">
                  <Typography fontSize={16}>Returns & Refunds</Typography>
                </Link>
              </li>
              <li>
                <Link href="/shippingpolicy" underline="none" color="#ffff">
                  <Typography fontSize={16}>Shipping Policy</Typography>
                </Link>
              </li>
              <li>
                <Link href="ordertracking" underline="none" color="#ffff">
                  <Typography fontSize={16}>Track Your Order</Typography>
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="#ffff"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Information
              </Typography>
              <li>
                <Link href="/about" underline="none" color="#ffff">
                  <Typography fontSize={16}>About us</Typography>
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    navigate(`/contactus#CHeader`, { state: { bulk: "0" } })
                  }
                  underline="none"
                  color="#ffff"
                >
                  <Typography fontSize={16}>Contact us</Typography>
                </Link>
              </li>
              <li>
                <Link href="/inspiration" underline="none" color="#ffff">
                  <Typography fontSize={16}>Inspiration</Typography>
                </Link>
              </li>
              <li>
                <Link href="/store" underline="none" color="#ffff">
                  <Typography fontSize={16}>Store Associates</Typography>
                </Link>
              </li>
              <li>
                <Link href="/termsandconditions" underline="none" color="#ffff">
                  <Typography fontSize={16}>Terms & Conditions</Typography>
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                color="#ffff"
                fontWeight={600}
                fontSize={17}
                marginBottom={1}
              >
                Payment & services
              </Typography>
              <li>
                <Link
                  href="/payment_info_credit"
                  underline="none"
                  color="#ffff"
                >
                  <Typography fontSize={16}>Credit Card</Typography>
                </Link>
              </li>
              <li>
                <Link href="/payment_info_debit" underline="none" color="#ffff">
                  <Typography fontSize={16}>Debit Card</Typography>
                </Link>
              </li>
              <li>
                <Link href="/pay_cheque_info" underline="none" color="#ffff">
                  <Typography fontSize={16}>Pay by Checks </Typography>
                </Link>
              </li>
              <li>
                <Link href="/e_transfer_info" underline="none" color="#ffff">
                  <Typography fontSize={16}>Pay by E-Transfer </Typography>
                </Link>
              </li>
              <li>
                <Link href="/shopz_care" underline="none" color="#ffff">
                  <Typography fontSize={16}>Shopztrend Care</Typography>
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ borderBottom: 2 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mb={3}
            display="flex"
            justifyContent="center"
            gap={3}
            className="bottomlogo"
          >
            <img
              src={gbusiness}
              style={{
                width: 100,
                height: 40,
                objectFit: "contain",
                mixBlendMode: "multiply",
              }}
              alt="img"
            ></img>
            <img
              src={yelp}
              style={{
                width: 100,
                height: 40,
                objectFit: "contain",
                mixBlendMode: "multiply",
              }}
              alt="img"
            ></img>
            <img
              src={trustpilot}
              style={{
                width: 100,
                height: 40,
                objectFit: "contain",
                mixBlendMode: "multiply",
              }}
              alt="img"
            ></img>
            <img
              src={purapost}
              style={{
                width: 100,
                height: 40,
                objectFit: "contain",
                mixBlendMode: "multiply",
              }}
              alt="img"
            ></img>
            <img
              src={tawk}
              style={{
                width: 100,
                height: 40,
                objectFit: "cover",
                mixBlendMode: "multiply",
              }}
              alt="img"
            ></img>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} alignContent="center" py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography mb={3} fontWeight="bold">
              Be first to know the latest deals
            </Typography>
            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.youtube.com/channel/UCAsp_pwgCB0yjlu8joqMpZA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={YouTubeIcon}
                alt="YT"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.facebook.com/Shopztrend"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookIcon}
                alt="FB"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://twitter.com/shopztrend"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={TwitterIcon}
                alt="tw"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.linkedin.com/company/shopztrend-enterprises-inc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={LinkedInIcon}
                alt="ln"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>

            <a
              style={{ color: "#2d2d2d" }}
              href="https://www.instagram.com/shopztrend/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="in"
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 5,
                  objectFit: "contain",
                  mixBlendMode: "multiply",
                }}
              />
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} padding={1}>
            <Typography fontWeight="bold">Payment methods</Typography>
            <Typography variant="subitlte1">
              We accept the following payment methods online
            </Typography>
            <Box mt={2}>
              <img src={Card1} style={{ marginRight: 10 }} alt="img"></img>
              <img src={Card2} style={{ marginRight: 10 }} alt="img"></img>
              <img src={Card3} style={{ marginRight: 10 }} alt="img"></img>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography mb={3} fontWeight="bold">
              Download our app
            </Typography>
            <img src={PlayStore} style={{ marginRight: 10 }} alt="img"></img>
            <img src={AppStore} style={{ marginRight: 10 }} alt="img"></img>
          </Grid>
        </Grid>
      </Box>

      <Box bgcolor="#222" color="#ffff" py={2} px={1}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
            <Box mt={1}>
              <Link
                href="/privacypolicy"
                underline="none"
                color="#ffff"
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Privacy policy
              </Link>
              <Link
                href="/"
                underline="none"
                color="#ffff"
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Shipping policy
              </Link>
              <Link
                href="/cookiespolicy"
                underline="none"
                color="#ffff"
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Cookies policy
              </Link>
              <Link
                href="/store"
                underline="none"
                color="#ffff"
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Store Associates
              </Link>
              <Link
                href="/inspiration"
                underline="none"
                color="#ffff"
                borderRight="2px solid silver"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Inspiration
              </Link>
              <Link
                href="/termsandconditions"
                underline="none"
                color="#ffff"
                paddingLeft={1}
                paddingRight={1}
                fontFamily={"CeraStencilPRO-Bold"}
              >
                Terms & conditions
              </Link>
            </Box>
            <Box
              p={1}
              mt={2}
              mb={2}
              display="flex"
              gap={5}
              justifyContent="center"
              flexDirection={{
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              }}
            >
              <Typography>
                {" "}
                © Shopztrend Enterprises Inc {new Date().getFullYear()}. All
                Rights Reserved.
              </Typography>
              {/* <Link href='https://www.mdqualityapps.com/' underline="none" target="_blank" rel="noopener">
            <Typography sx={{color:'#FFF'}}> Designed by  MDQuality Apps Solutions</Typography>
            </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
