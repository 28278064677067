import {
  Box,
  Button,
  Grid,
  Divider,
  Typography,
  Container,
} from "@mui/material";
import SecondaryFooter from "./../Footer/SecondaryFooter";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Header from "./../Header/Header";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function RegistrationConfirmation() {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const pincode = localStorage.getItem("pincode");
  const Email = localStorage.getItem("Email");

  const navigate = useNavigate();

  const handleMovetoShop = () => {
    navigate("/shop");
  };

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "silver" }} />

      <Box bgcolor="#fbfbfb" id="confirmationBox">
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <Box bgcolor="#ffff" m={10} p={5} boxShadow={5}>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  sx={{ color: "#2E9360" }}
                />
                <Typography>
                  {lastName}, thanks for joining Shopztrend
                </Typography>
                <Box textAlign="left" mt={3}>
                  <Typography>
                    {firstName} {lastName}
                  </Typography>
                  <Typography>{pincode}</Typography>
                  <Typography>
                    <MailOutlineIcon />
                    {Email}
                  </Typography>
                </Box>
                <Box mt={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ bgcolor: "#2E9360" }}
                    onClick={handleMovetoShop}
                  >
                    Start Shopping
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <SecondaryFooter />
    </Box>
  );
}
