import { Typography } from '@mui/material';
import React from 'react'

export default function ProductDescription({ productDescription }) {

  const splitDescription = () => {
    const productDescArray = productDescription && productDescription.split(/[\r\n\t]+/);
    const filteredProductDescArray = productDescArray && productDescArray.filter(point => point.trim() !== "");

    const descriptionIndex = filteredProductDescArray && filteredProductDescArray.findIndex(point => point.includes("Description:"));
    const productOverviewIndex = filteredProductDescArray && filteredProductDescArray.lastIndexOf("Product Overview:");

    const productOverviewArray = (productOverviewIndex !== -1 && descriptionIndex !== -1)
      ? filteredProductDescArray.slice(productOverviewIndex + 1, descriptionIndex)
      : [];

    const descriptionArray = (descriptionIndex !== -1)
      ? filteredProductDescArray.slice(descriptionIndex + 1)
      : filteredProductDescArray;

    return {
      productOverviewArray,
      descriptionArray
    };
  };

  const { productOverviewArray, descriptionArray } = splitDescription();

  const renderSpecialCharacters = (array) => {
    if (!array || array.length === 0) {
      return null;
    }

    return (
     <ul style={{listStyle:'none'}}>
        {array.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }} style={{fontSize:15 , marginTop:1}}></li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      {productOverviewArray && productOverviewArray.length > 0 && (
        <>
          <Typography variant='subtitle2' fontWeight={600}>Product Overview:</Typography>
          {renderSpecialCharacters(productOverviewArray)}
        </>
      )}
      {descriptionArray && descriptionArray.length > 0 && (
        <>
          <Typography variant='subtitle2' fontWeight={600}>Description:</Typography>
          {renderSpecialCharacters(descriptionArray)}
        </>
      )}
    </div>
  );
};







// import React from 'react';

// const ProductDescription = ({ productDescription }) => {


//   return (

//   );
// };

// export default ProductDescription;
