/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Card, Radio } from "@mui/material";
import { appendData } from "../../Variables/ProcessVariable";
import SnackBar from "../../Comps/Snackbar";
import { LoadingButton } from "@mui/lab";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  fetch_get_provinces,
  fetch_gettax,
  fetch_getuser,
} from "../../Services/Services/CommonService";
import {
  fetch_add_shipping_address,
  fetch_get_all_user_address,
  fetch_validate_postal_code,
} from "../../Services/Services/UserService";
import { fetch_quick_estimate } from "../../Services/Services/OrderService";

export default function AddressForm({
  addressForm,
  setCalculatedData,
  setAddresForm,
  TotalPiecesFromShipping,
  products,
  subtotal,
  setShippingServiceList,
  setTotalPiecesFromShipping,
  setTotalWeightFromShipping,
  activeStep,
  setActiveStep,
  setTaxinfo,
  setShippingAddressId,
  ShippingAddressId,
  setCheckoutData,
}) {
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [loading, setLoading] = React.useState(false);
  const [allAddresses, setAllAddresses] = useState([]);
  const [AddNewCard, setAddNewCard] = useState(false);
  //for address radio button
  const [selectedValue, setSelectedValue] = useState("");

  const handleChanges = (i) => {
    setSelectedValue(i.addressId);
    setShippingAddressId(i.addressId);
    setAddNewCard(false);
    setAddresForm({
      ...addressForm,
      address_1: i.address,
      state: i.province,
      city: i.city,
      postcode: i.pincode,
      country: "CA",
      countryCode: i.countryCode,
      stateCode: i.provinceCode,
    });
  };

  const showCardForm = () => {
    setAddNewCard(true);
    setSelectedValue("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddresForm({ ...addressForm, [name]: value });
  };

  useEffect(() => {
    const getAdddress = async () => {
      try {
        const res = await fetch_get_provinces();
        if (!res.data.error) {
          setProvinceList(res.data.data);
        } else {
          setProvinceList([]);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getAdddress();
  }, []);

  const options = provinceList.map((i) => {
    const obj = {
      label: i.ProvinceName,
      code: i.provinceCode,
      id: i.provinceId,
    };
    return obj;
  });

  useEffect(() => {
    localStorage.setItem("CheckoutaddressForm", JSON.stringify(addressForm));
  }, [addressForm]);

  const getUserDatatodisplay = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_getuser(serverData);
      if (!res.data.error) {
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setAddresForm({
          ...addressForm,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          phone: res.data.data.mobile.slice(1),
          phoneNumber: res.data.data.mobile,
        });
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getUserDatatodisplay();
  }, [userId]);

  const handleNext = async () => {
    if (
      addressForm.city !== " " &&
      addressForm.postcode &&
      addressForm.state !== "" &&
      addressForm.country !== ""
    ) {
      const obj = {
        receiverCity: addressForm.city,
        receiverProvince: addressForm.stateCode,
        receiverCountry: "CA",
        receiverPostalCode: addressForm.postcode,
      };
      const serverData = appendData(obj);
      setLoading(true);
      try {
        const res = await fetch_validate_postal_code(serverData);
        if (!res.data.error) {
          setOpen(true);
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          addShipAddress();
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
          setLoading(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Fill all the Fields");
    }
  };

  const addShipAddress = async () => {
    const obj = {
      userId: userId,
      firstName: addressForm.first_name,
      lastName: addressForm.last_name,
      address: addressForm.address_1,
      city: addressForm.city,
      pincode: addressForm.postcode,
      country: addressForm.country,
      province: addressForm.state,
      addressId: ShippingAddressId,
      phoneNum: addressForm.phone,
    };
    const serverData = appendData(obj);
    try {
      const res = await fetch_add_shipping_address(serverData);
      if (!res.data.error) {
        getQuickEstimatedShipping();
        setShippingAddressId(res.data.addressId);
        localStorage.setItem("ShippingAddressId", res.data.addressId);
        getTaxInfo();
      } else {
        setMessage(res.data.message);
        setOpen(false);
        setStatus(false);
        setColor(false);
        setLoading(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  // Total Dimensions of all Products

  const GRAMS_TO_POUNDS_CONVERSION_FACTOR = 0.00220462262185; // Conversion factor for grams to pounds

  const calculatedData = {};

  products.forEach((product, index) => {
    const {
      orderedQty,
      productWeight,
      productLength,
      productWidth,
      productHeight,
    } = product;

    // Convert string values to numbers
    const parsedProductWeightInGrams = parseFloat(productWeight);
    const parsedProductLength = parseFloat(productLength);
    const parsedProductWidth = parseFloat(productWidth);
    const parsedProductHeight = parseFloat(productHeight);

    if (
      !isNaN(parsedProductWeightInGrams) &&
      !isNaN(parsedProductLength) &&
      !isNaN(parsedProductWidth) &&
      !isNaN(parsedProductHeight)
    ) {
      for (let i = 0; i < orderedQty; i++) {
        let convertedWeightInPounds =
          parsedProductWeightInGrams *
          orderedQty *
          GRAMS_TO_POUNDS_CONVERSION_FACTOR;
        if (convertedWeightInPounds < 1) {
          convertedWeightInPounds = 1; // Set to 1 if less than 1
        } else {
          convertedWeightInPounds = convertedWeightInPounds.toFixed(6);
        }

        calculatedData[index] = {
          productWeight: convertedWeightInPounds,
          productLength: parsedProductLength.toFixed(2),
          productWidth: parsedProductWidth.toFixed(2),
          productHeight: parsedProductHeight.toFixed(2),
        };
      }
    }
  });

  // Total Weight of all Products

  let totalWeightInPounds = 0;

  products.forEach((product) => {
    const { orderedQty, productWeight } = product;

    // Convert string value to number
    const parsedProductWeightInGrams = parseFloat(productWeight);

    if (!isNaN(parsedProductWeightInGrams)) {
      // Calculate the weight in pounds
      const weightInPounds =
        parsedProductWeightInGrams *
        orderedQty *
        GRAMS_TO_POUNDS_CONVERSION_FACTOR;

      // Accumulate the total weight
      totalWeightInPounds += weightInPounds;
    }
  });

  // Ensure that the total weight is at least 1 pound
  if (totalWeightInPounds < 1) {
    totalWeightInPounds = 1;
  }

  const getQuickEstimatedShipping = async () => {
    const obj = {
      receiverCity: addressForm.city,
      receiverProvince: addressForm.stateCode,
      receiverCountry: "CA",
      receiverPostalCode: addressForm.postcode,
      totalWeight: totalWeightInPounds.toFixed(4),
    };
    const serverData = appendData(obj);
    setLoading(true);
    try {
      const res = await fetch_quick_estimate(serverData);
      if (res.data.data) {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
        setLoading(false);
        setActiveStep(activeStep + 1);
        setShippingServiceList(res.data.data);
        setTotalWeightFromShipping(totalWeightInPounds.toFixed(4));
        setCalculatedData(calculatedData);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
        setLoading(false);
        setOpen(true);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const getTaxInfo = async () => {
    const serverData = new FormData();
    serverData.append("province", addressForm.state);
    serverData.append("total", subtotal);
    try {
      const res = await fetch_gettax(serverData);
      if (!res.data.error) {
        setTaxinfo(res.data.tax_details);
      } else {
        setOpen(true);
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const getAllUserAddress = async () => {
    const serverData = new FormData();
    serverData.append("userId", userId);
    try {
      const res = await fetch_get_all_user_address(serverData);
      if (!res.data.error) {
        setAllAddresses(res.data.data);
      } else {
        setMessage(res.data.message);
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };
  useEffect(() => {
    getAllUserAddress();
  }, [userId]);

  return (
    <React.Fragment>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="first_name"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={handleChange}
            value={addressForm.first_name}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="last_name"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={addressForm.last_name}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="left" display="flex" flexDirection="column">
            <Typography variant="caption" color="#707070" mb={1}>
              Phone Number *
            </Typography>
            <PhoneInput
              country="ca"
              regions={["north-america", "carribean"]}
              value={addressForm.phoneNumber}
              onChange={(value, data) =>
                setAddresForm({
                  ...addressForm,
                  phone: value.slice(data.dialCode.length),
                  phoneNumber: value,
                })
              }
              autoComplete="off"
            />
          </Box>
        </Grid>

        {allAddresses.length !== 0 && (
          <Grid item xs={12}>
            <Box textAlign="left" display="flex" flexDirection="column">
              <Typography variant="caption" color="#707070" mb={1}>
                Choose Shipping Address
              </Typography>
              <Card sx={{ maxHeight: 320, overflowY: "scroll" }}>
                {allAddresses &&
                  allAddresses.map((i) => {
                    return (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          p={2}
                          sx={{ cursor: "pointer" }}
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" gap={2}>
                              <Radio
                                checked={selectedValue === i.addressId}
                                onChange={() => handleChanges(i)}
                                value={i}
                                name="radio-buttons"
                              />
                              <Box>
                                <Typography>
                                  {i.address}, {i.city},
                                </Typography>
                                <Typography>
                                  {i.province}, {i.pincode}, {i.country}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Card>
            </Box>
          </Grid>
        )}

        <Box p={2} onClick={showCardForm}>
          <Typography textAlign="left" color="primary">
            <AddCircleOutlineIcon sx={{ verticalAlign: "middle" }} /> Add New
            Shipping Address
          </Typography>
        </Box>
        {AddNewCard && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                id="address1"
                name="address_1"
                label="Street Address"
                fullWidth
                autoComplete="shipping address-line1"
                variant="standard"
                value={addressForm.address_1}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                autoComplete="shipping address-level2"
                variant="standard"
                value={addressForm.city}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                onChange={(event, value) => {
                  localStorage.setItem("SelectedProvince", value.label);
                  setAddresForm({
                    ...addressForm,
                    state: value.label,
                    stateCode: value.code,
                  });
                }}
                options={options}
                value={addressForm.state}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    size="small"
                    label="Province"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="zip"
                name="postcode"
                label="Zip / Postal code"
                fullWidth
                autoComplete="shipping postal-code"
                variant="standard"
                value={addressForm.postcode}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                options={[
                  {
                    label: "Canada",
                    code: "CA",
                  },
                ]}
                value={addressForm.country}
                onChange={(event, value) => {
                  setAddresForm({
                    ...addressForm,
                    country: value.label,
                    countryCode: value.code,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    size="small"
                    label="Country"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "end", mt: 2 }}
        >
          <LoadingButton
            onClick={handleNext}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            className={!loading && "button-63"}
            sx={{ width: "15ch" }}
          >
            <span>Next</span>
          </LoadingButton>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
