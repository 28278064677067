import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  AccordionSummary,
  Button,
  Stack,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SnackBar from "../../Comps/Snackbar";
import Chip from "@mui/material/Chip";
import {
  fetch_filter_products,
  fetch_get_product_color,
} from "../../Services/Services/ProductService";
import "./ShopPage.styles.css";

export default function FilterProducts({
  setOpenFilterSection,
  setFilterProductsList,
}) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [colorsList, setColorsList] = useState([]);
  const [selectedFilterList, setSelectedFilterList] = useState([]);

  // Handle Change checkBoxes
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [discountFilters, setDiscountFilters] = useState([]);
  const [chosenFilter, setChosenFilter] = useState([]);
  const [sortByFilters, setSortByFilters] = useState("");

  useEffect(() => {
    const getProductColor = async () => {
      try {
        const res = await fetch_get_product_color();
        if (!res.data.error) {
          setMessage(res.data.message);
          setColorsList(res.data.data);
          setOpen(false);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getProductColor();
  }, []);

  const handleChangeColor = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      if (!chosenFilter.includes("Colour")) {
        setChosenFilter([...chosenFilter, "Colour"]);
      }
      setSelectedColors([...selectedColors, { color: value }]);
      setSelectedFilterList([...selectedFilterList, value.toString()]);
    } else {
      setSelectedFilterList(
        selectedFilterList.filter((color) => color !== value)
      );
      setSelectedColors(
        selectedColors.filter((color) => color.color !== value)
      );
      if (selectedColors.length <= 1 && chosenFilter.includes("Colour")) {
        setChosenFilter(chosenFilter.filter((filter) => filter !== "Colour"));
      }
    }
  };

  const handleChangePrice = (event, from, to) => {
    const value = { fromPrice: from, toPrice: to };
    if (event.target.checked) {
      if (!chosenFilter.includes("Price")) {
        setChosenFilter([...chosenFilter, "Price"]);
      }
      setSelectedPrices([...selectedPrices, value]);
      setSelectedFilterList([...selectedFilterList, event.target.name]);
    } else {
      setSelectedFilterList(
        selectedFilterList.filter((price) => price !== event.target.name)
      );
      setSelectedPrices(
        selectedPrices.filter(
          (price) => price.fromPrice !== from || price.toPrice !== to
        )
      );
      if (selectedPrices.length <= 1 && chosenFilter.includes("Price")) {
        setChosenFilter(chosenFilter.filter((filter) => filter !== "Price"));
      }
    }
  };

  const handleDiscountChange = (event, from, to) => {
    const value = { fromDiscount: from, toDiscount: to };
    if (event.target.checked) {
      if (!chosenFilter.includes("Discount")) {
        setChosenFilter([...chosenFilter, "Discount"]);
      }
      setDiscountFilters([...discountFilters, value]);
      setSelectedFilterList([...selectedFilterList, event.target.name]);
    } else {
      setSelectedFilterList(
        selectedFilterList.filter((disc) => disc !== event.target.name)
      );
      setDiscountFilters(
        discountFilters.filter(
          (price) => price.fromDiscount !== from || price.toDiscount !== to
        )
      );
      if (discountFilters.length <= 1 && chosenFilter.includes("Discount")) {
        setChosenFilter(chosenFilter.filter((filter) => filter !== "Discount"));
      }
    }
  };

  const handleSortByChange = (event, type) => {
    if (event.target.checked) {
      if (!chosenFilter.includes("Sorting")) {
        setChosenFilter([...chosenFilter, "Sorting"]);
      }
      setSortByFilters(event.target.name);
      setSelectedFilterList([...selectedFilterList, type]);
    } else {
      setSortByFilters("");
      setSelectedFilterList(selectedFilterList.filter((sort) => sort !== type));
      if (chosenFilter.includes("Sorting")) {
        setChosenFilter(chosenFilter.filter((filter) => filter !== "Sorting"));
      }
    }
  };

  const handleSortByCheckboxDisable = (name) => {
    return sortByFilters !== "" && sortByFilters !== name;
  };

  let result = {};

  if (selectedColors.length === 0 && selectedPrices.length === 0) {
    for (let i = 0; i < discountFilters.length; i++) {
      let newObj = {
        ...(discountFilters[i] || {}),
        sort: sortByFilters || "",
      };
      result[i] = newObj;
    }
  } else {
    const maxIterations = Math.max(
      selectedColors.length,
      selectedPrices.length,
      discountFilters.length
    );

    for (let i = 0; i < maxIterations; i++) {
      let newObj = {
        ...(selectedColors[i] || {}),
        ...(selectedPrices[i] || {}),
        ...(discountFilters[i] || {}),
        sort: sortByFilters || "",
      };
      result[i] = newObj;
    }
  }

  // Filter products
  const bringFilteredProducts = async () => {
    if (chosenFilter.length === 1) {
      const subid = new FormData();
      subid.append("filter", chosenFilter.toString());
      subid.append("productColor", JSON.stringify(selectedColors));
      subid.append("productPrice", JSON.stringify(selectedPrices));
      subid.append("productDiscount", JSON.stringify(discountFilters));
      subid.append("sort", sortByFilters);
      subid.append("filterProduct", "");
      try {
        const res = await fetch_filter_products(subid);
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setFilterProductsList(res.data.data);
          setOpenFilterSection(true);
          const filterResultElement = document.getElementById("filterResult");
          if (filterResultElement) {
            filterResultElement.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else if (chosenFilter.length > 1) {
      const subid = new FormData();
      subid.append("filter", "");
      subid.append("filterProduct", JSON.stringify(result));

      try {
        const res = await fetch_filter_products(subid);
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setFilterProductsList(res.data.data);
          setOpenFilterSection(true);
          const filterResultElement = document.getElementById("filterResult");
          if (filterResultElement) {
            filterResultElement.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      setMessage("Choose Atleast One Property");
      setOpen(true);
      setStatus(false);
      setColor(false);
    }
  };

  const resetData = () => {
    setOpenFilterSection(false);
    result = {};
    setSelectedFilterList([]);
    setSelectedColors([]);
    setSelectedPrices([]);
    setDiscountFilters([]);
    setChosenFilter([]);
    setSortByFilters("");
  };

  const handleDelete = (item) => {
    if (selectedFilterList.length <= 1) {
      resetData();
    }
    setSelectedFilterList(
      selectedFilterList.filter((filter) => filter !== item)
    );

    // Remove the corresponding values from selectedColors
    setSelectedColors(selectedColors.filter((color) => color.color !== item));

    switch (item) {
      case "$10 - $100":
        setSelectedPrices(
          selectedPrices.filter(
            (price) => price.fromPrice !== 10 || price.toPrice !== 100
          )
        );
        break;
      case "$101 - $500":
        setSelectedPrices(
          selectedPrices.filter(
            (price) => price.fromPrice !== 101 || price.toPrice !== 500
          )
        );
        break;
      case "$501 - $1000":
        setSelectedPrices(
          selectedPrices.filter(
            (price) => price.fromPrice !== 501 || price.toPrice !== 1000
          )
        );
        break;
      case "$1000 - $15000":
        setSelectedPrices(
          selectedPrices.filter(
            (price) => price.fromPrice !== 1001 || price.toPrice !== 15000
          )
        );
        break;
      case "More Than 60%":
        setDiscountFilters(
          discountFilters.filter(
            (discount) =>
              discount.fromDiscount !== 60 || discount.toDiscount !== 100
          )
        );
        break;
      case "More Than 40%":
        setDiscountFilters(
          discountFilters.filter(
            (discount) =>
              discount.fromDiscount !== 40 || discount.toDiscount !== 60
          )
        );
        break;
      case "More Than 20%":
        setDiscountFilters(
          discountFilters.filter(
            (discount) =>
              discount.fromDiscount !== 20 || discount.toDiscount !== 40
          )
        );
        break;
      case "More Than 10%":
        setDiscountFilters(
          discountFilters.filter(
            (discount) =>
              discount.fromDiscount !== 10 || discount.toDiscount !== 20
          )
        );
        break;
      case "A TO Z":
      case "Z TO A":
      case "Low To High":
      case "High To Low":
        setSortByFilters("");
        break;
      default:
        // Handle other cases here
        break;
    }

    if (selectedPrices.length <= 1 && chosenFilter.includes("Price")) {
      setChosenFilter(chosenFilter.filter((filter) => filter !== "Price"));
    }

    if (selectedColors.length <= 1 && chosenFilter.includes("Colour")) {
      setChosenFilter(chosenFilter.filter((filter) => filter !== "Colour"));
    }

    if (discountFilters.length <= 1 && chosenFilter.includes("Discount")) {
      setChosenFilter(chosenFilter.filter((filter) => filter !== "Discount"));
    }

    if (sortByFilters !== "" && chosenFilter.includes("Sorting")) {
      setChosenFilter(chosenFilter.filter((filter) => filter !== "Sorting"));
    }
  };

  return (
    <Box>
      <Box textAlign="left">
        <SnackBar
          message={message}
          setOpen={setOpen}
          open={open}
          status={status}
          color={color}
        />
        <Box mx={1} py={2}>
          <Typography variant="h6">
            <TuneIcon sx={{ verticalAlign: "middle", mr: 1 }} /> Filters
          </Typography>
        </Box>

        {/* Filter By Properties */}
        <Accordion disableGutters defaultExpanded={true} sx={{ boxShadow: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="#703737" fontWeight={500}>
              Properties
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              mb={1}
              borderBottom="1px solid silver"
            >
              <Box p={1}>
                <Typography fontWeight={700}>Colour</Typography>
              </Box>
              <Stack spacing={1} px={1}>
                {colorsList &&
                  colorsList.map((i) => {
                    return (
                      <Box py={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={selectedFilterList.includes(
                                i.productColor
                              )}
                              onChange={handleChangeColor}
                              value={i.productColor}
                            />
                          }
                          label={
                            i.productColor.charAt(0).toUpperCase() +
                            i.productColor.slice(1).toLowerCase()
                          }
                        />
                      </Box>
                    );
                  })}
              </Stack>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              mb={1}
              borderBottom="1px solid silver"
            >
              <Box p={1}>
                <Typography fontWeight={700}>Price</Typography>
              </Box>
              <Stack spacing={1} px={1}>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(event) => handleChangePrice(event, 10, 100)}
                        checked={selectedFilterList.includes("$10 - $100")}
                        value="$10 - $100"
                        name="$10 - $100"
                      />
                    }
                    label="$10 - $100"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(event) => handleChangePrice(event, 101, 500)}
                        checked={selectedFilterList.includes("$101 - $500")}
                        value="$101 - $500"
                        name="$101 - $500"
                      />
                    }
                    label="$101 - $500"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(event) =>
                          handleChangePrice(event, 501, 1000)
                        }
                        checked={selectedFilterList.includes("$501 - $1000")}
                        value="$501 - $1000"
                        name="$501 - $1000"
                      />
                    }
                    label="$501 - $1000"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(event) =>
                          handleChangePrice(event, 1001, 15000)
                        }
                        checked={selectedFilterList.includes("Above $1000")}
                        value="Above $1000"
                        name="Above $1000"
                      />
                    }
                    label="Above $1000"
                  />
                </Box>
              </Stack>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              mb={1}
              borderBottom="1px solid silver"
            >
              <Box p={1}>
                <Typography fontWeight={700}>Discount</Typography>
              </Box>
              <Stack spacing={1} px={1}>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="More Than 60%"
                        checked={selectedFilterList.includes("More Than 60%")}
                        onChange={(event) =>
                          handleDiscountChange(event, 60, 100)
                        }
                      />
                    }
                    label="More than 60%"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="More Than 40%"
                        checked={selectedFilterList.includes("More Than 40%")}
                        onChange={(event) =>
                          handleDiscountChange(event, 40, 60)
                        }
                      />
                    }
                    label="More than 40%"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="More Than 20%"
                        checked={selectedFilterList.includes("More Than 20%")}
                        onChange={(event) =>
                          handleDiscountChange(event, 20, 40)
                        }
                      />
                    }
                    label="More than 20%"
                  />
                </Box>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="More Than 10%"
                        checked={selectedFilterList.includes("More Than 10%")}
                        onChange={(event) =>
                          handleDiscountChange(event, 10, 20)
                        }
                      />
                    }
                    label="More than 10%"
                  />
                </Box>
              </Stack>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              mb={1}
              borderBottom="1px solid silver"
            >
              <Box p={1}>
                <Typography fontWeight={700}>Sort By</Typography>
              </Box>
              <Stack spacing={1} px={1}>
                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="product_name"
                        onChange={(e) => handleSortByChange(e, "A TO Z")}
                        disabled={handleSortByCheckboxDisable("product_name")}
                        checked={selectedFilterList.includes("A TO Z")}
                      />
                    }
                    label="A &darr; Z"
                  />
                </Box>

                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="product_name DESC"
                        onChange={(e) => handleSortByChange(e, "Z TO A")}
                        disabled={handleSortByCheckboxDisable(
                          "product_name DESC"
                        )}
                        checked={selectedFilterList.includes("Z TO A")}
                      />
                    }
                    label="Z &darr; A"
                  />
                </Box>

                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="cast(product_price as Decimal(6,2))"
                        checked={selectedFilterList.includes("Low To High")}
                        onChange={(e) => handleSortByChange(e, "Low To High")}
                        disabled={handleSortByCheckboxDisable(
                          "cast(product_price as Decimal(6,2))"
                        )}
                      />
                    }
                    label="Low Price To High Price"
                  />
                </Box>

                <Box py={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="cast(product_price as Decimal(6,2)) DESC"
                        checked={selectedFilterList.includes("High To Low")}
                        onChange={(e) => handleSortByChange(e, "High To Low")}
                        disabled={handleSortByCheckboxDisable(
                          "cast(product_price as Decimal(6,2)) DESC"
                        )}
                      />
                    }
                    label="High Price To Low Price"
                  />
                </Box>
              </Stack>
            </Box>

            {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Size</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
           <Box py={1} >
            <Typography>S</Typography>
            </Box>
             <Box py={1} >
            <Typography>M</Typography>
            </Box>
             <Box py={1} >
            <Typography>L</Typography>
            </Box>
            <Box py={1}>
            <Typography>XL</Typography>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion> */}
            {/* 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Gender</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
           <Box py={1} >
            <Typography>MEN</Typography>
            </Box>
             <Box py={1} >
            <Typography>WOMEN</Typography>
            </Box>
             <Box py={1} >
            <Typography>BOYS</Typography>
            </Box>
            <Box py={1}>
            <Typography>GIRLS</Typography>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion> */}

            {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Customer Rating</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
           <Box py={1} >
            <Typography>⭐⭐ ⭐ ⭐⭐(5)</Typography>
            </Box>
             <Box py={1} >
            <Typography>⭐⭐ ⭐ ⭐(4)</Typography>
            </Box>
             <Box py={1} >
            <Typography>⭐⭐ ⭐(3)</Typography>
            </Box>
            <Box py={1} >
            <Typography>⭐⭐(2)</Typography>
            </Box>
            <Box py={1}>
            <Typography>⭐(1)</Typography>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion> */}
          </AccordionDetails>
        </Accordion>

        {selectedFilterList.length !== 0 && (
          <Box p={2} borderBottom="1px solid silver">
            <Box py={2}>
              <Typography color="#703737" fontWeight={500}>
                Selected Filters
              </Typography>
            </Box>
            <Stack spacing={1}>
              {selectedFilterList &&
                selectedFilterList.map((i) => (
                  <Chip
                    variant="outlined"
                    label={i}
                    color="warning"
                    onDelete={() => handleDelete(i)}
                  />
                ))}
            </Stack>
          </Box>
        )}

        <Box
          py={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            sx={{ bgcolor: "#faa634" }}
            onClick={bringFilteredProducts}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "red" }}
            onClick={resetData}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
