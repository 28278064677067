import { Box, Typography, Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { NotForSaleProductCard } from "../ProductsCard/NotForSaleProductCard";
import { fetch_get_brand_image } from "../../Services/Services/CommonService";
import "./ShopPage.styles.css";

export default function NotForSale(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const getBrand = async () => {
      const sendData = new FormData();
      sendData.append("brandName", id);
      try {
        const res = await fetch_get_brand_image(sendData);
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else if (res.data.data) {
          setMessage(res.data.message);
          setData(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getBrand();
  }, [id]);

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  }, []);

  return (
    <Box position="relative">
      <Header />

      <Box px={1}>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          <Container>
            <Box mt={1}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} xxl={12}>
                  <Box
                    id="BrandsHead"
                    width="100%"
                    bgcolor="#faa634"
                    textAlign="center"
                    py={{ xs: 1, md: 2, lg: 3 }}
                    className="productHead"
                  >
                    <Typography variant="h5" color="#FFFFFF" fontWeight={600}>
                      {" "}
                      Reach Out for Bulk Orders !
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12} md={12} mb={2}>
            <Container>
              <Grid container mt={1} spacing={3}>
                {data.map((product, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={3}
                    position="relative"
                  >
                    <NotForSaleProductCard item={product} index={index} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
