import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SalePageCards({ section, layout, title, BrandData }) {
  const navigate = useNavigate();

  console.log(BrandData);

  const handleOpenSpecificBrands = (id) => {
    if (BrandData.includes(id)) {
      navigate(`/launchingsoon/${id}/#BrandsHead`);
    } else {
      const slideCategoryId = id.replace(/\s+/g, "").toLowerCase();
      navigate(`/shop#${slideCategoryId}`);
    }
  };

  return (
    <Container>
      <Box textAlign="left" mt={4}>
        <Typography variant="h5" fontWeight={500}>
          {title}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {section?.cards?.map((i, index) => {
          return (
            <Grid
              sx={{ cursor: "pointer" }}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              key={index}
              mt={2}
              mb={2}
              display="flex"
              justifyContent={{
                lg: "start",
                md: "start",
                sm: "center",
                xs: "center",
              }}
            >
              <Card
                onClick={() => handleOpenSpecificBrands(i.category)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  maxWidth: 385,
                  height: "100%",
                  textAlign: "left",
                  ":hover": { boxShadow: "0px 0px 4px 3px silver" },
                }}
              >
                {layout === "3" ? (
                  <CardMedia
                    sx={{ width: 385, height: 300, objectFit: "contain" }}
                    image={i.images}
                    title={i.title}
                    component="img"
                  />
                ) : (
                  <CardMedia
                    sx={{ width: 385, height: 280 }}
                    image={i.images}
                    title={i.title}
                    component="img"
                  />
                )}
                <Box bgcolor="#f5f5f5">
                  <CardContent
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="h4"
                      fontWeight={600}
                      fontSize={18}
                      mt={1}
                    >
                      {i.title}
                    </Typography>
                    <Link onClick={() => handleOpenSpecificBrands(i.category)}>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        mt={1}
                        fontWeight={500}
                        color="#0c509f"
                        fontSize={15}
                      >
                        Inquire Now
                      </Typography>
                    </Link>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
