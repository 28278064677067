import { Box, Typography, Container, Grid, Stack } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";

export default function DebitCardInfo() {
  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Debit Card Payments
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Pay by Debit Card
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            When using a debit card for purchases on our ecommerce app, you have
            the option to make payments directly from your bank account. Here's
            how you can buy with a debit card:
          </Typography>
          <ul>
            <Stack spacing={2} mt={3}>
              <li>
                <strong>Add to Cart: </strong>Browse our wide range of products
                and select the items you wish to purchase. Click on the "Add to
                Cart" button to add them to your shopping cart.
              </li>
              <li>
                <strong>Proceed to Checkout: </strong>Once you have finished
                selecting your items, click on the "Checkout" button to proceed
                with the payment process.
              </li>
              <li>
                <strong>Choose Debit Card: </strong>On the payment page, select
                the "Debit Card" option. You will be prompted to enter your
                debit card details, including the card number, expiration date,
                and CVV code.
              </li>
              <li>
                <strong>Authorization: </strong>After providing the necessary
                card information, you may be asked to authorize the payment
                using your bank's security measures, such as entering a One-Time
                Password (OTP) sent to your registered mobile number.
              </li>
              <li>
                <strong>Complete the Purchase: </strong> Once the payment is
                successfully authorized, review your order details and click on
                the "Confirm Purchase" button to complete the transaction. You
                will receive an order confirmation with the estimated delivery
                date.
              </li>
            </Stack>
          </ul>
          <Typography sx={{ marginTop: 3 }}>
            Note: It's important to ensure that your debit or credit card has
            sufficient funds or credit limit available to complete the purchase.
            Additionally, we prioritize the security of your card information
            and implement industry-standard encryption and security measures to
            protect your payment details throughout the transaction process.
          </Typography>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
