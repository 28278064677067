import { Box, Typography, Container, Grid, Stack } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";

export default function PayBy_E_Transfer() {
  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Pay by E-Transfer
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Pay by Interac E-Transfers
          </Typography>
          <ul>
            <Stack spacing={1} mt={3}>
              <li>
                Please initiate the Interac E-Transfer to the mail ID :{" "}
                <a
                  href="mailto:clientcare@shopztrend.com"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  {" "}
                  payments@shopztrend.com
                </a>
              </li>
              <li>
                Please ensure that you use the same email address that is
                registered with Shopztrend.com for the E-Transfer.
              </li>
              <li>
                Kindly include the Order/Invoice number in the E-Transfer memo.
              </li>
              <li>
                Once the payment is received by Shopztrend, your orders will be
                processed.
              </li>
            </Stack>
          </ul>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
