import * as React from "react";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Avatar, Badge, Box, Typography } from "@mui/material";
import { ProductsContext } from "../../Context/CountContext";
import { ProfileImageAPI } from "../../Services/BaseAPI/API";

export default function BasicMenu({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = JSON.parse(localStorage.getItem("Userauth"));
  const profilePic = localStorage.getItem("profilePic");
  const firstName = localStorage.getItem("firstName");

  const { CartCount, wishlistCount } = React.useContext(ProductsContext);

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack spacing={3} p={2}>
          <Link to="/account" style={{ textDecoration: "none" }}>
            <Box display="flex" flexDirection="row">
              {!auth ? (
                <AccountCircleIcon
                  sx={{
                    verticalAlign: "middle",
                    color: "#faa634",
                    marginRight: 1,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#faa634",
                    verticalAlign: "middle",
                    marginRight: 1,
                  }}
                  src={`${ProfileImageAPI}${profilePic}`}
                  alt={firstName}
                />
              )}
              <Typography component="span" sx={{ color: "#49484a" }}>
                Account
              </Typography>
            </Box>
          </Link>

          <Link
            to="/wishlist"
            style={{ textDecoration: "none", color: "#faa634" }}
          >
            <Box>
              <Typography sx={{ color: "#49484a" }}>
                <Badge badgeContent={wishlistCount} color="secondary">
                  <FavoriteBorderIcon
                    sx={{
                      verticalAlign: "middle",
                      color: "#faa634",
                      marginRight: 1,
                    }}
                  />
                </Badge>
                Wishlist
              </Typography>
            </Box>
          </Link>

          <Link
            to="/trolley"
            style={{ textDecoration: "none", color: "#faa634" }}
          >
            <Box>
              <Typography sx={{ color: "#49484a" }}>
                <Badge badgeContent={CartCount} color="secondary">
                  <ShoppingCartIcon
                    sx={{
                      verticalAlign: "middle",
                      color: "#faa634",
                      marginRight: 1,
                    }}
                  />
                </Badge>
                Cart
              </Typography>
            </Box>
          </Link>
        </Stack>
      </Menu>
    </>
  );
}
