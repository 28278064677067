import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import SecondaryFooter from "../Footer/SecondaryFooter";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import React from "react";
import { useState } from "react";
import { appendData } from "../../Variables/ProcessVariable";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fetch_user_signup } from "../../Services/Services/UserService";
import {
  fetch_generate_code,
  fetch_verify_code,
} from "../../Services/Services/CommonService";

export default function RegisterForm() {
  const [Email, setEmail] = useState("");
  const [EmailResult, setEmailResult] = useState(false);
  const [yourInfo, setYourInfo] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [panel1, setpanel1] = useState(true);
  const [panel2, setpanel2] = useState(false);
  const [panel3, setpanel3] = useState(false);
  const [emailFilled, setEmailFilled] = useState(false);
  const [panel2Color, setPanel2Color] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPSent, SetOTPSent] = useState(false);

  const [errorMsg2, setErrorMsg2] = useState("");
  const [ErrorLine2, setErrorLine2] = useState("");
  const [errorMsg1, setErrorMsg1] = useState("");
  const [ErrorLine1, setErrorLine1] = useState("");
  const [errorMsgOTP, setErrorMsgOTP] = useState("");

  const navigate = useNavigate();

  const validateInput = () => {
    const capitalLetterRegex = /[A-Z]/;
    const numberOrSpecialCharRegex = /[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        if (
          password.length >= 6 &&
          capitalLetterRegex.test(password) &&
          numberOrSpecialCharRegex.test(password)
        ) {
          setError(false);
          setErrorMsg("");
        } else {
          setErrorMsg(
            "Password must have at least 1 capital letter, 1 number or special character, and a minimum length of 6 characters."
          );
          setError(true);
        }
      } else {
        setErrorMsg("Passwords do not match");
        setError(true);
      }
    } else {
      setErrorMsg("");
      setError(false);
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    const regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    setIsValid(regex.test(emailValue));
    setEmail(emailValue);
  };

  const handleClick2 = () => {
    if (firstName !== "" && pincode !== "") {
      setYourInfo(true);
      setEmailResult(true);
      setpanel3(true);
      setEmailFilled(true);
      setPanel2Color(false);
    } else {
      setpanel2(true);
      setpanel3(false);
      setYourInfo(false);
      setEmailFilled(true);
      setPanel2Color(true);
    }
  };

  const goback1 = () => {
    setEmailResult(false);
    setYourInfo(false);
    setpanel3(false);
    setEmailFilled(false);
    setpanel2(false);
    setPanel2Color(false);
    setpanel1(true);
  };

  const goback2 = () => {
    setEmailResult(true);
    setpanel2(true);
    setpanel3(false);
    setYourInfo(false);
    setPanel2Color(true);
  };

  const handleClickOpenConfirmation = () => {
    navigate("/account/register/registrationconfirmation#confirmationBox");
    setTimeout(() => {
      const element = document.getElementById("confirmationBox");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const submit = async () => {
    if (password === confirmPassword) {
      const obj = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        email: Email,
        password: password,
        pincode: pincode,
      };
      const sendData = appendData(obj);
      try {
        const res = await fetch_user_signup(sendData);
        if (!res.data.error) {
          successMsg(res.data.message);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem("pincode", pincode);
          localStorage.setItem("Email", Email);
          localStorage.setItem("Userauth", true);
          localStorage.setItem("userId", res.userId);
          localStorage.setItem("accountBoxTitle", "Recent Orders");
          setEmail("");
          setFirstName("");
          setLastName("");
          setMobile("");
          setPincode("");
          setPassword("");
          setConfirmPassword("");
          handleClickOpenConfirmation();
        } else {
          ErrorMsg(res.data.message);
          setError(true);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      ErrorMsg("Error : Passwords Do not Match");
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const sendOTP = async () => {
    if (Email !== "" && isValid) {
      const sendData = new FormData();
      sendData.append("email", Email);
      try {
        const res = await fetch_generate_code(sendData);
        if (!res.data.error) {
          successMsg(res.data.message);
          SetOTPSent(true);
        } else {
          ErrorMsg(res.data.message);
          SetOTPSent(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    }
  };

  const verifyOTP = async () => {
    const sendData = new FormData();
    sendData.append("email", Email);
    sendData.append("verificationCode", OTP);

    try {
      const res = await fetch_verify_code(sendData);
      if (!res.data.error) {
        successMsg(res.data.message);
        SetOTPSent(false);
        setEmailResult(true);
        setpanel2(true);
        setEmailFilled(true);
        setPanel2Color(true);
      } else {
        ErrorMsg(res.data.message);
        SetOTPSent(false);
        setpanel1(true);
        setpanel2(false);
        setpanel3(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  return (
    <Box>
      <Header />
      <Divider />

      <Box>
        <Container>
          <Box py={5}>
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={12}
                lg={10}
                xl={10}
                border={1}
                borderColor="silver"
              >
                {/* Register Form */}
                <Box bgcolor="#f5f5f5" borderTop={1} borderColor="#ccc">
                  <Accordion
                    expanded={panel1}
                    disableGutters
                    sx={{
                      borderRadius: 0,
                      boxShadow: "none",
                      margin: 0,
                      borderBottom: "1px solid silver",
                    }}
                  >
                    {emailFilled ? (
                      <Box
                        padding={2}
                        bgcolor="#f5f5f5"
                        width="100%"
                        textAlign="left"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="h5">
                          <CheckCircleOutlineIcon
                            fontSize="large"
                            sx={{ verticalAlign: "middle", color: "#3C8A63" }}
                          />{" "}
                          Your Email
                        </Typography>
                        {EmailResult && (
                          <Box onClick={goback1}>
                            <Typography sx={{ color: "#195EA3" }}>
                              Change Now{" "}
                              <KeyboardArrowRightIcon
                                sx={{ verticalAlign: "middle" }}
                              />
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box
                        width="100%"
                        bgcolor="#faa634"
                        textAlign="left"
                        py={3}
                        color="#ffff"
                      >
                        <Typography
                          fontSize={20}
                          fontWeight={500}
                          marginLeft={2}
                        >
                          <PersonOutlineIcon
                            padding={1}
                            fontSize="large"
                            sx={{
                              border: 1,
                              borderRadius: "50%",
                              verticalAlign: "middle",
                              marginRight: 2,
                            }}
                          />
                          Do we know you?
                        </Typography>
                      </Box>
                    )}

                    <AccordionDetails>
                      {!EmailResult ? (
                        <Box>
                          <Box display="flex" justifyContent="center">
                            <Box textAlign="left" py={5}>
                              <Stack>
                                <Typography fontWeight={500} color="#707070">
                                  My email address is
                                </Typography>
                                <TextField
                                  sx={{
                                    width: {
                                      xs: 250,
                                      sm: 300,
                                      md: 500,
                                      lg: 500,
                                    },
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  defaultValue=""
                                  variant="outlined"
                                  id="email"
                                  name="email"
                                  type="email"
                                  autoComplete="email"
                                  required
                                  value={Email}
                                  onChange={handleEmailChange}
                                />

                                {OTPSent && (
                                  <>
                                    <Typography
                                      fontWeight={500}
                                      color="#707070"
                                    >
                                      Enter OTP
                                    </Typography>
                                    <TextField
                                      sx={{
                                        width: {
                                          xs: 250,
                                          sm: 300,
                                          md: 500,
                                          lg: 500,
                                        },
                                        marginBottom: 5,
                                      }}
                                      size="small"
                                      id="inputFName"
                                      defaultValue=""
                                      variant="outlined"
                                      sautoComplete="off"
                                      required
                                      inputProps={{ maxLength: 6 }}
                                      helperText={errorMsgOTP}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        var regName = /^[0-9]+$/;
                                        if (
                                          newValue.match(regName) ||
                                          newValue === ""
                                        ) {
                                          setErrorMsgOTP("");
                                          setOTP(newValue); // only set when successful
                                        } else {
                                          setErrorMsgOTP(
                                            "Entered OTP contains non-numeric characters."
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                )}
                                {OTPSent ? (
                                  <Button
                                    variant="contained"
                                    onClick={verifyOTP}
                                    sx={{
                                      height: 40,
                                      boxShadow: "none",
                                      bgcolor: "#99B37E",
                                    }}
                                  >
                                    VERIFY OTP
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    onClick={sendOTP}
                                    sx={{
                                      height: 40,
                                      boxShadow: "none",
                                      bgcolor: "#99B37E",
                                    }}
                                  >
                                    SEND OTP
                                  </Button>
                                )}
                              </Stack>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              Have account with us already?{" "}
                              <Link to="/account">Sign In</Link>
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box textAlign="left" py={2}>
                          <Typography
                            variant="h6"
                            fontWeight={300}
                            fontStyle="italic"
                          >
                            Your Email Address is
                          </Typography>
                          <Typography>{Email}</Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={panel2}
                    disableGutters
                    sx={{ borderRadius: 0, boxShadow: "none", margin: 0 }}
                  >
                    <Box
                      padding={2}
                      bgcolor={!panel2Color ? "#f5f5f5" : "#faa634"}
                      width="100%"
                      textAlign="left"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="h5"
                        color={!panel2Color ? "initial" : "#ffff"}
                      >
                        {" "}
                        {!yourInfo ? (
                          <></>
                        ) : (
                          <CheckCircleOutlineIcon
                            fontSize="large"
                            sx={{ verticalAlign: "middle", color: "#3C8A63" }}
                          />
                        )}{" "}
                        Your Details
                      </Typography>
                      {yourInfo && (
                        <Box onClick={goback2}>
                          <Typography sx={{ color: "#195EA3" }}>
                            Change Now{" "}
                            <KeyboardArrowRightIcon
                              sx={{ verticalAlign: "middle" }}
                            />
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <AccordionDetails
                      sx={{ borderRadius: 0, boxShadow: "none", margin: 0 }}
                    >
                      {!yourInfo ? (
                        <Box>
                          <Box display="flex" justifyContent="center">
                            <Box textAlign="left" py={5}>
                              <Stack>
                                <Typography fontWeight={500} color="#707070">
                                  First Name
                                </Typography>
                                <TextField
                                  sx={{
                                    width: {
                                      xs: 250,
                                      sm: 300,
                                      md: 500,
                                      lg: 500,
                                    },
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  id="inputFName"
                                  defaultValue=""
                                  variant="outlined"
                                  name="firstName"
                                  sautoComplete="off"
                                  required
                                  value={firstName}
                                  error={ErrorLine1}
                                  helperText={errorMsg1}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    var regName = /^[A-Za-z]+$/;
                                    if (
                                      newValue.match(regName) ||
                                      newValue === ""
                                    ) {
                                      setErrorMsg1("");
                                      setErrorLine1(false);
                                      setFirstName(newValue); // only set when successful
                                    } else {
                                      setErrorLine1(true);
                                      setErrorMsg1(
                                        "Forbidden character: %<>$'\""
                                      );
                                    }
                                  }}
                                />
                                <Typography fontWeight={500} color="#707070">
                                  Last Name
                                </Typography>
                                <TextField
                                  sx={{
                                    width: {
                                      xs: 250,
                                      sm: 300,
                                      md: 500,
                                      lg: 500,
                                    },
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  id="inputLName"
                                  defaultValue=""
                                  variant="outlined"
                                  name="lastName"
                                  autoComplete="off"
                                  required
                                  value={lastName}
                                  error={ErrorLine2}
                                  helperText={errorMsg2}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    var regName = /^[A-Za-z]+$/;
                                    if (
                                      newValue.match(regName) ||
                                      newValue === ""
                                    ) {
                                      setErrorMsg2("");
                                      setErrorLine2(false);
                                      setLastName(newValue); // only set when successful
                                    } else {
                                      setErrorLine2(true);
                                      setErrorMsg2(
                                        "Forbidden character: %<>$'\""
                                      );
                                    }
                                  }}
                                />
                                <Typography fontWeight={500} color="#707070">
                                  Mobile Number{" "}
                                  <Typography variant="caption">
                                    {" "}
                                    (Optional)
                                  </Typography>
                                </Typography>
                                <Typography variant="caption" color="#707070">
                                  We'll text you order updates and only call if
                                  we have to!
                                </Typography>
                                <Box
                                  sx={{
                                    width: {
                                      xs: 250,
                                      sm: 300,
                                      md: 500,
                                      lg: 500,
                                    },
                                    marginBottom: 5,
                                  }}
                                  mt={1}
                                >
                                  <PhoneInput
                                    country={"ca"}
                                    onChange={(phone) => setMobile(phone)}
                                    autoComplete="off"
                                  />
                                </Box>
                                <Typography fontWeight={500} color="#707070">
                                  Post Code
                                </Typography>
                                <TextField
                                  sx={{
                                    width: {
                                      xs: 250,
                                      sm: 300,
                                      md: 500,
                                      lg: 500,
                                    },
                                    marginBottom: 5,
                                  }}
                                  size="small"
                                  id="inputpin"
                                  defaultValue=""
                                  variant="outlined"
                                  name="pincode"
                                  autoComplete="off"
                                  required
                                  value={pincode}
                                  inputProps={{ maxLength: 6 }}
                                  onChange={(e) => setPincode(e.target.value)}
                                />
                                <Button
                                  variant="contained"
                                  onClickCapture={handleClick2}
                                  sx={{
                                    height: 40,
                                    boxShadow: "none",
                                    bgcolor: "#99B37E",
                                  }}
                                >
                                  SAVE AND CONTINUE
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box textAlign="left">
                          <Typography
                            variant="h6"
                            fontWeight={300}
                            fontStyle="italic"
                          >
                            First Name
                          </Typography>
                          <Typography sx={{ marginBottom: 2 }}>
                            {firstName}
                          </Typography>
                          <Typography
                            variant="h6"
                            fontWeight={300}
                            fontStyle="italic"
                          >
                            Last Name
                          </Typography>
                          <Typography sx={{ marginBottom: 2 }}>
                            {lastName}
                          </Typography>
                          {mobile !== "" && (
                            <>
                              <Typography
                                variant="h6"
                                fontWeight={300}
                                fontStyle="italic"
                              >
                                Mobile
                              </Typography>
                              <Typography sx={{ marginBottom: 2 }}>
                                {mobile}
                              </Typography>
                            </>
                          )}

                          <Typography
                            variant="h6"
                            fontWeight={300}
                            fontStyle="italic"
                          >
                            Post Code
                          </Typography>
                          <Typography sx={{ marginBottom: 2 }}>
                            {pincode}
                          </Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={panel3}
                    disableGutters
                    sx={{ borderRadius: 0, boxShadow: "none", margin: 0 }}
                  >
                    <Box
                      padding={2}
                      bgcolor={panel3 ? "#faa634" : "#f5f5f5"}
                      width="100%"
                      textAlign="left"
                    >
                      <Typography variant="h5">Your Account Info</Typography>
                    </Box>
                    <AccordionDetails
                      sx={{ borderRadius: 0, boxShadow: "none", margin: 0 }}
                    >
                      <Box textAlign="left">
                        <Typography
                          fontWeight={550}
                          variant="subtitle1"
                          sx={{ marginTop: 2, color: "#707070" }}
                        >
                          Your password must meet the following criteria:{" "}
                        </Typography>
                        <ul>
                          <Stack spacing={1} mt={1}>
                            <li style={{ color: "#2d2d2d" }}>
                              At least 1 uppercase letter
                            </li>
                            <li style={{ color: "#2d2d2d" }}>
                              At least 1 number or special character
                            </li>
                            <li style={{ color: "#2d2d2d" }}>
                              Minimum length of 6 characters
                            </li>
                          </Stack>
                        </ul>
                      </Box>
                      <Box display="grid" justifyContent="center">
                        <Box textAlign="left" py={5}>
                          <Stack>
                            <Typography fontWeight={500} color="#707070">
                              Your Password
                            </Typography>
                            <TextField
                              sx={{
                                width: { xs: 250, sm: 300, md: 500, lg: 500 },
                                marginBottom: 5,
                              }}
                              size="small"
                              id="inputpassword"
                              defaultValue=""
                              variant="outlined"
                              name="password"
                              type="password"
                              required
                              autoComplete="off"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              onBlur={validateInput}
                            />
                            <Typography fontWeight={500} color="#707070">
                              Confirm Password
                            </Typography>
                            <TextField
                              sx={{
                                width: { xs: 250, sm: 300, md: 500, lg: 500 },
                                marginBottom: 5,
                              }}
                              size="small"
                              id="inputconfirmpassword"
                              defaultValue=""
                              variant="outlined"
                              name="confirmPassword"
                              type="password"
                              required
                              helperText={errorMsg}
                              error={error}
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              onBlur={validateInput}
                            />
                            <Button
                              variant="contained"
                              onClick={submit}
                              sx={{
                                height: 40,
                                boxShadow: "none",
                                bgcolor: "#99B37E",
                              }}
                            >
                              Register
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <ToastContainer />

      <SecondaryFooter />
    </Box>
  );
}
