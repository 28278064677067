import { Box, Typography, Container, Grid, Stack } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";

export default function PayByCheque() {
  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Pay by Checks
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box textAlign="left" py={4}>
          <Typography color="#262626" fontSize={30} fontWeight={500}>
            Pay by Checks &amp; Money Orders
          </Typography>
          <ul>
            <Stack spacing={1} mt={3}>
              <li>
                Shopztrend.com accepts Personal Checks and Money Orders as
                payment options for online orders above $1000.
              </li>
              <li>
                Please note that Shopztrend.com will process your order only
                after the payment via Personal Check or Money Order is credited
                to Shopztrend Enterprises Inc.
              </li>
              <li>
                To make a payment, kindly mail your Personal Check or Money
                Order to the following address:
                <ul
                  style={{
                    listStyle: "none",
                    fontWeight: 500,
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <li>Shopztrend Enterprises Inc.</li>
                  <li># 138, Delayen Crescent</li>
                  <li>S7N 2V6, Saskatoon</li>
                  <li>SK, Canada.</li>
                </ul>
              </li>
              <li>
                Please ensure that the postage charges for mailing the Personal
                Check or Money Order are borne by you, the client.{" "}
              </li>
            </Stack>
          </ul>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
