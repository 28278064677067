import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();

  const steps = ["Shipping address", "Payment Details"];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm activeStep={activeStep} setActiveStep={setActiveStep} />
        );
      case 1:
        return (
          <PaymentForm activeStep={activeStep} setActiveStep={setActiveStep} />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <Box>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Box
          id="orderconfirmation"
          component={Card}
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, boxShadow: 5 }}
        >
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <Box>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigate("/shop")}
                sx={{
                  width: { xs: 200, sm: 250, md: 500, lg: 500 },
                  fontWeight: 600,
                  boxShadow: "none",
                  marginTop: 2,
                  bgcolor: "#008844",
                }}
              >
                START SHOPPING
              </Button>
            </Box>
          ) : (
            <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
          )}
        </Box>
      </Container>
    </Box>
  );
}
