import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './Routes';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import FeedBackButton from './Comps/FeedbackModal/FeedBackButton.jsx';
import React, { useEffect , useState } from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Oswald'
      ].join(','),
    },
    Link: {
      fontFamily: [
        'Oswald'
      ].join(','),
    },

  });

  
  const auth = localStorage.getItem('Userauth');


  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 25) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      {
        auth && <FeedBackButton />
      }
      {showScrollButton && (
        <Button
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: 0,
            zIndex: '999',
            display: 'block',
           
          }}
        >
          <ExpandCircleDownIcon
            sx={{ color:'#faa634' , fontSize: { lg: '60px', md: '60px', sm: '60px', xs: '40px' }, rotate: '180deg' }}
          />
        </Button>
      )}
    <Router>
    <Routes />
    </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;



