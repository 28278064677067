import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import FeedBackDialog from './FeedBackDialog';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box } from '@mui/material';

export default function SimpleBackdrop() {

    const [openModal, setOpenModal] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(false);

  const handleToggle = () => {
    setOpenModal(!openModal)
  };
 
  if(isLoading){
    <CircularProgress color="inherit" />
  }
else{

  return (
    <div>
      <Button onClick={handleToggle} variant='contained' 
      sx={{bgcolor:'#737373', position:'fixed', top:'60%', right:-30 , rotate:'-90deg', zIndex:10, 
      height:25, width:120, fontSize:14,opacity:0.7 , ':hover':{bgcolor:'#737373', opacity:1}}}
><ArrowLeftIcon />FeedBack</Button>
      <Box sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <FeedBackDialog open={openModal} setOpen={setOpenModal} /></Box>
    </div>
  );
}
}