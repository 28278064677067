/* eslint-disable jsx-a11y/no-redundant-roles */
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import {
  Box,
  Typography,
  Container,
  Grid,
  Rating,
  Card,
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ProgressBarWithValue from "./ProgressBarWithValue";
import ReviewsTable from "./ReviewsTable";
import WriteReviewDialog from "./WriteReviewDialog";
import { BaseImageAPI } from "../../Services/BaseAPI/API";
import { useLocation, useNavigate } from "react-router-dom";
import { appendData } from "../../Variables/ProcessVariable";
import { ProductsContext } from "../../Context/CountContext";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import Slider from "../Shop/Slider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetch_add_to_cart,
  fetch_getParticularItem,
  fetch_get_product_rating_count,
  fetch_get_product_review_count,
  fetch_get_product_reviews,
} from "../../Services/Services/ProductService";

export default function ReviewPage() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [reviewcount, setReviewCount] = useState("");
  const [ratingcount, setRatingCount] = useState([]);
  const [overallRating, setOverallRating] = useState("");
  const [productQty, setProductQty] = useState(1);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [Notification, setNotification] = useState("");
  const auth = localStorage.getItem("Userauth");
  const { setCartCount, CartCount } = useContext(ProductsContext);
  const [selectedColor, setSelectedColor] = useState(null);
  const navigate = useNavigate();
  const [isAddedCartSnackbarOpen, setIsAddedCartSnackbarOpen] = useState(false);
  const [HelperText, setHelperText] = useState("");
  const [availableStock, setAvailableStock] = useState(1);
  const [PreviewData, setPreviewData] = useState([]);

  const { state } = useLocation();
  const data = state.id;

  useEffect(() => {
    const getParticularData = async () => {
      const sendData = new FormData();
      sendData.append("categoryId", "");
      sendData.append("productId", data);
      try {
        const res = await fetch_getParticularItem(sendData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setPreviewData(res.data.data);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setPreviewData([]);
          setColor(false);
          setOpen(true);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getParticularData();
  }, [data]);

  const handleOpenDialog = () => {
    if (auth) {
      setOpen(!open);
    } else {
      setIsSnackbarOpen(true);
      setNotification(
        "You need to log in before you can add items to your wishlist."
      );
    }
  };

  const handleColorClick = (color, productImage) => {
    setSelectedColor(color);
    setAvailableStock(productImage[color][0].inStock);
    setHelperText("");
  };

  useEffect(() => {
    if (PreviewData.length > 0) {
      const defaultColor = Object.keys(PreviewData[0].productImage)[0];
      setSelectedColor(defaultColor);
      setAvailableStock(PreviewData[0].productImage[defaultColor][0].inStock);
    }
  }, [PreviewData]);

  //  ADD PRODUCT
  const addProductToCart = async (id) => {
    if (productQty !== null && productQty !== "") {
      const obj = {
        userId: userId,
        productId: id,
        qty: productQty,
        cart: 1,
        productColor: selectedColor,
      };
      const lData = appendData(obj);
      try {
        const res = await fetch_add_to_cart(lData);
        if (!res.data.error) {
          setCartCount(CartCount + productQty);
          setIsAddedCartSnackbarOpen(true);
          setNotification("Item Added to your Cart.");
        } else {
          errorMsg(res.data.message);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    } else {
      errorMsg("Add Minimum One Item to Proceed");
    }
  };

  const handleCloseAddedCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAddedCartSnackbarOpen(false);
  };

  //Buy Now

  const BuyDirectly = async (id) => {
    if (productQty !== null && productQty !== "") {
      if (!auth) {
        setIsSnackbarOpen(true);
        setNotification(
          "You need to log in before you can add items to your wishlist."
        );
      } else {
        const obj = {
          userId: userId,
          productId: id,
          qty: productQty,
          cart: 1,
          productColor: selectedColor,
        };
        const lData = appendData(obj);
        try {
          const res = await fetch_add_to_cart(lData);
          if (!res.data.error) {
            setCartCount(CartCount + productQty);
            navigate(`/trolley#trolleycon`);
            setMessage(res.data.message);
          } else {
            errorMsg(res.data.message);
          }
        } catch (error) {
          console.log("Oops something went wrong " + error.message);
        }
      }
    } else {
      errorMsg("Add Minimum One Item to Proceed");
    }
  };

  // ALL REviewsTable

  const getAllReviews = async () => {
    const subid = new FormData();
    subid.append("productId", data);
    try {
      const res = await fetch_get_product_reviews(subid);
      if (!res.data.error) {
        setReviews(res.data.data);
        setMessage(res.data.message);
        setStatus(true);
        setColor(true);
      } else {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, [data]);

  // Review Count
  useEffect(() => {
    const getCount = async () => {
      const subid = new FormData();
      subid.append("productId", data);
      try {
        const res = await fetch_get_product_review_count(subid);
        if (!res.data.error) {
          setMessage(res.data.message);
          setReviewCount(res.data.data);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setReviewCount(res.data.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getCount();
  }, [data]);

  // Rating Count
  useEffect(() => {
    const getCount = async () => {
      const subid = new FormData();
      subid.append("productId", data);
      try {
        const res = await fetch_get_product_rating_count(subid);
        if (!res.data.error) {
          setMessage(res.data.message);
          setRatingCount(res.data.data);
          setOverallRating(res.data.overallRating);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getCount();
  }, [data]);

  const totalRatings = ratingcount.reduce((acc, curr) => acc + curr, 0);
  const ratingPercentages = ratingcount.map((count) =>
    Math.round((count / totalRatings) * 100)
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  // const successMsg = (message) => {
  //   toast.success(message, {
  //     position: toast.POSITION.BOTTOM_LEFT,
  //     autoClose: 2000,
  //     closeOnClick: true,
  //   });
  // };

  const errorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  return (
    <Box>
      <Header />
      <Divider sx={{ borderColor: "silver" }} />

      {/* Snack Bar Alert */}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {Notification}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isAddedCartSnackbarOpen}
        autoHideDuration={500}
        onClose={handleCloseAddedCartSnackbar}
      >
        <Alert onClose={handleCloseAddedCartSnackbar}>{Notification}</Alert>
      </Snackbar>

      <WriteReviewDialog
        open={open}
        setOpen={setOpen}
        PreviewData={PreviewData}
        getAllReviews={getAllReviews}
      />
      <Container>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Box className="dialog" sx={{ cursor: "pointer" }}>
                <Box py={3} px={1}>
                  <Grid container spacing={5}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={6}
                      xl={6}
                      justifyContent="start"
                      display="flex"
                    >
                      <Slider
                        data={PreviewData}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={6}
                      xl={6}
                      textAlign="left"
                      mt={2}
                    >
                      <Container>
                        {PreviewData.map((i, index) => {
                          return (
                            <Stack spacing={3} key={index}>
                              <Box>
                                <Typography variant="h5" color="#000000">
                                  {i.productName}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  fontWeight={500}
                                  sx={{ marginTop: 1 }}
                                >
                                  CAD {i.productPrice}
                                </Typography>
                                <Box display="flex" flexDirection="row" gap={3}>
                                  <Typography
                                    variant="subtitle2"
                                    color="#616e80"
                                    sx={{
                                      marginTop: 1,
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    CAD {i.productMRP}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ marginTop: 1 }}
                                  >
                                    {i.productDiscountPercent !== ""
                                      ? `${i.productDiscountPercent}% OFF`
                                      : ""}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  width: 400,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography fontSize={{ xs: 12, sm: 12 }}>
                                  Customer Rating -
                                </Typography>
                                <Rating
                                  name="hover-feedback"
                                  value={i.overallRating}
                                  precision={0.5}
                                  readOnly
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                {
                                  <Typography
                                    color="#616e80"
                                    variant="caption"
                                    sx={{ marginTop: 1 }}
                                  >
                                    {i.productReviewCount >= 1
                                      ? `(${i.productReviewCount})`
                                      : ""}
                                  </Typography>
                                }
                              </Box>
                              <Box display="flex" gap={1}>
                                <Typography
                                  variant="caption"
                                  sx={{ textDecoration: "underline" }}
                                >
                                  {i.productReviewCount}
                                  {i.productReviewCount >= 1
                                    ? " Comments"
                                    : " Comment"}
                                </Typography>
                                <Typography variant="caption">/</Typography>
                                {i.launchingSoon === "yes" ? (
                                  <Typography
                                    variant="caption"
                                    sx={{ textDecoration: "underline" }}
                                  >
                                    {" "}
                                    Write a Review
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{ textDecoration: "underline" }}
                                  >
                                    {" "}
                                    Write a Review
                                  </Typography>
                                )}
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight={700}
                                >
                                  Colors
                                </Typography>
                                {i.comboColor === "yes" ? (
                                  <>{i.colorCode}</>
                                ) : (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    py={2}
                                    flexWrap="wrap"
                                  >
                                    {Object.keys(i.productImage).map(
                                      (color) => (
                                        <Box
                                          p={1}
                                          key={color}
                                          onClick={() =>
                                            handleColorClick(
                                              color,
                                              i.productImage
                                            )
                                          }
                                          sx={{
                                            width: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            border:
                                              selectedColor === color
                                                ? "3px solid #ffa700"
                                                : "1px solid silver",
                                          }}
                                        >
                                          <img
                                            src={`${BaseImageAPI}${i.productImage[color][0].productImage}`} // Assuming the first image URL is in the first item of the color array
                                            alt={color}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              mixBlendMode: "multiply",
                                            }}
                                          />
                                          <span
                                            style={{
                                              fontSize: 14,
                                              color: "#000",
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {color}
                                          </span>
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )}
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <TextField
                                  label="Qty"
                                  size="small"
                                  id="outlined-start-adornment"
                                  sx={{ width: 150, marginTop: 1 }}
                                  value={
                                    productQty > availableStock
                                      ? availableStock
                                      : productQty
                                  }
                                  max={availableStock}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const numericValue = inputValue.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (numericValue === "") {
                                      setProductQty(null);
                                      setHelperText("");
                                    } else {
                                      const parsedValue =
                                        parseInt(numericValue);
                                      if (parsedValue < availableStock) {
                                        setProductQty(parsedValue);
                                        setHelperText("");
                                      } else {
                                        setProductQty(availableStock);
                                        setHelperText(
                                          "In Stock: " + availableStock
                                        );
                                      }
                                    }
                                  }}
                                  inputProps={{
                                    style: { textAlign: "center" },
                                    max: availableStock,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          onClick={() => {
                                            if (productQty < availableStock) {
                                              setProductQty(
                                                (prevQty) =>
                                                  parseInt(prevQty) + 1
                                              );
                                            } else {
                                              setProductQty(availableStock);
                                              setHelperText(
                                                "In Stock: " + availableStock
                                              );
                                            }
                                          }}
                                        >
                                          <AddIcon
                                            sx={{
                                              verticalAlign: "middle",
                                              fontSize: 16,
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          disabled={productQty === 1 && "true"}
                                          onClick={() =>
                                            setProductQty(
                                              parseInt(productQty) - 1
                                            )
                                          }
                                        >
                                          <RemoveIcon
                                            sx={{
                                              verticalAlign: "middle",
                                              fontSize: 16,
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {HelperText !== "" && (
                                  <Typography
                                    variant="subtitle2"
                                    mt={1}
                                    style={{ color: "red" }}
                                  >
                                    {HelperText}
                                  </Typography>
                                )}
                              </Box>
                              <Box className="addToBagandClose" p={1}>
                                {i.launchingSoon === "no" &&
                                  i.soldOut === "no" && (
                                    <>
                                      <Box>
                                        <Button
                                          sx={{
                                            width: {
                                              lg: "20ch",
                                              md: "18ch",
                                              sm: "auto",
                                              xs: "auto",
                                            },
                                          }}
                                          className="button-62"
                                          role="button"
                                          onClick={() =>
                                            addProductToCart(i.productId)
                                          }
                                        >
                                          ADD TO CART
                                        </Button>
                                      </Box>
                                      <Box>
                                        <Button
                                          sx={{
                                            width: {
                                              lg: "20ch",
                                              md: "18ch",
                                              sm: "auto",
                                              xs: "auto",
                                            },
                                          }}
                                          className="button-64"
                                          role="button"
                                          onClick={() =>
                                            BuyDirectly(i.productId)
                                          }
                                        >
                                          BUY NOW
                                        </Button>
                                      </Box>
                                    </>
                                  )}
                              </Box>
                            </Stack>
                          );
                        })}
                      </Container>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {reviewcount.productReviewCount === 0 ? (
        <Container>
          <Box py={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box component={Card} boxShadow={8} width="100%" p={3}>
                  <Box p={1} textAlign="left">
                    <Typography variant="h6">
                      Be the First one Write a Review
                    </Typography>
                  </Box>
                  <Box textAlign="left">
                    <button
                      className="button-62"
                      role="button"
                      onClick={handleOpenDialog}
                    >
                      Write a Review
                    </button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        <Container>
          <Box mt={4} mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box component={Card} boxShadow={8} width="100%" p={3}>
                  <Box
                    borderBottom="0.0625rem solid rgba(0,0,0,.12)"
                    p={1}
                    textAlign="left"
                  >
                    <Typography variant="h6">
                      {reviewcount.productReviewCount} Reviews
                    </Typography>
                  </Box>

                  {/* Percentage Bar */}
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        display={{ lg: "flex", xs: "block" }}
                        gap={15}
                        p={3}
                        sx={{ borderBottom: "0.0625rem solid rgba(0,0,0,.12)" }}
                      >
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Box textAlign="left">
                            <Typography>
                              <span style={{ fontSize: 25 }}>
                                {overallRating}
                              </span>{" "}
                              out 5 stars
                            </Typography>
                            <Rating
                              name="hover-feedback"
                              value={overallRating}
                              precision={0.5}
                              readOnly
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                          </Box>
                          <button
                            class="button-62"
                            role="button"
                            onClick={handleOpenDialog}
                          >
                            Write a Review
                          </button>
                        </Box>
                        <Box mt={{ xs: 4 }}>
                          <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography variant="subtitle2" textAlign="center">
                              {reviewcount.productReviewCount} Ratings
                            </Typography>
                            {ratingPercentages.map((i, index) => {
                              return (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap={1}
                                  key={index}
                                >
                                  <Typography variant="caption">
                                    {5 - index}
                                    <StarIcon
                                      fontSize="small"
                                      sx={{ color: "#faa634" }}
                                    />
                                  </Typography>
                                  <ProgressBarWithValue key={index} value={i} />
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box p={3} sx={{borderBottom:'0.0625rem solid rgba(0,0,0,.12)'}}>
                <Box display='flex' justifyContent='space-between' flexDirection={{xs:'column', sm:'column', md:'row', lg:'row'}}>
                  <Box  display='flex' justifyContent='start' flexDirection='row' gap={3}>
                      <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={['Most Recent', 'Most Helpful', 'Highest Rated', 'Lowest Rated']}
                      sx={{ width:{xs:150, sm:150, md:200, lg:200} }}
                      renderInput={(params) => <TextField {...params} size='small' label="Sort By" />}
                    />
                      <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={[ '⭐⭐ ⭐ ⭐⭐(5)' , '⭐⭐ ⭐ ⭐(4)' , '⭐⭐ ⭐(3)', '⭐⭐(2)', '⭐(1)']}
                     sx={{ width:{xs:150, sm:150, md:200, lg:200} }}
                      renderInput={(params) => <TextField {...params} size='small' label="Ratings" />}
                    />
                  </Box>
                  <Button className="reviewbutton ndreviewbtn"  onClick={handleOpenDialog}>Write a Review</Button>
                </Box>
              </Box> */}
                    </Grid>
                  </Grid>

                  {/* Reviews Table */}

                  <Box>
                    <ReviewsTable reviews={reviews} setReviews={setReviews} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}

      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
