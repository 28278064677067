import {
  get_all_orders,
  get_product_ordered_qty,
  carddetails,
  get_checkout_details,
  get_placed_orders,
  placeorders,
  calculate_full_estimate,
  tracking_shipment,
  add_returns_refunds,
  get_user_placed_orders,
  quick_estimate,
  create_setup_intent,
  confirm_payment_intent,
} from "../BaseAPI/API";
import { apiGETRequest, apiPOSTRequest } from "../apiRequest";

// Fetch get all orders
const fetch_get_all_orders = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_all_orders, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch product ordered quantity
const fetch_get_product_ordered_qty = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_product_ordered_qty, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch card details
const fetch_carddetails = async (data) => {
  try {
    const responseData = await apiPOSTRequest(carddetails, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch checkout details
const fetch_get_checkout_details = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_checkout_details, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch placed orders
const fetch_get_placed_orders = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_placed_orders, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Place orders
const fetch_placeorders = async (data) => {
  try {
    const responseData = await apiPOSTRequest(placeorders, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Calculate full estimate
const fetch_calculate_full_estimate = async (data) => {
  try {
    const responseData = await apiPOSTRequest(calculate_full_estimate, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Tracking shipment
const fetch_tracking_shipment = async (data) => {
  try {
    const responseData = await apiPOSTRequest(tracking_shipment, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Add returns and refunds
const fetch_add_returns_refunds = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_returns_refunds, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch user placed orders
const fetch_get_user_placed_orders = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_user_placed_orders, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Quick estimate
const fetch_quick_estimate = async (data) => {
  try {
    const responseData = await apiPOSTRequest(quick_estimate, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Create setup intent
const fetch_create_setup_intent = async () => {
  try {
    const responseData = await apiGETRequest(create_setup_intent);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Confirm payment intent
const fetch_confirm_payment_intent = async (data) => {
  try {
    const responseData = await apiPOSTRequest(confirm_payment_intent, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_get_all_orders,
  fetch_get_product_ordered_qty,
  fetch_carddetails,
  fetch_get_checkout_details,
  fetch_get_placed_orders,
  fetch_placeorders,
  fetch_calculate_full_estimate,
  fetch_tracking_shipment,
  fetch_add_returns_refunds,
  fetch_get_user_placed_orders,
  fetch_quick_estimate,
  fetch_create_setup_intent,
  fetch_confirm_payment_intent,
};
