import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import StorePic from "../../Assets/Store/pexels-fauxels-3184418.jpg";
import Finger from "../../Assets/Store/icons8-hand-right-96.png";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appendData } from "../../Variables/ProcessVariable";
import {
  fetch_add_consultant,
  fetch_add_contractor,
  fetch_add_supplier,
} from "../../Services/Services/CommonService";

export default function Store() {
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);

  const [supplierContactPerson, setSupplierContactPerson] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierMail, setSupplierMail] = useState("");
  const [supplierMobile, setSupplierMobile] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [productNature, setProductNature] = useState("");
  const [factoryLocation, setFactoryLocation] = useState("");
  const [reference, setReference] = useState("");
  const [projectType, setProjectType] = useState("");
  const [productDetails, setProductDetails] = useState("");

  const [contractorCategory, setContractorCategory] = useState("");
  const [contractorCompany, setContractorCompany] = useState("");
  const [contractorContactPerson, setContractorContactPerson] = useState("");
  const [contractorAddress, setContractorAddress] = useState("");
  const [contractorMail, setContractorMail] = useState("");
  const [contractorMobile, setContractorMobile] = useState("");
  const [contractorwebsiteURL, setcontractorWebsiteURL] = useState("");
  const [contractorcompanyType, setcontractorCompanyType] = useState("");
  const [contractorpastExperience, setcontractorPastExperience] = useState("");
  const [contractorreference, setcontractorReference] = useState("");
  const [contractorprojectType, setcontractorProjectType] = useState("");

  const [consultantCompany, setConsultantCompany] = useState("");
  const [consultantContactPerson, setConsultantContactPerson] = useState("");
  const [consultantAddress, setConsultantAddress] = useState("");
  const [consultantMail, setConsultantMail] = useState("");
  const [consultantMobile, setConsultantMobile] = useState("");
  const [consultantwebsiteURL, setconsultantWebsiteURL] = useState("");
  const [consultantType, setConsultantType] = useState("");
  const [consultantpastExperience, setconsultantPastExperience] = useState("");
  const [consultantreference, setconsultantReference] = useState("");
  const [consultantprojectType, setconsultantProjectType] = useState("");
  const [consultantcompanyType, setconsultantCompanyType] = useState("");

  const addVendor = async () => {
    const obj = {
      supplierContactPerson: supplierContactPerson,
      supplierAddress: supplierAddress,
      supplierMail: supplierMail,
      supplierMobile: supplierMobile,
      websiteURL: websiteURL,
      productNature: productNature,
      factoryLocation: factoryLocation,
      reference: reference,
      projectType: projectType,
      productDetails: productDetails,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_supplier(lData);
      if (!res.data.error) {
        successMsg("Your request has been sent successfully !");
        setSupplierContactPerson("");
        setSupplierAddress("");
        setSupplierMail("");
        setSupplierMobile("");
        setWebsiteURL("");
        setProductNature("");
        // setPastExperience("");
        setFactoryLocation("");
        setReference("");
        setProjectType("");
        setProductDetails("");
      } else {
        ErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const addContractor = async () => {
    const obj = {
      contractorCategory: contractorCategory,
      contractorCompany: contractorCompany,
      contractorContactPerson: contractorContactPerson,
      contractorAddress: contractorAddress,
      contractorMail: contractorMail,
      contractorMobile: contractorMobile,
      websiteURL: contractorwebsiteURL,
      companyType: contractorcompanyType,
      pastExperience: contractorpastExperience,
      reference: contractorreference,
      projectType: contractorprojectType,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_contractor(lData);
      if (!res.data.error) {
        successMsg("Your request has been sent successfully !");
        setContractorCategory("");
        setContractorCompany("");
        setContractorContactPerson("");
        setContractorAddress("");
        setContractorMail("");
        setContractorMobile("");
        setcontractorWebsiteURL("");
        setcontractorCompanyType("");
        setcontractorPastExperience("");
        setcontractorReference("");
        setcontractorProjectType("");
      } else {
        ErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const addConsultant = async () => {
    const obj = {
      consultantCompany: consultantCompany,
      consultantContactPerson: consultantContactPerson,
      consultantAddress: consultantAddress,
      consultantMail: consultantMail,
      consultantMobile: consultantMobile,
      websiteURL: consultantwebsiteURL,
      consultantType: consultantType,
      pastExperience: consultantpastExperience,
      reference: consultantreference,
      projectType: consultantprojectType,
      companyType: consultantcompanyType,
    };
    const lData = appendData(obj);
    try {
      const res = await fetch_add_consultant(lData);
      if (!res.data.error) {
        successMsg("Your request has been sent successfully !");
        setConsultantCompany("");
        setConsultantContactPerson("");
        setConsultantAddress("");
        setConsultantMail("");
        setConsultantMobile("");
        setconsultantWebsiteURL("");
        setConsultantType("");
        setconsultantPastExperience("");
        setconsultantReference("");
        setconsultantProjectType("");
        setconsultantCompanyType("");
      } else {
        ErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  const ErrorMsg = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      closeOnClick: true,
    });
  };

  return (
    <Box>
      <Header />
      {/* <Divider sx={{borderColor:'silver' , borderWidth:'1px'}}/> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Store Associates
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box width="100%" mt={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={StorePic}
                alt="img"
                style={{ objectFit: "cover", width: "100%", height: 350 }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              md={6}
              display="flex"
              alignItems="center"
              bgcolor="#faa634"
            >
              <Box
                textAlign="left"
                width="100%"
                height={{ lg: 350, md: 350, sm: 600, xs: 650 }}
                p={2}
                display="flex"
                justifyContent="space-evenly"
                flexDirection="column"
              >
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: 1, textAlign: "justify", color: "#49484a" }}
                >
                  Shopztrend Enterprises Inc. has established an extensive
                  global supply chain network with a view of strategic sourcing
                  for its products. The business partnership has been set up
                  with some of the most renowned manufacturers from India,
                  China, Vietnam, Cambodia, Middle East, Turkey, Mexico and Far
                  East Asia.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: 1, textAlign: "justify", color: "#49484a" }}
                >
                  We also have sourcing set-ups located in various part of the
                  world and that might be a significant advantage to your
                  Purchasing team. We also have our business partners in the
                  designated locations to manage the Product specifications, due
                  diligence and packaging requirement as per the international
                  standards.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: 1, textAlign: "justify", color: "#49484a" }}
                >
                  At Shopztrend Enterprises Inc. we truly value the relationship
                  of our suppliers and transparency deal on mutual benefit
                  terms. We have a stringent credit policy and deal with
                  established corporations to secure our own and suppliers’
                  payments.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginTop: 1,
                    cursor: "pointer",
                    textAlign: "justify",
                    color: "#49484a",
                  }}
                >
                  If you would like to be preferred supplier and take advantage
                  of Shopztrend Enterprises Inc. extensive network, please reach
                  out to the email{" "}
                  <a
                    href="mailto:clientcare@shopztrend.com"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    sales@shopztrend.com
                  </a>{" "}
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box>
        <Container>
          <Box>
            <Grid container mb={2} mt={3}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Box width="100%" textAlign="center" py={3}>
                  <Grid
                    container
                    mb={2}
                    mt={3}
                    columnSpacing={3}
                    rowSpacing={3}
                    display="flex"
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      onMouseEnter={() => {
                        const icon = document.querySelector(".homeIcon");
                        icon.style.transform = "scale(1.5)";
                        icon.style.transition = "transform 0.2s ease-in-out";
                      }}
                      onMouseLeave={() => {
                        const icon = document.querySelector(".homeIcon");
                        icon.style.transform = "";
                        icon.style.transition = "";
                      }}
                    >
                      <Box component={Card} border="2px solid #faa634">
                        <Box
                          my={2}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          gap={1}
                          maxHeight={200}
                        >
                          <Box>
                            <HomeOutlinedIcon
                              className="homeIcon"
                              sx={{
                                color: "#faa634",
                                fontSize: "60px",
                                verticalAlign: "middle",
                                transition: "transform 0.2s ease-in-out",
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant="h6" fontWeight={700}>
                              Shopztrend Enterprises Inc.
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2" color="#616e80">
                              138, Delayen Crescent, Saskatoon
                            </Typography>
                            <Typography variant="subtitle2" color="#616e80">
                              Saskatchewan, S7N 2V6.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      onMouseEnter={() => {
                        const icon = document.querySelector(".phoneIcon");
                        icon.style.transform = "scale(1.5)";
                        icon.style.transition = "transform 0.2s ease-in-out";
                      }}
                      onMouseLeave={() => {
                        const icon = document.querySelector(".phoneIcon");
                        icon.style.transform = "";
                        icon.style.transition = "";
                      }}
                    >
                      <Box component={Card} border="2px solid #faa634">
                        <Box
                          my={2}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          gap={1}
                          maxHeight={200}
                        >
                          <Box>
                            <ContactPhoneOutlinedIcon
                              className="phoneIcon"
                              sx={{
                                color: "#faa634",
                                fontSize: "60px",
                                verticalAlign: "middle",
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant="h6" fontWeight={700}>
                              Phone
                            </Typography>
                          </Box>
                          <Box mt={2.5}>
                            <Typography variant="subtitle2" color="#616e80">
                              +1(306)590-0000
                            </Typography>
                            {/* <Typography variant='subtitle2' color='#616e80'>3068505760</Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      onMouseEnter={() => {
                        const icon = document.querySelector(".emailIcon");
                        icon.style.transform = "scale(1.5)";
                        icon.style.transition = "transform 0.2s ease-in-out";
                      }}
                      onMouseLeave={() => {
                        const icon = document.querySelector(".emailIcon");
                        icon.style.transform = "";
                        icon.style.transition = "";
                      }}
                    >
                      <Box component={Card} border="2px solid #faa634">
                        <Box
                          my={2}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          gap={1}
                          maxHeight={200}
                        >
                          <Box>
                            <AttachEmailOutlinedIcon
                              className="emailIcon"
                              sx={{
                                color: "#faa634",
                                fontSize: "60px",
                                verticalAlign: "middle",
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant="h6" fontWeight={700}>
                              Email
                            </Typography>
                          </Box>
                          <Box mt={2.5}>
                            <Typography variant="subtitle2" color="#616e80">
                              sales@shopztrend.com{" "}
                            </Typography>
                            {/* <Typography variant='subtitle2' color='#616e80'> clientcare@shopztrend.com</Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Container>
          <Box textAlign="left" py={4}>
            <Typography color="#faa635" fontSize={30} fontWeight={500}>
              VENDOR INQUIRY
            </Typography>
          </Box>
        </Container>

        <Container>
          <Box py={3}>
            {/* Exchanges */}
            <Accordion
              expanded={panel1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                boxShadow: "none",
                border: "none",
              }}
              disableGutters
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ borderBottom: "none" }}
              >
                <Typography
                  color="#000"
                  fontWeight={600}
                  onClick={() => {
                    setPanel1(!panel1);
                    setPanel2(false);
                    setPanel3(false);
                  }}
                  variant="subtitle1"
                >
                  {panel1 ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}{" "}
                  Vendors
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container p={2} bgcolor="#f0f0f0">
                  <Stack spacing={2} textAlign="left">
                    <Box display="flex" justifyContent="start">
                      <Typography color="#faa634">
                        Fields marked with * are mandatory
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact Person *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={supplierContactPerson}
                          onChange={(e) =>
                            setSupplierContactPerson(e.target.value)
                          }
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Address *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={supplierAddress}
                          onChange={(e) => setSupplierAddress(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Email Id *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          type="email"
                          sx={{ width: "25ch" }}
                          value={supplierMail}
                          onChange={(e) => setSupplierMail(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact No *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={supplierMobile}
                          onChange={(e) => setSupplierMobile(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Website URL</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={websiteURL}
                          onChange={(e) => setWebsiteURL(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Type & nature of the product</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={productNature}
                          onChange={(e) => setProductNature(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          Location of the factory / go-down / branches /
                          workplace
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={factoryLocation}
                          onChange={(e) => setFactoryLocation(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          References of previous Partnership *
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={reference}
                          onChange={(e) => setReference(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Type of projects *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={projectType}
                          onChange={(e) => setProjectType(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          Product details & specification{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={productDetails}
                          onChange={(e) => setProductDetails(e.target.value)}
                        />
                      </Grid>
                    </Box>

                    <Box>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button
                          onClick={addVendor}
                          variant="contained"
                          sx={{
                            ml: 6,
                            color: "#fff",
                            width: "20ch",
                            bgcolor: "#000",
                            ":hover": { bgcolor: "#faa634" },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Box>
                  </Stack>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Return Exclusions */}
            <Accordion
              expanded={panel2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                boxShadow: "none",
                border: "none",
              }}
              disableGutters
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ borderBottom: "none" }}
              >
                <Typography
                  color="#000"
                  fontWeight={600}
                  onClick={() => {
                    setPanel2(!panel2);
                    setPanel3(false);
                    setPanel1(false);
                  }}
                  variant="subtitle1"
                >
                  {panel2 ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}{" "}
                  Contractors
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container p={2} bgcolor="#f0f0f0">
                  <Stack spacing={2} textAlign="left">
                    <Box display="flex" justifyContent="start">
                      <Typography color="#faa634">
                        Fields marked with * are mandatory
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Category of contractor * </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorCategory} // Connect to state variable
                          onChange={(e) =>
                            setContractorCategory(e.target.value)
                          } // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Name of the company *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorCompany} // Connect to state variable
                          onChange={(e) => setContractorCompany(e.target.value)} // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact Person *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorContactPerson} // Connect to state variable
                          onChange={(e) =>
                            setContractorContactPerson(e.target.value)
                          } // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Address *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorAddress} // Connect to state variable
                          onChange={(e) => setContractorAddress(e.target.value)} // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Email Id *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorMail} // Connect to state variable
                          onChange={(e) => setContractorMail(e.target.value)} // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact No *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorMobile} // Connect to state variable
                          onChange={(e) => setContractorMobile(e.target.value)} // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Website URL</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorwebsiteURL} // Connect to state variable
                          onChange={(e) =>
                            setcontractorWebsiteURL(e.target.value)
                          } // Update state on change
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          Company type – pub ltd, pvt ltd, partnership,
                          proprietary etc
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorcompanyType} // Set the value to the state variable
                          onChange={(e) =>
                            setcontractorCompanyType(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          Past experience in the field/existence in the field *
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorpastExperience} // Set the value to the state variable
                          onChange={(e) =>
                            setcontractorPastExperience(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          References of previous Partnership *
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorreference} // Set the value to the state variable
                          onChange={(e) =>
                            setcontractorReference(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Type of projects *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={contractorprojectType} // Set the value to the state variable
                          onChange={(e) =>
                            setcontractorProjectType(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button
                          onClick={addContractor}
                          variant="contained"
                          sx={{
                            ml: 6,
                            color: "#fff",
                            width: "20ch",
                            bgcolor: "#000",
                            ":hover": { bgcolor: "#faa634" },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Box>
                  </Stack>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={panel3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                boxShadow: "none",
                border: "none",
              }}
              disableGutters
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ borderBottom: "none" }}
              >
                <Typography
                  color="#000"
                  onClick={() => {
                    setPanel3(!panel3);
                    setPanel1(false);
                    setPanel2(false);
                  }}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {panel3 ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}{" "}
                  Consultant
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container p={2} bgcolor="#f0f0f0">
                  <Stack spacing={2} textAlign="left">
                    <Box display="flex" justifyContent="start">
                      <Typography color="#faa634">
                        Fields marked with * are mandatory
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Name of the company *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantCompany} // Set the value to the state variable
                          onChange={(e) => setConsultantCompany(e.target.value)} // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact Person *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantContactPerson} // Set the value to the state variable
                          onChange={(e) =>
                            setConsultantContactPerson(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Address *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantAddress} // Set the value to the state variable
                          onChange={(e) => setConsultantAddress(e.target.value)} // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Email Id *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantMail} // Set the value to the state variable
                          onChange={(e) => setConsultantMail(e.target.value)} // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Contact No *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantMobile} // Set the value to the state variable
                          onChange={(e) => setConsultantMobile(e.target.value)} // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>Website URL</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantwebsiteURL} // Set the value to the state variable
                          onChange={(e) =>
                            setconsultantWebsiteURL(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Type of consultant</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantType} // Set the value to the state variable
                          onChange={(e) => setConsultantType(e.target.value)} // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          Past experience in the field/existence in the field *
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantpastExperience} // Set the value to the state variable
                          onChange={(e) =>
                            setconsultantPastExperience(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>
                          References of previous Partnership *
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantreference} // Set the value to the state variable
                          onChange={(e) =>
                            setconsultantReference(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Type of projects *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantprojectType} // Set the value to the state variable
                          onChange={(e) =>
                            setconsultantProjectType(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                      }}
                    >
                      <Grid item xs={12} sm={5} md={5} lg={5}>
                        <Typography>Company Type *</Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: "25ch" }}
                          value={consultantcompanyType} // Set the value to the state variable
                          onChange={(e) =>
                            setconsultantCompanyType(e.target.value)
                          } // Set the onChange handler
                        />
                      </Grid>
                    </Box>

                    <Box>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Button
                          onClick={addConsultant}
                          variant="contained"
                          sx={{
                            ml: 6,
                            color: "#fff",
                            width: "20ch",
                            bgcolor: "#000",
                            ":hover": { bgcolor: "#faa634" },
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Box>
                  </Stack>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>

        <Container>
          <Box mt={4} mb={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                md={4}
                display="flex"
                alignItems="center"
              >
                <Box textAlign="left">
                  <Typography variant="h6" fontWeight={600}>
                    Shopztrend Enterprises Inc.
                  </Typography>
                  <Typography sx={{ marginTop: 1, color: "#49484a" }}>
                    You Know Where to Find Us.
                    <img
                      src={Finger}
                      alt=""
                      style={{
                        height: 30,
                        width: 30,
                        verticalAlign: "bottom",
                        marginLeft: 3,
                      }}
                    />
                  </Typography>
                </Box>
              </Grid>

              <Grid
                bgcolor="#f0f0f0"
                item
                xs={12}
                sm={12}
                lg={8}
                xl={8}
                md={8}
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                {/* <iframe width="620" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=138,%20Delayen%20Crescent,%20%20Saskatoon,%20Saskatchewan+(Shopztrend%20Enterprises%20Inc.%20)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
                <Box textAlign="left" p={5}>
                  <Stack spacing={1}>
                    <Typography color="#faa635" variant="h5" fontWeight={500}>
                      OUR OFFICE
                    </Typography>
                    <Typography color="#faa634" variant="h6" fontWeight={500}>
                      Registered Office
                    </Typography>
                    <Typography>138, Delayen Crescent</Typography>
                    <Typography>Saskatoon, Saskatchewan , S7N 2V6.</Typography>
                    <Typography>Tel : +1(306)590-0000</Typography>
                    <Typography>
                      Mail :
                      <a
                        href="mailto:sales@shopztrend.com"
                        style={{
                          fontWeight: 400,
                          textDecoration: "none",
                          color: "#faa635",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        sales@shopztrend.com
                      </a>
                    </Typography>
                    <a
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      href="https://maps.google.com/maps?q=138,%20Delayen%20Crescent,%20%20Saskatoon,%20Saskatchewan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on Google Maps
                    </a>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <ToastContainer />
      <Footer />
      <BottomCredLink />
    </Box>
  );
}
