import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Box,
  TablePagination,
  Typography,
  Rating,
  Avatar,
  Dialog,
} from "@mui/material";
import { ReviewImageAPI } from "../../Services/BaseAPI/API";
import StarIcon from "@mui/icons-material/Star";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

export default function ReviewsTable({ reviews, setReviews }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [helpful, setHelpful] = useState(true); // Initially true
  const [notHelpful, setNotHelpful] = useState(true); // Initially true

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageDialog = () => {
    setOpenImageDialog(!openImageDialog);
  };

  const handleVideoDialog = () => {
    setOpenVideoDialog(!openVideoDialog);
  };

  const handleHelpful = () => {
    setHelpful((prevHelpful) => !prevHelpful);
    setNotHelpful(true);
  };

  const handleNotHelpful = () => {
    setNotHelpful(!notHelpful);
    setHelpful(true);
  };

  return (
    <Box>
      <TableContainer sx={{ py: 4 }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              {reviews.map((i, index) => {
                return (
                  <Box
                    sx={{
                      borderBottom: "0.0625rem solid rgba(0,0,0,.12)",
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <Grid container spacing={2} mb={2} className="reviewTable">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        textAlign="left"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                      >
                        <Typography variant="caption">
                          {i.reviewDate}
                        </Typography>
                        <Box display="flex" gap={1}>
                          <Avatar sx={{ width: 24, height: 24 }} />
                          <Typography>{i.userName}</Typography>
                        </Box>

                        <Rating
                          size="large"
                          name="hover-feedback"
                          value={i.ratings}
                          readOnly
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        justifyContent="center"
                        gap={1}
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography
                          textAlign="justify"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {i.reviews}
                        </Typography>

                        {i.reviewImage !== null && i.reviewImage !== "" ? (
                          <>
                            <img
                              alt="review"
                              src={`${ReviewImageAPI}${i.reviewImage}`}
                              onClick={handleImageDialog}
                              style={{ height: 100, width: 200 }}
                            />
                            <Dialog
                              open={openImageDialog}
                              onClose={() => setOpenImageDialog(false)}
                            >
                              <img
                                alt="review"
                                src={`${ReviewImageAPI}${i.reviewImage}`}
                              />
                            </Dialog>
                          </>
                        ) : (
                          <></>
                        )}

                        {i.reviewVideo !== null && i.reviewVideo !== "" ? (
                          <>
                            <video
                              controls
                              style={{ height: 100, width: 200 }}
                              onClick={handleVideoDialog}
                            >
                              <source
                                src={`${ReviewImageAPI}${i.reviewVideo}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </>
                        ) : (
                          <></>
                        )}

                        <Dialog
                          open={openVideoDialog}
                          onClose={() => setOpenVideoDialog(false)}
                        >
                          <video controls style={{ height: 400, width: 400 }}>
                            <source
                              src={`${ReviewImageAPI}${i.reviewVideo}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </Dialog>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        justifyContent="end"
                        display="flex"
                      >
                        <Box display="flex" flexDirection="row" gap={2}>
                          <Typography
                            textAlign="justify"
                            sx={{ wordBreak: "break-word" }}
                          >
                            Was this helpful?{" "}
                          </Typography>
                          {helpful ? (
                            <ThumbUpOffAltIcon
                              onClick={handleHelpful}
                              sx={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <ThumbUpAltIcon
                              onClick={handleHelpful}
                              sx={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                                color: "#faa634",
                              }}
                            />
                          )}
                          {notHelpful ? (
                            <ThumbDownOffAltIcon
                              onClick={handleNotHelpful}
                              sx={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <ThumbDownAltIcon
                              onClick={handleNotHelpful}
                              sx={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                                color: "#faa634",
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ display: "flex", justifyContent: "end" }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={reviews.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
