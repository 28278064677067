import React, { useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { fetch_add_returns_refunds } from "../../Services/Services/OrderService";

function RefundVoucherForm({
  successMsg,
  ErrorMsg,
  openDialog,
  setOpenDialog,
}) {
  const [orderNum, setOrderNum] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [productCode, setProductCode] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");
  const [isStorePurchase, setIsStorePurchase] = useState(false); // Assuming it's a boolean
  const [isOnlinePurchase, setIsOnlinePurchase] = useState(false); // Assuming it's a boolean
  const [isRequestReturn, setIsRequestReturn] = useState(false); // Assuming it's a boolean
  const [isRequestReplacement, setIsRequestReplacement] = useState(false); // Assuming it's a boolean
  const [isRequestRefund, setIsRequestRefund] = useState(false); // Assuming it's a boolean
  const [returnReason, setReturnReason] = useState("");
  const [transactionImage, setTransactionImage] = useState([]);
  const [RequestReturnReason, setRequestReturnReason] = useState(""); // Assuming it's a boolean
  const [RequestReplacementReason, setRequestReplacementReason] = useState(""); // Assuming it's a boolean
  const [RequestRefundReason, setRequestRefundReason] = useState("");
  const handleClose = () => {
    setOpenDialog(false);
    setOrderNum("");
    setDateOfPurchase("");
    setProductCode("");
    setAmountPaid("");
    setInvoiceNum("");
    setIsStorePurchase("");
    setIsOnlinePurchase("");
    setIsRequestReturn("");
    setIsRequestReplacement("");
    setIsRequestRefund("");
    setReturnReason("");
    setTransactionImage("");
    setRequestReturnReason("");
    setRequestReplacementReason("");
    setRequestRefundReason("");
  };

  const addReturnsRefunds = async () => {
    const obj = {
      orderNum: orderNum,
      dateOfPurchase: dateOfPurchase,
      productCode: productCode,
      amountPaid: amountPaid,
      invoiceNum: invoiceNum,
      isStorePurchase: isStorePurchase,
      isOnlinePurchase: isOnlinePurchase,
      isRequestReturn: isRequestReturn,
      isRequestReplacement: isRequestReplacement,
      isRequestRefund: isRequestRefund,
      returnReason: returnReason,
      requestReturnReason: RequestReturnReason,
      requestReplacementReason: RequestReplacementReason,
      requestRefundReason: RequestRefundReason,
    };
    const formData = new FormData();
    for (let i = 0; i < transactionImage.length; i++) {
      formData.append("transactionImage[]", transactionImage[i]);
    }

    // Append other data fields
    for (const key in obj) {
      formData.append(key, obj[key]);
    }

    try {
      const res = await fetch_add_returns_refunds(formData);
      if (!res.data.error) {
        successMsg("Your request has been sent successfully !");
        handleClose();
      } else {
        ErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  return (
    <>
      <Dialog open={openDialog} maxWidth="md">
        <DialogTitle>Return Request Form</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} p={3} columnSpacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Order No.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                value={orderNum}
                onChange={(e) => setOrderNum(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Date of Purchase</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                type="date"
                sx={{ width: "100%" }}
                value={dateOfPurchase}
                onChange={(e) => setDateOfPurchase(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Product Code</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Total Amount Paid ($)</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Sales Invoice No.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                value={invoiceNum}
                onChange={(e) => setInvoiceNum(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Is this a store purchase?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RadioGroup
                row
                value={isStorePurchase}
                onChange={(e) => setIsStorePurchase(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Is this a online purchase?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RadioGroup
                row
                value={isOnlinePurchase}
                onChange={(e) => setIsOnlinePurchase(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                For online purchases, please upload the Attachments (Multiple
                File Upload) *
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                type="file"
                inputProps={{
                  accept: "image/*",
                  multiple: true,
                }}
                sx={{ width: "100%" }}
                onChange={(e) => setTransactionImage(e.target.files)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Is this a request for return?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RadioGroup
                row
                value={isRequestReturn}
                onChange={(e) => setIsRequestReturn(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                If yes, please provide the reason for the return?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                value={RequestReturnReason}
                onChange={(e) => setRequestReturnReason(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Is this a request for replacement?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RadioGroup
                row
                value={isRequestReplacement}
                onChange={(e) => setIsRequestReplacement(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                If yes, please provide the reason for the replacement request
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                value={RequestReplacementReason}
                onChange={(e) => setRequestReplacementReason(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Is this a request for refund?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RadioGroup
                row
                value={isRequestRefund}
                onChange={(e) => setIsRequestRefund(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                If yes, please provide us the details here
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                value={RequestRefundReason}
                onChange={(e) => setRequestRefundReason(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>Other reason for return</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                onChange={(e) => setReturnReason(e.target.value)}
                value={returnReason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={addReturnsRefunds}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RefundVoucherForm;
