import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Button,
  Card,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CardLogo from "../../Assets/PaymentLogo/icons8-magnetic-card-96.png";
import CheckLogo from "../../Assets/PaymentLogo/icons8-paycheque-96.png";
import ETLogo from "../../Assets/PaymentLogo/icons8-initiate-money-transfer-96.png";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import "./Payment.styles.css";
import {
  fetch_add_card,
  fetch_get_saved_cards,
} from "../../Services/Services/UserService";
import { fetch_get_checkout_details } from "../../Services/Services/OrderService";

export default function PaymentForm({
  runConfirm,
  setSavedCustomerId,
  errorMsg,
  createSetupIntent,
  successMsg,
  rememberCard,
  paymentIntentKey,
  setCustomerId,
  setPaymentIntentKey,
  clientSecret,
  selectedSaveCardPayment,
  SavedPaymentMethod,
  setselectedSaveCardPayment,
  setSavedPaymentMethod,
  setRememberCard,
  activeStep,
  setPaymentFormData,
  paymentFormData,
  setActiveStep,
  setCheckoutData,
  ShippingAddressId,
  setselectedPaymentAccord,
}) {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [AddNewCard, setAddNewCard] = useState(false);
  const storedShippingAddressId = localStorage.getItem("ShippingAddressId");

  const [Accord1, setAccord1] = useState(true);
  const [Accord2, setAccord2] = useState(false);
  const [Accord3, setAccord3] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [ShowsavedCards, setShowSavedCards] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    createSetupIntent();
    // localStorage.setItem('CheckoutpaymentForm', JSON.stringify([]));
  };

  const showCardForm = () => {
    setAddNewCard(true);
    setShowSavedCards(false);
    setSavedPaymentMethod("");
    setselectedSaveCardPayment(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const result = await stripe.handleCardSetup(clientSecret, cardElement);
      const paymentMethodId = result.setupIntent.payment_method;
      setPaymentIntentKey(paymentMethodId);
      addCardData(paymentMethodId);
    } catch (error) {
      errorMsg("Your card was declined. Please try some other Card!");
      createSetupIntent();
      setLoading(false);
    }
  };

  const addCardData = async (id) => {
    const sendData = new FormData();
    sendData.append("userId", userId);
    sendData.append(" rememberCard", rememberCard);
    sendData.append("paymentMethod", id);
    try {
      const res = await fetch_add_card(sendData);
      if (!res.data.error) {
        setCustomerId(res.data.data.customer_id);
        successMsg(res.data.message);
        setLoading(false);
      } else {
        setCustomerId("");
        setLoading(false);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    const ConfirmPaymentIntent = async () => {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      try {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (result.error) {
          errorMsg("Payment confirmation failed");
        } else if (result.paymentIntent.status === "succeeded") {
          successMsg("Done Payment");
        } else if (result.paymentIntent.status === "requires_action") {
          errorMsg("requires_action");
        }
      } catch (error) {
        errorMsg(error);
      }
    };

    if (runConfirm) {
      ConfirmPaymentIntent();
    }
  }, [runConfirm, stripe, elements, clientSecret]);

  useEffect(() => {
    const getCheckout = async () => {
      const serverData = new FormData();
      serverData.append("userId", userId);
      serverData.append("addressId", storedShippingAddressId);
      try {
        const res = await fetch_get_checkout_details(serverData);
        if (!res.data.error) {
          setCheckoutData(res.data.shipping_address);
        } else {
          setCheckoutData([]);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getCheckout();
  }, [storedShippingAddressId]);

  const handleAccordion = (item) => {
    if (item === "Credit / Debit Card") {
      setselectedPaymentAccord(item);
      setAccord1(true);
      setAccord2(false);
      setAccord3(false);
    } else if (item === "Check / Cheque") {
      setselectedPaymentAccord(item);
      setAccord3(false);
      setAccord1(false);
      setAccord2(true);
    } else {
      setselectedPaymentAccord(item);
      setAccord2(false);
      setAccord1(false);
      setAccord3(true);
    }
  };

  const handleRemember = (e) => {
    if (e.target.checked) {
      setRememberCard("1");
    } else {
      setRememberCard("0");
    }
  };

  const handleOpenSavedCard = () => {
    setShowSavedCards(true);
    setAddNewCard(false);
  };

  useEffect(() => {
    const getCards = async () => {
      const sendData = new FormData();
      sendData.append("userId", userId);
      try {
        const res = await fetch_get_saved_cards(sendData);
        if (!res.data.error) {
          setSavedCards(res.data.data);
        } else {
          setSavedCards([]);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getCards();
  }, [userId]);

  const handleSentSavedCard = (method, id) => {
    setSavedPaymentMethod(method);
    setselectedSaveCardPayment(true);
    setSavedCustomerId(id);
  };

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom pt={2}>
        Payment method
      </Typography>

      <Grid container>
        <Box py={3}>
          <Accordion
            expanded={Accord1}
            onChange={() => handleAccordion("Credit / Debit Card")}
            disableGutters
            sx={{
              boxShadow: 0,
              borderTop: "1px solid silver",
              borderRight: "1px solid silver",
              borderLeft: "1px solid silver",
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <img
                src={CardLogo}
                style={{
                  width: 40,
                  height: 40,
                  verticalAlign: "middle",
                  marginRight: 5,
                }}
                alt="card"
              />
              <Typography color="#616e80" py={1}>
                Card
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box py={2} display="flex" justifyContent="start">
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={handleOpenSavedCard}
                        checked={ShowsavedCards}
                      />
                    }
                    label="Saved Card"
                  />
                </Box>
                {ShowsavedCards && (
                  <Stack spacing={1}>
                    {savedCards.map((i) => {
                      return (
                        <Box component={Card} p={1} alignContent="center">
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              textTransform="uppercase"
                            >
                              {i.cardType}
                            </Typography>
                            <Typography variant="body1">
                              **** **** **** {i.cardNumber}
                            </Typography>
                            <Typography variant="body1">
                              {i.expMonth}/{i.expYear}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={selectedSaveCardPayment}
                                  onChange={() =>
                                    handleSentSavedCard(
                                      i.paymentMethod,
                                      i.customerId
                                    )
                                  }
                                />
                              }
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                )}
                <Box py={3} borderTop="1px solid silver">
                  <Typography
                    textAlign="left"
                    color="primary"
                    onClick={showCardForm}
                  >
                    Add New Credit / Debit / ATM Card
                  </Typography>
                  <div className="checkout-form">
                    <div className="sr-payment-form">
                      <div className="sr-form-row" />
                      <div className="sr-combo-inputs">
                        <div className="sr-combo-inputs-row">
                          <CardElement
                            className="sr-input sr-card-element"
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Grid item xs={12} display="flex" flexWrap="wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="saveCard"
                        onChange={handleRemember}
                      />
                    }
                    label="Remember credit card details for next time"
                  />
                </Grid>
                <Box display="flex" justifyContent="end" mt={1.5}>
                  <LoadingButton
                    onClick={handleConfirm}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    sx={{ width: "20ch" }}
                  >
                    <span>
                      {paymentIntentKey !== "" ? "Added" : "Add Card"}
                    </span>
                  </LoadingButton>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={Accord2}
            onChange={() => handleAccordion("Check / Cheque")}
            disableGutters
            sx={{
              boxShadow: 0,
              borderTop: "1px solid silver",
              borderRight: "1px solid silver",
              borderLeft: "1px solid silver",
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <img
                src={CheckLogo}
                style={{
                  width: 40,
                  height: 40,
                  verticalAlign: "middle",
                  marginRight: 5,
                }}
                alt="card"
              />
              <Box display="flex" flexDirection="row" gap={1}>
                <Typography py={1} color="#616e80">
                  Check
                </Typography>
                <Typography py={1.5} color="#616e80" variant="caption">
                  (Eligible For Orders Above $1000)
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul style={{ listStyle: "none" }}>
                  <Stack spacing={1} mt={3} textAlign="left">
                    <li>
                      To make a payment, kindly mail your Personal Check or
                      Money Order to the following address: :
                      <ul
                        style={{
                          listStyle: "none",
                          fontWeight: 500,
                          marginTop: 2,
                          marginBottom: 2,
                        }}
                      >
                        <li>Shopztrend Enterprises Inc.</li>
                        <li># 138, Delayen Crescent</li>
                        <li>S7N 2V6, Saskatoon</li>
                        <li>SK, Canada.</li>
                      </ul>
                    </li>
                    <Typography variant="caption" color="#616e80">
                      Please note that Shopztrend.com will process your order
                      only after the payment via Personal Check or Money Order
                      is credited to Shopztrend Enterprises Inc. NOTE : Eligible
                      For Orders Above $1000
                    </Typography>
                  </Stack>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={Accord3}
            onChange={() => handleAccordion("Interac e-Transfer")}
            disableGutters
            sx={{
              boxShadow: 0,
              borderBottom: "1px solid silver",
              borderTop: "1px solid silver",
              borderRight: "1px solid silver",
              borderLeft: "1px solid silver",
            }}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowRightIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <img
                src={ETLogo}
                style={{
                  width: 40,
                  height: 40,
                  verticalAlign: "middle",
                  marginRight: 5,
                }}
                alt="card"
              />
              <Typography py={1} color="#616e80">
                Interac e-Transfer
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1} mt={3} textAlign="left">
                <Typography sx={{ cursor: "pointer" }}>
                  Please initiate the Interac E-Transfer to the mail ID :{" "}
                  <a
                    href="mailto:Payments@Shopztrend.com."
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    payments@shopztrend.com
                  </a>{" "}
                  .
                </Typography>
                <Typography>
                  Please ensure that you use the same email address that is
                  registered with Shopztrend.com for the E-Transfer.
                </Typography>
                <Typography>
                  Kindly include the Order/Invoice number in the E-Transfer
                  memo.
                </Typography>
                <Typography>
                  Once the payment is received by Shopztrend, your orders will
                  be processed.
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Box sx={{ m: 2, pt: 2 }}>
            <Button
              variant="contained"
              sx={{ bgcolor: "#2d2d2d", color: "#fff" }}
              fullWidth
              onClick={handleBack}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
