import { Box, Typography, Container, Grid } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import { useEffect, useState } from "react";
import SalePageCards from "../../Comps/Cards/SalePageCards";
import {
  fetch_get_all_brand_names,
  fetch_get_wholesale_trendz,
  fetch_get_wholesale_trendz_banner,
} from "../../Services/Services/CommonService";

export default function TrendingPage() {
  const [TrendingSection1, setTrendingSection1] = useState([]);
  const [TrendingSection2, setTrendingSection2] = useState([]);
  const [TrendingSection3, setTrendingSection3] = useState([]);
  const [TrendingSection4, setTrendingSection4] = useState([]);
  const [TrendingSection5, setTrendingSection5] = useState([]);
  const [TrendingSection6, setTrendingSection6] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [bannerHeading, setBannerHeading] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [BrandData, setBrandData] = useState([]);

  useEffect(() => {
    const getSale = async () => {
      const sendData = new FormData();
      sendData.append("homePageImageId", "");
      try {
        const res = await fetch_get_wholesale_trendz(sendData);
        if (!res.data.error) {
          setMessage(res.data.message);
          setTrendingSection1(res.data.data.SalesPage_layout_1);
          setTrendingSection2(res.data.data.SalesPage_layout_2);
          setTrendingSection3(res.data.data.SalesPage_layout_3);
          setTrendingSection4(res.data.data.SalesPage_layout_4);
          setTrendingSection5(res.data.data.SalesPage_layout_5);
          setTrendingSection6(res.data.data.SalesPage_layout_6);
          setOpen(true);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getSale();
  }, []);

  useEffect(() => {
    const getBanner = async () => {
      const sendData = new FormData();
      sendData.append("bannerId", 1);
      try {
        const res = await fetch_get_wholesale_trendz_banner(sendData);
        if (!res.data.error) {
          setBannerHeading(res.data.data.bannerHeading);
          setBannerCaption(res.data.data.bannerCaption);
        } else {
          setBannerHeading(`Our Biggest Deals Right Now`);
          setBannerCaption(
            `Save when they play with our list of deals and promotions`
          );
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getBanner();
  }, []);

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, []);

  const getAllbrandData = async () => {
    try {
      const res = await fetch_get_all_brand_names();
      if (!res.data.error) {
        setBrandData(res.data.data);
      } else {
        setBrandData([]);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getAllbrandData();
  }, []);

  const brandNames = BrandData.map((i) => i.brandName);

  return (
    <Box>
      <Header />

      <Container>
        <Box>
          <Grid container mb={2} mt={1}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h5" color="#FFFFFF" fontWeight={600}>
                  {bannerHeading}
                </Typography>
                <Typography
                  variant="h6"
                  color="#FFFFFF"
                  fontWeight={500}
                  sx={{ marginTop: 2 }}
                >
                  {bannerCaption}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {
        <SalePageCards
          section={TrendingSection1}
          layout="1"
          title=""
          BrandData={brandNames}
        />
      }

      {TrendingSection2?.isHeadingEnable === 1 ? (
        <SalePageCards
          section={TrendingSection2}
          layout="2"
          title={TrendingSection2?.heading}
          BrandData={brandNames}
        />
      ) : null}

      {TrendingSection3?.isHeadingEnable === 1 ? (
        <SalePageCards
          section={TrendingSection3}
          layout="3"
          title={TrendingSection3?.heading}
          BrandData={brandNames}
        />
      ) : null}

      {TrendingSection4?.isHeadingEnable === 1 ? (
        <SalePageCards
          section={TrendingSection4}
          layout="4"
          title={TrendingSection4?.heading}
          BrandData={brandNames}
        />
      ) : null}

      {TrendingSection5?.isHeadingEnable === 1 ? (
        <SalePageCards
          section={TrendingSection5}
          layout="5"
          title={TrendingSection5?.heading}
          BrandData={brandNames}
        />
      ) : null}

      {TrendingSection6?.isHeadingEnable === 1 ? (
        <SalePageCards
          section={TrendingSection6}
          layout="6"
          title={TrendingSection6?.heading}
          BrandData={brandNames}
        />
      ) : null}

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
