import { Typography, Dialog } from "@mui/material";
import { motion, useMotionValue, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./ProductsCard.styles.css";
import { useNavigate } from "react-router-dom";

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CardContainer = styled(motion.div)`
  width: 290px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  color: #fff;
  position: relative;
  filter: brightness(105%);
  background: radial-gradient(
    circle farthest-corner at center center,
    #f6f8f9 0%,
    #e5ebee 0%,
    #d7dee3 9%,
    #f5f7f9 100%
  );

  cursor: ${(props) =>
    props.isLaptop ? "grab" : "default"}; // Set cursor style conditionally
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.2;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  padding: 2em 15px;
`;

const ShoesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Shoes = styled(motion.div)`
  width: auto;
  height: 200px;
  z-index: 99;
  user-select: none;
  margin-top: 5em;
  img {
    width: 250px;
    height: 100%;
    user-select: none;
    background-blend-mode: multiply;
  }
`;

export function NotForSaleProductCard({ item, index }) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [active, setActive] = useState(-1);
  const navigate = useNavigate();
  const [isLaptop, setIsLaptop] = useState(false);

  useEffect(() => {
    // Use a media query to detect screen width and set the isLaptop state
    const mediaQuery = window.matchMedia("(min-width: 768px)"); // Adjust the value to your preferred breakpoint
    setIsLaptop(mediaQuery.matches);

    const handleResize = () => {
      setIsLaptop(mediaQuery.matches);
    };

    // Attach the event listener to handle resizing
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //   const handleDragStart = (e) => {
  //     if (!isLaptop) {
  //       e.preventDefault();
  //     }
  //   };

  const textArray =
    item.descriptions !== "" && item.descriptions.split(/\s+/).filter(Boolean);

  // Create an object to store the extracted values
  const extractedValues = {};

  textArray &&
    textArray.forEach((item) => {
      const [key, value] = item.split(":");
      if (key && value) {
        // Adjust the key to be "Model No" if it is "No"
        const adjustedKey = key.trim() === "No" ? "Model No" : key.trim();
        extractedValues[adjustedKey] = value.trim();
      }
    });

  return (
    <CardWrapper>
      <CardContainer
        className="cardcon"
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        whileTap={{ cursor: "grabbing" }}
        style={{ z: 10, cursor: "pointer" }}
        onMouseOver={() => {
          setActive(index);
        }}
        onMouseLeave={() => {
          setActive(-1);
        }}
      >
        <TopContainer>
          <ShoesWrapper>
            <Shoes
              onClick={() => setOpenImageDialog(true)}
              style={{
                mixBlendMode: "multiply",
              }}
              whileTap={{ cursor: "grabbing" }}
            >
              <img
                alt=""
                style={{ mixBlendMode: "multiply" }}
                src={item.images}
              />
            </Shoes>
            <Dialog
              fullWidth
              open={openImageDialog}
              onClose={() => {
                setOpenImageDialog(false);
                setActive(-1);
              }}
            >
              <Box p={4}>
                <img
                  alt=""
                  style={{ height: 400, width: "100%", objectFit: "contain" }}
                  src={item.images}
                />
              </Box>
            </Dialog>
          </ShoesWrapper>
        </TopContainer>
        <Box onClick={() => setOpenImageDialog(true)} zIndex={99}>
          {active === index ? (
            <Button
              variant="contained"
              sx={{
                width: 150,
                position: "absolute",
                top: "50%",
                left: "25%",
                cursor: "pointer",
                opacity: 1,
                color: "#262626",
                border: "1px solid #faa634",
                borderRadius: 10,
                backgroundColor: "rgba(255, 255, 255, 1)",
                ":hover": {
                  color: "#ffff",
                  backgroundColor: "#faa634",
                  border: "1px solid #faa634",
                  opacity: 1,
                },
              }}
              onClick={() => setOpenImageDialog(true)}
            >
              View Full Image
            </Button>
          ) : (
            <></>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          mt={1}
          sx={{ textShadow: "none" }}
        >
          {item?.productName !== "" && (
            <Box
              sx={{
                textAlign: "left",
                px: 2,
                fontSmooth: "none",
                boxShadow: "none",
              }}
            >
              <Typography
                variant="caption"
                fontWeight={500}
                fontSize={15}
                mt={1}
                color="#150404"
              >
                {item.productName}
              </Typography>
            </Box>
          )}

          {item.descriptions !== "" && (
            <Box
              sx={{
                textAlign: "left",
                px: 2,
                fontSmooth: "none",
                boxShadow: "none",
              }}
            >
              {item.descriptions.split("\n").map((line, index) => (
                <div key={index}>
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    fontSize={14}
                    mt={1}
                    color="#150404"
                  >
                    {line}
                  </Typography>
                  <br /> {/* Add a line break after each line */}
                </div>
              ))}
            </Box>
          )}

          <Box p={1}>
            <Box>
              <Button
                onClick={() =>
                  navigate(`/contactus#CHeader`, { state: { bulk: "1" } })
                }
                fullWidth
                className="button-62"
                role="button"
              >
                Inquire Now
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContainer>
    </CardWrapper>
  );
}
