import {
  getmainproducts,
  getallcategory,
  get_product_reviews,
  add_product_reviews,
  get_allproducts,
  add_products,
  getParticularItem,
  add_to_cart,
  add_to_wishlist,
  get_user_wishlist,
  get_user_cart,
  count_cart_wishlist,
  get_product_review_count,
  get_product_rating_count,
  filter_products,
  pay_order_payment,
  get_product_color,
  get_products_in_admin,
} from "../BaseAPI/API";
import { apiGETRequest, apiPOSTRequest } from "../apiRequest";

const fetch_getallcategory = async () => {
  try {
    const responseData = await apiGETRequest(getallcategory);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_getmainproducts = async (data) => {
  try {
    const responseData = await apiGETRequest(getmainproducts, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_product_reviews = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_product_reviews, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_product_reviews = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_product_reviews, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_products = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_products, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_allproducts = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_allproducts, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_getParticularItem = async (data) => {
  try {
    const responseData = await apiPOSTRequest(getParticularItem, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_to_cart = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_to_cart, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_user_cart = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_user_cart, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_user_wishlist = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_user_wishlist, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_count_cart_wishlist = async (data) => {
  try {
    const responseData = await apiPOSTRequest(count_cart_wishlist, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_product_review_count = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_product_review_count, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_product_rating_count = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_product_rating_count, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_filter_products = async (data) => {
  try {
    const responseData = await apiPOSTRequest(filter_products, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_pay_order_payment = async (data) => {
  try {
    const responseData = await apiPOSTRequest(pay_order_payment, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_product_color = async () => {
  try {
    const responseData = await apiGETRequest(get_product_color);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_get_products_in_admin = async (data) => {
  try {
    const responseData = await apiPOSTRequest(get_products_in_admin, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetch_add_to_wishlist = async (data) => {
  try {
    const responseData = await apiPOSTRequest(add_to_wishlist, data);
    return responseData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  fetch_getallcategory,
  fetch_get_allproducts,
  fetch_add_product_reviews,
  fetch_getmainproducts,
  fetch_get_product_reviews,
  fetch_add_products,
  fetch_getParticularItem,
  fetch_add_to_cart,
  fetch_add_to_wishlist,
  fetch_count_cart_wishlist,
  fetch_filter_products,
  fetch_get_product_color,
  fetch_get_product_rating_count,
  fetch_get_product_review_count,
  fetch_get_products_in_admin,
  fetch_get_user_cart,
  fetch_get_user_wishlist,
  fetch_pay_order_payment,
};
