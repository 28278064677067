import {
  Box,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function CookiesPolicy() {
  const [panel1, setPanel1] = useState(false);
  const [panel2, setPanel2] = useState(false);
  const [panel3, setPanel3] = useState(false);
  const [panel4, setPanel4] = useState(false);
  const [panel5, setPanel5] = useState(false);

  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Cookies Policy
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={3}>
          {/* What are Cookies? */}
          <Accordion
            expanded={panel1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                onClick={() => setPanel1(!panel1)}
                fontWeight={600}
                variant="subtitle1"
              >
                {" "}
                {panel1 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                What are Cookies?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={500}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  {" "}
                  We use the term cookie to describe cookies and similar
                  technologies such as tags and pixels. Cookies are small data
                  files that websites place on your computer, laptop or mobile
                  device.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Our use of Cookies */}
          <Accordion
            expanded={panel2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ borderBottom: "none" }}
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel2(!panel2)}
                variant="subtitle1"
              >
                {panel2 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Our use of Cookies
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={700}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  We use Cookies for the following purposes:
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      To improve the performance of our websites by
                      understanding which parts work well, and which don't.
                    </li>
                    <li>
                      To deliver relevant online advertising to you both on our
                      websites and elsewhere. This is sometimes done by
                      combining data that we already have about you with the
                      data collected through Cookies.{" "}
                    </li>
                    <li>
                      These Cookies are placed by us and selected third parties
                      and enable adverts to be presented to you on our and third
                      party websites.
                    </li>
                    <li>
                      To measure how effective our online advertising and
                      marketing communications are.
                    </li>
                    <li>
                      To enable us to collect information about how you and
                      other people use our websites.
                    </li>
                    <li>
                      To improve your experience on our websites, for example we
                      use Cookies to remember the products you’ve put in your
                      basket and to personalise your experience.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* What Cookies do we use? */}
          <Accordion
            expanded={panel3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                onClick={() => setPanel3(!panel3)}
                fontWeight={600}
                variant="subtitle1"
              >
                {panel3 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                What Cookies do we use?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={400}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  We use the following Cookies:
                </Typography>
                <ul>
                  <Stack spacing={1} mt={1}>
                    <li>
                      <strong>Strictly necessary Cookies. </strong>These are
                      Cookies that are required for the operation of our
                      website. They are necessary for the safety, security and
                      integrity of the site. For example they help support the
                      structure of the pages that are displayed to you, help to
                      improve navigation and allow you to return to pages you
                      have previously visited. This type of Cookie only lasts
                      for the duration of the time you are visiting the website.
                      When you leave the website they are deleted automatically.
                    </li>
                    <li>
                      <strong>
                        Performance Cookies or analytical Cookies.{" "}
                      </strong>
                      They allow us to recognise and count the number of
                      visitors and to see how visitors move around our website
                      when they are using it. This helps us to improve the way
                      our website works, for example, by ensuring that users are
                      finding what they are looking for easily. The data is
                      aggregated and anonymised, which means we cannot identify
                      you as an individual.
                    </li>
                    <li>
                      <strong>Functionality Cookies. </strong>These are used to
                      recognise you when you return to our website. This enables
                      us to personalise our content for you, greet you by name
                      and remember your preferences (for example, your choice of
                      language or region). These Cookies will remain on your
                      device until you choose to clear it. If you choose to do
                      this, you will need to enter your details each time you
                      visit the site.
                    </li>
                    <li>
                      <strong>Targeting Cookies. </strong>These Cookies will
                      collect information about your browsing habits and allow
                      us to show you adverts while you are browsing our site and
                      other sites on the internet. They are set by us or by
                      carefully selected third parties. They help us and the
                      selected third parties to understand the performance of
                      our marketing activities and improve the relevance of the
                      adverts that you see.
                    </li>
                  </Stack>
                </ul>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Blocking or restricting Cookies */}
          <Accordion
            expanded={panel4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel4(!panel4)}
                variant="subtitle1"
              >
                {panel4 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Blocking or restricting Cookies
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={500}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  You can stop Cookies being used on your device by activating
                  the setting on your browser that allows you to block the
                  deployment of all or some Cookies. Please visit
                  www.allaboutcookies.org to find out how. Please note, if you
                  use your browser settings to block Cookies you may not be able
                  to access all or parts of our site.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/*Policy change */}
          <Accordion
            expanded={panel5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              boxShadow: "none",
              border: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color="#703737"
                fontWeight={600}
                onClick={() => setPanel5(!panel5)}
                variant="subtitle1"
              >
                {panel5 ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}{" "}
                Policy change
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box textAlign="left">
                <Typography
                  fontWeight={500}
                  variant="subtitle1"
                  sx={{ marginTop: 2 }}
                >
                  This cookie policy was most recently updated in May 2018. If
                  we make changes to it, then we will take appropriate steps to
                  bring those changes to your attention.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
