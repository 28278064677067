import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Typography,
  Link,
  Rating,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../Assets/Shopztrend-Logo.png";
import StarIcon from "@mui/icons-material/Star";
import { appendData } from "../../Variables/ProcessVariable";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { fetch_add_feedback } from "../../Services/Services/CommonService";

export default function ScrollDialog({ open, setOpen }) {
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedProblem, setSelectedProblem] = useState("");
  const [feedback, setFeedback] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [openSnack, setopenSnack] = React.useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const onSubmit = async () => {
    const obj = {
      userId: userId,
      rating: value,
      feedbackType: selectedTopic,
      feedbackTopic: selectedProblem,
      feedback: feedback,
    };
    const sendData = appendData(obj);

    try {
      const res = await fetch_add_feedback(sendData);
      if (!res.data.error) {
        setSelectedProblem(null);
        setSelectedTopic(null);
        setValue(0);
        setMessage("FeedBack Sent Successfully !");
        setFeedback("");
        handleClose();
        setopenSnack(true);
      } else {
        setMessage("Something Went Wrong !");
        setColor("error");
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSnack(false);
  };

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={1000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={color}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent dividers>
          <Box>
            <Stack spacing={4}>
              <Box textAlign="center">
                <img
                  className="Logo"
                  src={logo}
                  alt="logo"
                  style={{
                    objectFit: "contain",
                    mixBlendMode: "multiply",
                    height: 80,
                    width: 200,
                  }}
                />
                <Typography fontWeight={600} variant="h6" color="#3A475B">
                  Customer FeedBack
                </Typography>
              </Box>
              <Box textAlign="center">
                <Stack spacing={2}>
                  <Typography variant="caption" fontSize={15}>
                    Your feedback will help us improve our website and is very
                    important us.
                  </Typography>
                  <Typography variant="caption" fontSize={15}>
                    Unfortunately, we are unable to respond to your feedback.
                  </Typography>
                  <Typography variant="caption" fontSize={15}>
                    Need Help?
                  </Typography>
                </Stack>
              </Box>
              <Box textAlign="center">
                <Stack>
                  <Link variant="caption" fontSize={15}>
                    Contact our Customer Service team
                  </Link>
                  <Typography variant="caption" fontSize={15}>
                    or
                  </Typography>
                  <Link variant="caption" fontSize={15}>
                    Live Chat
                  </Link>
                  <Typography variant="caption" fontSize={15}>
                    or
                  </Typography>
                  <Link variant="caption" fontSize={15}>
                    See our Help & FAQ section
                  </Link>
                </Stack>
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="caption"
                  fontSize={15}
                  color="#3A475B"
                  fontWeight={600}
                >
                  Please rate your experience on our website.
                </Typography>
              </Box>
              <Box textAlign="center">
                <Rating
                  size="large"
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </Box>
              <Box>
                <Typography color="#3A475B" fontWeight={600} mb={1}>
                  What is your feedback regarding?
                </Typography>
                <Autocomplete
                  onChange={(event, value) => setSelectedTopic(value)}
                  disablePortal
                  id="combo-box-demo"
                  options={["Facing an Issue", "Compliment"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={selectedTopic}
                      color="secondary"
                      size="small"
                      fullWidth
                      label="Choose a topic"
                    />
                  )}
                />
              </Box>
              {selectedTopic === "Facing an Issue" && (
                <Box>
                  <Typography color="#3A475B" fontWeight={600} mb={1}>
                    What problem are you having?
                  </Typography>
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => setSelectedProblem(value)}
                    id="combo-box-demo"
                    options={[
                      "Product Quality",
                      "User Interface",
                      "Payment Related",
                      "Website Performance",
                      "Other",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={selectedProblem}
                        color="secondary"
                        size="small"
                        fullWidth
                        label="Choose ..."
                      />
                    )}
                  />
                </Box>
              )}

              <Box>
                <Typography color="#3A475B" fontWeight={600} mb={1}>
                  {selectedTopic === "Facing an Issue"
                    ? "Please describe the problem you are having"
                    : "Let us Know Your Thoughts"}
                </Typography>
                <TextField
                  id="ProjectDescp"
                  rows={4}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  multiline
                  fullWidth
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </Box>
              <Box>
                <Button
                  className="button-62"
                  variant="contained"
                  fullWidth
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
