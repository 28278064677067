import { Box, Typography, Container, Grid } from "@mui/material";
import Footer from "../../Comps/Footer/Footer";
import Header from "../../Comps/Header/Header";
import BottomCredLink from "../../Comps/Footer/BottomCredLink";
import Inspire1 from "../../Assets/Inspire/pexels-jane-pham-1571673.jpg";
import Inspire2 from "../../Assets/Inspire/pexels-polina-zimmerman-3782140.jpg";

export default function Inspiration() {
  return (
    <Box>
      <Header />
      {/* <Divider sx={{ borderColor: 'silver' }} /> */}

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box width="100%" bgcolor="#faa634" textAlign="center" py={3}>
                <Typography variant="h4" color="#FFFFFF" fontWeight={600}>
                  Our Inspiration
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box>
          <Grid container mb={2} mt={3}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5" fontWeight={600}>
                  At Shopztrend, Excellence is a Journey, Not a Destination.
                </Typography>
                <Typography textAlign="justify" sx={{ color: "#49484a" }}>
                  We are ambitious, heart-centered and intuitive team with a
                  greater passion for the service industry. In an Endeavour to
                  excel, developing a new concept and exploring various avenues
                  of online business by setting higher standards of performance
                  and corporate governance, our team has on board professionals
                  from various other industries to head the Marketing,
                  Technical, HR, Finance, Sales &amp; Customer Relations,
                  Branding, Legal systems, Management Representative for product
                  Audits &amp; Safety and Human Resource divisions.
                </Typography>
                <Typography textAlign="justify" sx={{ color: "#49484a" }}>
                  Our core values of transparency, innovative products and
                  ethical business practices, clearly set the company apart from
                  the rest of the industry players. A strict adherence to
                  statutory norms has earned Shopztrend Enterprises Inc. the
                  respect of the online retail industry and the admiration of
                  numerous delighted customers.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box mt={4} bgcolor="#d1d5db">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            alignItems="center"
            order={{ xs: 2, sm: 2, lg: 1 }}
          >
            <Box px={3} display="flex" flexDirection="column" gap={3}>
              <Typography
                textAlign="justify"
                sx={{ marginTop: 1, color: "#49484a" }}
              >
                Shopztrend Enterprises Inc. was established in the year 2023 as
                an online company into the retail business development, and into
                contracted work in the past. With a vision to source innovative
                products globally and with greater emphasize on the Quality
                &amp; Standard. Shopztrend Enterprises Inc. has been working
                with Care Homes, Schools and Hotels for the contracted projects
                with most of them located in premium locations with high-end
                Users. Please do visit www.shopztrend.com for more details.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            order={{ xs: 1, sm: 1, lg: 2 }}
          >
            <img
              src={Inspire1}
              alt="img"
              style={{ objectFit: "cover", width: "100%", height: 300 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            order={{ xs: 3, sm: 3, lg: 3 }}
          >
            <img
              src={Inspire2}
              alt="img"
              style={{ objectFit: "cover", width: "100%", height: 300 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            md={6}
            display="flex"
            alignItems="center"
            order={{ xs: 4, sm: 4, lg: 4 }}
          >
            <Box px={3} display="flex" flexDirection="column" gap={3}>
              <Typography
                textAlign="justify"
                sx={{ marginTop: 1, color: "#49484a" }}
              >
                With compliance to legal diligence and business ethics, our
                system is backed by leading professionals with expertise in
                design conceptualization, material choices and execution of
                projects. The trust, integrity and longstanding client
                relationship has accredited our credibility with their continued
                support by reinvesting in our projects.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
      <BottomCredLink />
    </Box>
  );
}
