import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material';
import { ProductsProvider } from './Context/CountContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <StyledEngineProvider injectFirst>
      <ProductsProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              <ToastContainer />
            </QueryClientProvider>
    </ProductsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);


