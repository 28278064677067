import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logo from "../../Assets/Shopztrend-Logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./Header.styles.css";
import SearchIcon from "@mui/icons-material/Search";
import BasicMenu from "./Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { ProfileImageAPI } from "../../Services/BaseAPI/API";
import { ProductsContext } from "../../Context/CountContext";
import { fetch_getmainproducts } from "../../Services/Services/ProductService";

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const auth = JSON.parse(localStorage.getItem("Userauth"));
  const profilePic = localStorage.getItem("profilePic");
  const firstName = localStorage.getItem("firstName");

  const { CartCount, wishlistCount } = useContext(ProductsContext);

  useEffect(() => {
    const getData = async () => {
      console.log("Ruunning");
      try {
        const res = await fetch_getmainproducts();
        if (!res.data.error) {
          setData(res.data.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    getData();
  }, []);

  function handleSearch(event) {
    setSearchText(event.target.value);
    // Filter the data by category and search term
    const filtered = Object.entries(data).reduce(
      (acc, [category, products]) => {
        const matches = products.filter((product) => {
          const { productName } = product;
          const terms = `${category} ${productName}`.toLowerCase();
          return terms.includes(searchText.toLowerCase());
        });
        if (matches.length > 0) {
          acc[category] = matches;
        }
        return acc;
      },
      {}
    );
    setProducts(filtered);
  }
  const handleFilter = () => {
    if (searchText !== "") {
      console.log(products);
      navigate(`/shopfilter/result`, {
        state: { products: products || [], searchText: searchText },
      });
      localStorage.setItem("searchText", searchText);
    }
  };

  return (
    <Box position="relative">
      {/* 2nd Layer of Heading */}
      <Box className="navBox">
        <Grid
          container
          display="flex"
          alignItems="center"
          className="gridcon"
          gridAutoFlow="dense"
        >
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            position="relative"
            className="logogrid"
          >
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <img className="Logo" src={logo} alt="logo" />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            display="flex"
            justifyContent="end"
            position="relative"
            gap={6}
            className="shoproutes"
          >
            <Link
              to="/shop"
              style={{
                color: "#49484a",
                textDecoration: "none",
                verticalAlign: "middle",
              }}
            >
              <Typography
                className="shoprouteText"
                fontSize={18}
                onClick={() => navigate("/shop")}
              >
                Shopping
              </Typography>
            </Link>
            <Link
              to="/sale"
              style={{
                color: "#49484a",
                textDecoration: "none",
                verticalAlign: "middle",
              }}
            >
              <Typography className="shoprouteText" fontSize={18}>
                Wholesale
              </Typography>
            </Link>
            <Link
              to="/trending"
              style={{
                color: "#49484a",
                textDecoration: "none",
                verticalAlign: "middle",
              }}
            >
              <Typography className="shoprouteText" fontSize={18}>
                Uniforms
              </Typography>
            </Link>
            <MenuIcon className="smallScreenMenuIcon" onClick={handleClick} />
            {openMenu ? (
              <BasicMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="searchBox">
            <Box display="flex" justifyContent="center">
              <Paper
                className="deskSearch"
                sx={{
                  display: "flex",
                  boxShadow: 0,
                  border: "1px solid silver",
                  width: "350px",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search products, brands or FAQs*"
                  value={searchText}
                  onChange={handleSearch}
                />
                <Box bgcolor="#faa634" onClick={handleFilter}>
                  <IconButton
                    type="button"
                    sx={{ p: "10px", bgcolor: "#faa634" }}
                    aria-label="search"
                  >
                    <SearchIcon sx={{ fontSize: "27px" }} />
                  </IconButton>
                </Box>
              </Paper>

              <Paper
                className="smallSearch"
                sx={{
                  display: "flex",
                  boxShadow: 0,
                  border: "1px solid silver",
                  width: "25ch",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search products, brands or FAQs*"
                  value={searchText}
                  onChange={handleSearch}
                />
                <Box bgcolor="#faa634" onClick={handleFilter}>
                  <IconButton
                    type="button"
                    sx={{ p: "10px", bgcolor: "#faa634" }}
                    aria-label="search"
                  >
                    <SearchIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Box>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={1} sm={1} md={1} className="menuIcon">
            <MenuIcon onClick={handleClick} />
            {openMenu ? (
              <BasicMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            ) : (
              <></>
            )}
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            xl={3}
            display="flex"
            gap={6}
            justifyContent="center"
            className="deskIcons"
          >
            <Link
              to="/account"
              style={{ textDecoration: "none", color: "#faa634" }}
            >
              <Box>
                <Box display="flex" justifyContent="center">
                  {!auth ? (
                    <AccountCircleIcon />
                  ) : (
                    <Avatar
                      sx={{ width: 24, height: 24, bgcolor: "#faa634" }}
                      src={`${ProfileImageAPI}${profilePic}`}
                      alt={firstName}
                    />
                  )}
                </Box>
                <Box>
                  <Typography sx={{ color: "#49484a", fontSize: 18 }}>
                    Account
                  </Typography>
                </Box>
              </Box>
            </Link>

            <Link
              to="/wishlist"
              style={{ textDecoration: "none", color: "#faa634" }}
            >
              <Box>
                <Box>
                  <Badge badgeContent={wishlistCount} color="secondary">
                    <FavoriteBorderIcon />
                  </Badge>
                </Box>
                <Box>
                  <Typography sx={{ color: "#49484a", fontSize: 18 }}>
                    Wishlist
                  </Typography>
                </Box>
              </Box>
            </Link>

            <Link
              to="/trolley"
              style={{ textDecoration: "none", color: "#faa634" }}
            >
              <Box>
                <Box>
                  <Badge badgeContent={CartCount} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </Box>
                <Box>
                  <Typography sx={{ color: "#49484a", fontSize: 18 }}>
                    Cart
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
